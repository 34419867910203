import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  WorkplaceShareButton,
  TelegramIcon,
  LinkedinIcon,
  TumblrIcon,
  RedditIcon,
  PinterestIcon,
  EmailIcon,
} from "react-share";

const Share = ({property}) => {
  return (
    <div className="space-x-2">
      <FacebookShareButton
        url={process.env.REACT_APP_BACKEND_URL +'share/'+ property.property_id}
        quote={property.title}
        hashtag="#bnbbuyer" 
      >
        <FacebookIcon size={20} className="rounded-full" />
      </FacebookShareButton>

      <TwitterShareButton
        url={process.env.REACT_APP_BACKEND_URL +'share/'+ property.property_id}
        title={property.title}
        hashtag="#bnbbuyer"
      >
        <TwitterIcon size={20} className="rounded-full" />
      </TwitterShareButton>
      <EmailShareButton
        subject={property.title}
        body={
            `This ${property.title} has a short term rental history and is for sale. Check out more photos and details on Bnb Buyer.`
        }
        url={process.env.REACT_APP_BACKEND_URL +'share/'+ property.property_id}
        separator='-'
      >
        <EmailIcon size={20} className="rounded-full" />
      </EmailShareButton>

    </div>
  );
};

export default Share;