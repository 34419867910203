import { Drawer } from '@mui/material'
import { Div } from 'atomize'
import React from 'react'
import UserNav from '../reusables/UserNav'

const MobileUserSideDrawer = ({ openMobileUserSideDrawer, setOpenMobileUserSideDrawer }) => {
    return (
        <Drawer open={openMobileUserSideDrawer} onClose={() => setOpenMobileUserSideDrawer(false)} dir='ltr'  >
            <Div className='w-[65vw] relative h-full px-2 pt-2'  >
                <UserNav setOpenMobileUserSideDrawer={setOpenMobileUserSideDrawer} />
            </Div>
        </Drawer>
    )
}

export default MobileUserSideDrawer