import { Divider, Slider, Switch, Box, Select, MenuItem, InputBase, Button } from '@mui/material'
import { Div, Label } from 'atomize'
import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import Currency from 'react-currency-formatter'
import { useRecoilState } from 'recoil'
import { areasState, propertiesState, selectedZipsState } from '../recoilstate'
import AutoCompleteSearch from '../reusables/AutoCompleteSearch'
import '../mystyles.css'
import AreaSelect from '../select-inputs/Areas'


const Filter = ({ openMobileFilterSideDrawer, setOpenMobileFilterSideDrawer, price, setPrice, revenue, setRevenue, roi, setRoi, filterPrice, setFilterPrice, setFilterRevenue, maxPrice, minPrice, minRevenue,
    maxRevenue, properties, setProperties, minRoi, maxRoi, areas, setAreas, allAreas, setAllAreas, setLoader, setMyFilter, loader, applyFilter, setApplyFilter, filterMy, setFilterMy 
    ,defaultSelectedAreas, setDefaultSelectedAreas,setItemOffset
}) => {
    // eslint-disable-next-line
    const [value, setValue] = useState([20, 40])
    const [filterListingType, setFilterListingType] = useState('default')
    const [selectedZips, setSelectedZips] = useRecoilState(selectedZipsState)




    const listingTypes = [
        'By Agent', 'Off-Market'
    ]

    const handleChangeListingTypeFilter = async (event) => {
        setFilterListingType(event.target.value)
        /* 
         let ids = []
         for (let i = 0; i < properties.length; i++) {
             ids.push(properties[i]._id)
         }
         setLoader(true)
          await axios.post(process.env.REACT_APP_BACKEND_URL + `filter/properties-type`,{ids, prop_type: event.target.value})
             .then((res) => { 
                 if (res.data.status === 'success') {
                     setProperties(res.data.properties)
                     setLoader(false)
                 }
             })
             .catch((err) => { 
                 console.log(err)
             }) */
    }










    /*  const sortProperties = async () => {
         setLoader(true)
         let zips = []
         for (let i = 0; i < allAreas.length; i++) {
             zips.push(Object.values(allAreas[i].zips)[0])
         }
         const properties = await axios.post(process.env.REACT_APP_BACKEND_URL + `filter/properties/`,{
             pmin : price[0],
             pmax: price[1],
             rmin: roi,
             imin: revenue,
             zips
         })
             .then((res) => {
                 if (res.data.status === 'success') {
                     setProperties(res.data.properties)
                     setLoader(false)
                 }
             })
     } */



    const handleChangePrice = async (event, newValue) => {
        // setLoader(true)
        setPrice(newValue)
    }


    /*     const handleDragStopPrice = async (event, newValue) => {
            let ids = []
            for (let i = 0; i < properties.length; i++) {
                ids.push(properties[i]._id)
            }
             await axios.post(process.env.REACT_APP_BACKEND_URL + `filter/properties-price`,{
                min: newValue[0],
                max: newValue[1],
                ids
             })
                .then((res) => {
                    if (res.data.status === 'success') {
                        setProperties(res.data.properties)
                        setLoader(false)
                    }
                })
            
            
    
        } */
    const handleChangeRevenue = async (event, newValue) => {
        // setLoader(true)
        setRevenue(newValue)
    }
    /*    const handleDragStopRevenue = async (event, newValue) => {
           let ids = []
           for (let i = 0; i < properties.length; i++) {
               ids.push(properties[i]._id)
           }
            await axios.post(process.env.REACT_APP_BACKEND_URL + `filter/properties-income`,{
               min: newValue,
               ids
            })
               .then((res) => {
                   if (res.data.status === 'success') {
                       setProperties(res.data.properties)
                       setLoader(false)
                   }
               })
           
   
       } */



    const handleChangeRoi = async (event, newValue) => {

        setRoi(newValue)
        // setLoader(true)

    }

    /*    const handleDragStopRoi = async(event, newValue) => {
           let ids = []
           for (let i = 0; i < properties.length; i++) {
               ids.push(properties[i]._id)
           }
           setLoader(true)
            await axios.post(process.env.REACT_APP_BACKEND_URL + `filter/properties-roi`,{ids, min: newValue})
               .then((res) => { 
                   if (res.data.status === 'success') {
                       setProperties(res.data.properties)
                       setLoader(false)
                   }
               })
               .catch((err) => { 
                   console.log(err)
               })
   
           
          
       } */






    const filterbyZips = async (areas) => {
        let ids = []
        for (let i = 0; i < properties.length; i++) {
            ids.push(properties[i]._id)
        }
        let zips = []
        for (let i = 0; i < areas.length; i++) {
            zips.push(Object.values(areas[i].zips)[0])
        }
        let zips_length = zips.length
        setLoader(true)
        await axios.post(process.env.REACT_APP_BACKEND_URL + `filter/properties-zip`, { ids, zips, zips_length })
            .then((res) => {
                if (res.data.status === 'success') {
                    setProperties(res.data.properties)
                    setLoader(false)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }





    //for snack
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState('')
    const [msg, setMsg] = useState('')

    async function getAllProperties() {
        const properties = await axios.get(process.env.REACT_APP_BACKEND_URL + 'properties')
            .then((res) => {
                if (res.data.status === 'success') {
                    setProperties(res.data.properties)
                    /* setPrice() */
                }
            })
    }




    const filterAllProperties = async () => {
        setLoader(true)
        setApplyFilter(true)
        /* setFilterMy(true) */
        /* let zips = []
        for (let i = 0; i < areas.length; i++) {
            zips.push(Object.values(areas[i].zips)[0])
        } */
        let zips_length = selectedZips.length
        let nroi = roi / 100
        await axios.post(process.env.REACT_APP_BACKEND_URL + `filter/properties-all`, { imin: revenue, rmin: nroi, pmin: price[0], pmax: price[1], zips: selectedZips, zips_length, prop_type: filterListingType })
            .then((res) => {
                if (res.data.status === 'success') {
                    setProperties(res.data.properties)
                    /* setFilterListingType('default')
                    setAreas([]) */
                    setLoader(false)
                    setOpenMobileFilterSideDrawer(false)
                    setItemOffset(0)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const [clear, setClear] = useState(false)


    return (
        <Div className='space-y-5 pb-6 px-2'>
            <Div className='flex items-center justify-between' >
                <Div>Filter</Div>
                <Div className='text-gray-400 hover:text-[#1ebf99] cursor-pointer' onClick={() => {
                    setApplyFilter(false)
                    setFilterMy(true)
                    getAllProperties()
                    setClear(true)
                    setDefaultSelectedAreas([])
                }} >Reset Filter</Div>
            </Div>
            <Divider sx={{ position: 'relative', bottom: '0.5rem' }} />

            {localStorage.getItem('user') && (
                <Div className='flex items-center justify-between' >
                    <Div className='text-md' >Filter to My Preferences</Div>
                    <Div className='text-gray-400'>
                        <Switch
                            sx={{
                                color: '#1ebf99', '& .MuiSvgIcon-root': { fontSize: 20 },
                                '& .MuiSwitch-switchBase': { '&.Mui-checked': { color: '#1ebf99', tranform: 'translateX(22px)', '& + .MuiSwitch-track': { backgroundColor: '#1ebf99', opacity: '0.5' } } }
                            }}

                            checked={!filterMy}
                            onClick={() => {
                                setFilterMy(!filterMy)
                                if (filterMy) {
                                    setMyFilter()
                                } else {
                                    setApplyFilter(false)
                                    setFilterMy(true)
                                    getAllProperties()
                                   
                                    setDefaultSelectedAreas([])
                                }

                            }}

                        />
                    </Div>
                </Div>
            )}

            <Div>
                <Div className='space-y-2'>
                    {/*   <AutoCompleteSearch areas={allAreas} setAreas={setAreas} filterbyZips={filterbyZips} /> */}
                    <AreaSelect selectedZips={selectedZips} setSelectedZips={setSelectedZips} filterMy ={filterMy} applyFilter={applyFilter} clear={clear} setClear={setClear}
                    defaultSelectedAreas={defaultSelectedAreas} setDefaultSelectedAreas={setDefaultSelectedAreas}
                    />




                </Div>

            </Div>

            <Div className='space-y-2'>
                <Div className='' >
                    <Label className='font-semibold' >Price</Label>

                    <Box sx={{ width: '100%' }} >
                        <Slider valueLabelDisplay='auto' min={125000} max={6999900} step={100000} onChange={handleChangePrice} value={price} sx={{ color: '#1ebf99' }} />
                        <Div className='flex items-center justify-between ' >
                            <Div className='text-gray-500  items-center lg:flex-1 '> Min: $

                                <Div className='text-black font-semibold' >



                                    <InputBase type='text' className='placeholder:text-black '
                                        inputProps={{ sx: { color: 'black', '&::placeholder': { textOverflow: 'ellipsis !important', color: 'black', opacity: 1 } } }}
                                        sx={{ border: '1px solid #1ebf99', borderRadius: '4px', width: { xs: '6rem', lg: '7rem' }, pl: '0.3rem', ml: '0.3rem' }}
                                        value={Number(price[0]).toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        onChange={(e) => {
                                            let val = e.target.value //get value from input
                                            let m = val.replace(/,/g, '') //removing any comma present in the string
                                            let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                            e.target.value = result //set input value to the seperate format
                                            handleChangePrice(e, [Number(m), Number(price[1])])
                                        }

                                        } />
                                </Div> </Div>
                            <Div className='text-gray-500  items-center lg:flex-1 '> Max: $
                                <Div className='text-black font-semibold' >
                                    <InputBase type='text'
                                        inputProps={{ sx: { color: 'black', '&::placeholder': { textOverflow: 'ellipsis !important', color: 'black', opacity: 1 } } }}
                                        sx={{ border: '1px solid #1ebf99', borderRadius: '4px', width: { xs: '6rem', lg: '7rem' }, pl: '0.3rem', ml: '0.3rem' }} placeholder={Number(price[1]).toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        onChange={(e) => {
                                            let val = e.target.value //get value from input
                                            let m = val.replace(/,/g, '') //removing any comma present in the string
                                            let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                            e.target.value = result //set input value to the seperate format
                                            handleChangePrice(e, [Number(price[0]), Number(m)])
                                        }} />
                                </Div> </Div>

                        </Div>
                        {/* <Div className='flex items-center justify-between text-sm' >
                            <Div className='text-gray-500 '> Min: <span className='text-black font-semibold' > $<Currency pattern='##,###!' symbol='' quantity={Number(price[0])} /></span> </Div>
                            <Div className='text-gray-500 '> Max: <span className='text-black font-semibold' > $<Currency pattern='##,###!' symbol='' quantity={Number(price[1])} /></span> </Div>

                        </Div> */}
                    </Box>

                </Div>
                <Div className='' >
                    <Label className='font-semibold' >Revenue</Label>

                    <Box sx={{ width: '100%' }} >
                        <Slider valueLabelDisplay='auto' min={6667} max={200000} step={100} value={revenue} onChange={handleChangeRevenue} sx={{ color: '#1ebf99' }} />
                        <Div className='flex items-center justify-between' >
                            <Div className='text-gray-500 '></Div>
                            <Div className='text-gray-500 lg:flex items-center '>Minimum Revenue: $ <Div className='text-black font-semibold' >
                                <InputBase type='text'
                                    sx={{ border: '1px solid #1ebf99', borderRadius: '4px', width: { xs: 'unset', lg: '6rem' }, pl: '0.3rem', ml: '0.3rem' }}
                                    value={Number(revenue).toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    onChange={(e) => {

                                        let val = e.target.value //get value from input
                                        let m = val.replace(/,/g, '') //removing any comma present in the string
                                        let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                        e.target.value = result //set input value to the seperate format
                                        handleChangeRevenue(e, Number(m))
                                    }} />
                            </Div> </Div>

                        </Div>
                        {/* <Div className='flex items-center justify-between text-sm' >
                            <Div className='text-gray-500 '>  </Div>
                            <Div className='text-gray-500 '> Minimum Revenue: <span className='text-black font-semibold' > $<Currency pattern='##,###!' symbol='' quantity={revenue} /></span> </Div>
                        </Div> */}
                    </Box>

                </Div>
                <Div className='' >
                    <Label className='font-semibold' >ROI</Label>

                    <Box sx={{ width: '100%' }} >
                        <Slider valueLabelDisplay='auto' min={0} max={32} value={roi} step={2} onChange={handleChangeRoi} sx={{ color: '#1ebf99' }} />
                        {/*  <Div className='flex items-center justify-between text-sm' >
                            <Div className='text-gray-500 '>  </Div>
                            <Div className='text-gray-500 '>Minimum ROI: <span className='text-black font-semibold' > {Number(roi).toFixed(1)}% </span>
                            </Div>

                        </Div> */}
                        <Div className='flex items-center justify-between' >
                            <Div className='text-gray-500 '>  </Div>
                            <Div className='text-gray-500 lg:flex items-center '>Minimum ROI: <Div className='text-black font-semibold ml-2' >
                                <InputBase

                                    sx={{ border: '1px solid #1ebf99', borderRadius: '4px', width: { xs: 'unset', lg: '6rem' }, pl: '0.3rem', mr: '0.3rem' }}
                                    type='number' value={Number(roi).toFixed(0)}
                                    inputProps={{ sx: { color: 'black', '&::placeholder': { textOverflow: 'ellipsis !important', color: 'black', opacity: 1 } } }}
                                    onChange={(e) => handleChangeRoi(e, Number(e.target.value))} />
                                % </Div> </Div>
                        </Div>

                    </Box>

                </Div>



            </Div>



            <Div>
                <Select
                    sx={{ width: '100%', fontSize: '0.8rem' }}
                    input={<InputBase className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1' />}
                    value={filterListingType}
                    onChange={handleChangeListingTypeFilter}
                >
                    <MenuItem value='default' >Listing Type</MenuItem>
                    {listingTypes.map((name) => (
                        <MenuItem value={name} key={name}  >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </Div>

            <Div className='mt-4 mb-3' >
                <Button
                    disabled={loader}
                    className='shadow-md'
                    sx={{ textTransform: 'none', textSize: '0.8rem', width: '100%', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                    onClick={()=>{
                        setFilterMy(true)
                        filterAllProperties()
                    }}

                >
                    Apply filters
                </Button>
            </Div>




        </Div>
    )
}

export default Filter