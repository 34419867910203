

import { Avatar } from '@mui/material'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"

const HousesCarousel = ({ houses }) => {
    return (
        <Carousel showThumbs={false} showStatus={false} showIndicators={false} autoPlay={true} swipeable={true} emulateTouch={true} >
            {houses.map((item, index) => (
                <div>
                    <Avatar src={item}
                        sx={{ width: '100%', height: '20rem', borderRadius: '1rem' }} variant='square'>House Image</Avatar>
                </div>
            ))}
        </Carousel>
    )
}

export default HousesCarousel