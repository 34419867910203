import { Drawer } from '@mui/material'
import { Div } from 'atomize'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userEmailState } from '../recoilstate'
import LoginBtn from '../reusables/LoginBtn'
import LogoutBtn from '../reusables/LogoutBtn'
import NavLinks from '../reusables/NavLinks'
import RegisterBtn from '../reusables/RegisterBtn'

const MobileSideDrawer = ({ openMobileSideDrawer, setOpenMobileSideDrawer, setLoggedIn, redirect }) => {
    const [emailState, setEmailState] = useRecoilState(userEmailState || '')
    const navigate = useNavigate()
    const location = window.location

    return (
        <Drawer open={openMobileSideDrawer} onClose={() => setOpenMobileSideDrawer(false)} dir='ltr' className='lg:hidden'  >
            <Div className='w-[65vw] relative h-full'  >
                <Div className=' pl-2 pt-5 ' >
                   {/*  <Div className='text-gray-500  border-b-2 border-b-gray-100 py-3 mb-5' >Menu options</Div> */}
                    <Div className='space-y-4 ' >
                        {NavLinks.map((item, index) => (
                            <Div key={index} hoverTextColor='#00ccbb' className={location.pathname === item.route ? 'cursor-pointer text-sm text-[#1ebf99]': 'cursor-pointer text-sm'} onClick={() => {
                                navigate(item.route)
                                setOpenMobileSideDrawer(false)
                            }} >{item.name}</Div>
                        ))}
                    </Div>
                </Div>
                <Div className=' pl-2 ' >
                    {emailState && emailState != '' && (
                        <>
                            <Div className='text-gray-500  border-b-2 border-b-gray-100 py-3 mb-5 mt-4' >My account</Div>
                            <Div className='space-y-2 lg:space-y-1 mt-5 lg:mt-0 ' >
                                <Div
                                    className={location.pathname === '/user/profile' ?
                                        'text-[#1ebf99] text-sm ' :
                                        'text-gray-500 text-sm '
                                    }
                                    onClick={() => {
                                        navigate('/user/profile')
                                        setOpenMobileSideDrawer(false)

                                    }}
                                >
                                    My Profile
                                </Div>
                                <Div
                                    className={location.pathname === '/user/preferences' ?
                                        'text-[#1ebf99] text-sm ' :
                                        'text-gray-500 text-sm '}
                                    onClick={() => {
                                        navigate('/user/preferences')
                                        setOpenMobileSideDrawer(false)
                                    }}
                                >
                                    My Preferences
                                </Div>
                                <Div
                                    className={location.pathname === '/user/billing&subscription' ?
                                        'text-[#1ebf99] text-sm ' :
                                        'text-gray-500 text-sm  '}
                                    onClick={() => {
                                        navigate('/user/billing&subscription')
                                        setOpenMobileSideDrawer(false)
                                    }}
                                >
                                    Billing & Subscription
                                </Div>

                                <Div
                                    className={location.pathname === '/user/email-preference' ?
                                        'text-[#1ebf99] text-sm ' :
                                        'text-gray-500 text-sm  '}
                                    onClick={() => {
                                        navigate('/user/email-preference')
                                        setOpenMobileSideDrawer(false)
                                    }}
                                >
                                    Email Preferences
                                </Div>
                            </Div>
                        </>
                    )}
                    <Div className='space-y-2 mt-6' >
                        {emailState && emailState != '' ? (<Div><LogoutBtn /></Div>) :
                            (

                                <>
                                    <Div ><LoginBtn setLoggedIn={setLoggedIn} setOpenMobileSideDrawer={setOpenMobileSideDrawer} redirect={redirect} /></Div>
                                    <Div onClick={() => setOpenMobileSideDrawer(false)}><RegisterBtn /></Div>
                                </>
                            )}
                    </Div>
                </Div>

                <Div className='  mt-3 absolute bottom-2 space-x-1 pl-1  flex items-center' >

                    <Div textColor='#00ccbb' className='cursor-pointer text-sm underline ' onClick={()=> window.location.href = 'https://bnbbuyer.com/terms' } >Terms of Service</Div>
                    <Div textColor='#00ccbb' className='cursor-pointer text-sm underline'  onClick={()=> window.location.href = 'https://bnbbuyer.com/privacy-policy' } >Privacy Policy</Div>
                </Div>
            </Div>
        </Drawer>
    )
}

export default MobileSideDrawer