import {
  Routes,
  Route,
} from "react-router-dom";
import ChangePasswordScreen from "./screens/ChangePasswordScreen";
import ForgotPassword from "./screens/ForgotPasswordScreen";
import HouseDetailScreen from "./screens/HouseDetailScreen";
import HousesScreen from "./screens/HousesScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import RegisterScreen from "./screens/RegisterScreen";
import SellPropertyScreen from "./screens/SellPropertyScreen";
import User from "./screens/user/User";



function Main() {
  return (
    
            <Routes>

              <Route path="/*" element={<NotFoundScreen/>} />
              <Route path="/register?easyRegister=true" element={<RegisterScreen defaultStep={1} />} />
              <Route path="/register" element={<RegisterScreen defaultStep={1} />} />
              <Route path="/callback/:msg" element={<RegisterScreen defaultStep={4} />} />
              <Route path="/sell-my-property" element={<SellPropertyScreen />} />
              <Route path="/search-houses" element={<HousesScreen />} />
              <Route path="/" element={<HousesScreen />} />
              <Route path="?redirect=login" element={<HousesScreen />} />
              <Route path="/house-detail/:id" element={<HouseDetailScreen />} />
              <Route path="/user/*" element={<User />} />
              <Route path='forgot-password' element={<ForgotPassword/>} />
              <Route path='change-password/:token' element={<ChangePasswordScreen/>} />
            </Routes>

  );
}

export default Main;

