import { Div, Modal, Tag } from 'atomize'
import React, { useState, useEffect } from 'react'
import { Close } from '@mui/icons-material'
import Currency from 'react-currency-formatter'
import { Button } from '@mui/material'
import axios from 'axios'
import Plans from '../Plans'
import JoinPremiumAccessModal from './JoinPremiumAccessModal'
import { useStripe } from '@stripe/react-stripe-js'
import { track } from '../tracking'

const PlansModal = ({ openPlansModal, setOpenPlansModal, setLoader, setOpen, setMsg, setColor, subscription, setSubscription, free }) => {
    const stripe = useStripe()
    const createSubscription = async (plan) => {
        setLoader(true)
        await axios.post(process.env.REACT_APP_BACKEND_URL + 'stripe/create-subscription', {
            email: localStorage.getItem('user'),
            plan: plan
        })
            .then((res) => {
                if (res.data.status === 'success') {

                    setOpen(true)
                    setColor('green')
                    setMsg('Success billing plan updated successfully')
                    setOpenPlansModal(false)
                    setLoader(false)
                } else {
                    setOpen(true)
                    setMsg(res.data.msg)
                    setColor('#bb2124')
                    setLoader(false)
                }
            })

            .catch((err) => {
                setOpen(true)
                setMsg('some error occured')
                setColor('#bb2124')
                setLoader(false)
            })
    }


    const endSubscription = async () => {
        setLoader(true)
        await axios.post(process.env.REACT_APP_BACKEND_URL + 'stripe/end-subscription', {
            email: localStorage.getItem('user')
        })
            .then((res) => {
                if (res.data.status === 'success') {
                    setOpen(true)
                    setColor('green')
                    setMsg(res.data.msg)
                    setLoader(false)
                    setOpenPlansModal(false)
                } else {
                    setOpen(true)
                    setMsg(res.data.msg)
                    setColor('#bb2124')
                    setLoader(false)
                }
            })

    }


    const email = localStorage.getItem('user')

 

    const subscribe = async (plan) => {
        setLoader(true)
        const subscribenow = await axios.post(process.env.REACT_APP_BACKEND_URL + 'stripe/subscribe-from-billings', { email, plan })
            .then((res) => {
                if (res.data.status === 'success') {
                    stripe.redirectToCheckout({ sessionId: res.data.session_id })
                    setOpen(true)
                    setColor('green')
                    setMsg('redirecting to stripe')
                } else {
                    setLoader(false)
                    setOpen(true)
                    setColor('red')
                    setMsg(res.data.msg + ' Please try again or reach us at hello@bnbbuyer.com')
                }
            })
            .catch((err) => {
                setLoader(false)
                setOpen(true)
                setColor('red')
                setMsg('some error occured' + ' Please try again or reach us at hello@bnbbuyer.com')
            })
    }

    return (
        <Modal isOpen={openPlansModal} onClose={() => setOpenPlansModal(false)} className='relative' bg='#fafafa' align='center' maxW={{ lg: '48rem', xs: '21rem' }} >
            <Close sx={{ fontSize: '1.5rem', cursor: 'pointer', position: 'absolute', top: 4, right: 2 }} onClick={() => setOpenPlansModal(false)} />
            <Div className='lg:px-5 space-y-4' >
                <Div className='text-xl text-black font-bold text-center' >What Plan You'd Like To Switch To</Div>
                {subscription.plan?.id === Plans.monthly && (
                    <Div className='space-y-2' >
                        <Div className='bg-white shadow-md py-2 lg:py-4 lg:px-10 px-2 lg:flex  rounded-lg' >
                            <Div className='flex-1 ' >
                                <Div className='font-bold text-sm flex lg:block' >
                                    Annual Membership <Tag className=' border-[1px] border-[#6ed68d] text-center w-[6rem] ml-2' bg='#daf2dc'>Best Offer</Tag> </Div>
                                <Div className='text-black text-lg'>
                                    <span className='text-[#178269] font-bold' ><Currency quantity={159} /></span> Per Year </Div>
                            </Div>

                            <Div className='lg:my-auto justify-between  flex lg:block' >
                                <Div className='lg:hidden flex-1'></Div>
                                <Button
                                    className='shadow-md'
                                    sx={{ textTransform: 'none', px: '5', fontSize: '0.8rem', width: '8rem', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                                    onClick={() => {
                                        track(null,'annual_upgrade', email)
                                        createSubscription('yearly')
                                    }}
                                >
                                    Upgrade
                                </Button>
                            </Div>
                        </Div>
                    </Div>

                )} {subscription.plan?.id === Plans.yearly && (
                    <Div className='space-y-2' >
                        <Div className='bg-white shadow-md py-2 lg:py-4 lg:px-10 px-2 lg:flex  rounded-lg' >
                            <Div className='flex-1 ' >
                                <Div className='font-bold text-sm flex lg:block' >
                                    Monthly Membership <Tag className=' border-[1px] border-[#6ed68d] text-center w-[6rem] ml-2' bg='#daf2dc'>Best Offer</Tag> </Div>
                                <Div className='text-black text-lg'>
                                    <span className='text-[#178269] font-bold' ><Currency quantity={25} /></span> Per Year </Div>
                            </Div>

                            <Div className='lg:my-auto justify-between  flex lg:block' >
                                <Div className='lg:hidden flex-1'></Div>
                                <Button
                                    className='shadow-md'
                                    sx={{ textTransform: 'none', px: '5', fontSize: '0.8rem', width: '8rem', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                                    onClick={() => {
                                         track(null,'monthly_upgrade', email)
                                        createSubscription('monthly')
                                    }}
                                >
                                    Upgrade
                                </Button>
                            </Div>
                        </Div>
                    </Div>
                )}


                {free && (

                    <>
                        <Div className='space-y-2' >
                            <Div className='bg-white shadow-md py-2 lg:py-4 lg:px-10 px-2 lg:flex  rounded-lg' >
                                <Div className='flex-1 ' >
                                    <Div className='font-bold text-sm flex lg:block' >
                                        Annual Membership <Tag className=' border-[1px] border-[#6ed68d] text-center w-[6rem] ml-2' bg='#daf2dc'>Best Offer</Tag> </Div>
                                    <Div className='text-black text-lg'>
                                        <span className='text-[#178269] font-bold' ><Currency quantity={159} /></span> Per Year </Div>
                                </Div>

                                <Div className='lg:my-auto justify-between  flex lg:block' >
                                    <Div className='lg:hidden flex-1'></Div>
                                    <Button
                                        className='shadow-md'
                                        sx={{ textTransform: 'none', px: '5', fontSize: '0.8rem', width: '8rem', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                                        onClick={() => {
                                            track(null,'annual_upgrade_from_free_plan', email)
                                            subscribe('yearly')
                                        }}
                                    >
                                        Upgrade
                                    </Button>
                                </Div>
                            </Div>
                        </Div>
                        <Div className='space-y-2' >
                            <Div className='bg-white shadow-md py-2 lg:py-4 lg:px-10 px-2 lg:flex  rounded-lg' >
                                <Div className='flex-1 ' >
                                    <Div className='font-bold text-sm flex lg:block' >
                                        Monthly Membership <Tag className=' border-[1px] border-[#6ed68d] text-center w-[6rem] ml-2' bg='#daf2dc'>Best Offer</Tag> </Div>
                                    <Div className='text-black text-lg'>
                                        <span className='text-[#178269] font-bold' ><Currency quantity={25} /></span> Per Year </Div>
                                </Div>

                                <Div className='lg:my-auto justify-between  flex lg:block' >
                                    <Div className='lg:hidden flex-1'></Div>
                                    <Button
                                        className='shadow-md'
                                        sx={{ textTransform: 'none', px: '5', fontSize: '0.8rem', width: '8rem', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                                        onClick={() => {
                                            track(null,'monthly_upgrade_from_free_plan', email)
                                            subscribe('monthly')
                                        }}
                                    >
                                        Upgrade
                                    </Button>
                                </Div>
                            </Div>
                        </Div>
                    </>
                )}

                {!free && (
                    <Div className='space-y-2' >
                        <Div className='bg-white shadow-md py-2 lg:py-4 lg:px-10 px-2 lg:flex  rounded-lg' >
                            <Div className='flex-1' >
                                <Div className='font-bold text-sm' >
                                    Downgrade To Free Membership </Div>
                                <Div className='text-black text-lg'>
                                    <span className='text-[#178269] font-bold' ><Currency quantity={0} /></span>  </Div>
                            </Div>

                            <Div className='lg:my-auto justify-between  flex lg:block' >
                                <Div className='lg:hidden flex-1'></Div>
                                <Button
                                    className='shadow-md'
                                    sx={{ textTransform: 'none', px: '5', fontSize: '0.8rem', width: '8rem', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                                    onClick={()=>{
                                        
                                        endSubscription()
                                        track(null,'downgrade_to_free_membership', email)
                                    }
                                    }
                                >
                                    Downgrade
                                </Button>
                            </Div>
                        </Div>
                    </Div>
                )}

            </Div>
        </Modal>
    )
}

export default PlansModal