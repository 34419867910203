import { Button } from '@mui/material'
import React,{useState} from 'react'
import { useRecoilState } from 'recoil'
import Login from '../modals/LoginModal'
import { userEmailState } from '../recoilstate'

const LogoutBtn = () => {
  const [emailState, setEmailState] = useRecoilState( userEmailState || '')
  return (
    <>
    
    <Button
            sx={{ border: '1px solid #1ebf99', borderRadius: '0.4rem', 
            textTransform: 'none', bgcolor: '#1ebf99', color: 'white', px: '1.3rem', py: '0.1rem', ':hover': { color:'black' }, width:{xs:'80%',lg:'10rem'} }}
            onClick={()=>{
              setEmailState(null)
              localStorage.removeItem('user')
              window.location.reload()
            }}
          >Logout</Button>
    
    </>
  )
}

export default LogoutBtn