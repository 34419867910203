import { Google, Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, Checkbox, Divider, InputBase } from '@mui/material'
import { Div, Label, Anchor } from 'atomize'
import axios from 'axios'
import React, { useState, useEffect, useMemo } from 'react'
import Snack from '../reusables/Snack'
import { useRecoilState } from 'recoil'
import { userState, userEmailState } from '../recoilstate'
import GoogleLogin from 'react-google-login';
import jwt_decode from 'jwt-decode'
import { track } from '../tracking'



const RegisterationStep1 = ({ step, setStep }) => {

    const [seePassword, setSeePassword] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [newsletter_subscription, setNewsLetterSubscription] = useState(true)
    //for snack
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState('')
    const [msg, setMsg] = useState('')
    const [isloading, setLoader] = useState(false)
    const [agree, setAgree] = useState(true)

    const [user, setUser] = useRecoilState(userState)
    const [emailState, setEmailState] = useRecoilState(userEmailState)


    const registerfromgoogle = async (response) => {
        var user_object = jwt_decode(response.credential)
        const user = await axios.post(process.env.REACT_APP_BACKEND_URL + 'register1', { email: user_object.email, password: user_object.aud, first_name: user_object.given_name, last_name: user_object.family_name, newsletter_subscription, email_isverified: true })
            .then((res) => {
                if (res.data.status === 'success') {
                    setOpen(true)
                    setColor('green')
                    setMsg(`We've got your info! Redirecting to prefrences..`)
                    setUser(res.data.newUser)
                    setEmailState(res.data.newUser.email)
                    localStorage.setItem('user', res.data.newUser.email)
                    setTimeout(() => {
                        setStep(step + 1)
                    }, 2000);
                    return
                } else {
                    setOpen(true)
                    setColor('#bb2124')
                    setMsg(res.data.msg + ' :: Please try again or reach us at hello@bnbbuyer.com')
                    setLoader(false)
                    return
                }
            })
            .catch((err) => {
                setOpen(true)
                setColor('#bb2124')
                setMsg(`Error4 ${err} occured ::  Please try again or reach us at hello@bnbbuyer.com `)
                setLoader(false)
            })
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })

        /* global google */
        google.accounts.id.initialize({
            
            /* client_id: "177032038340-jul0gcjukdu8turooase19b1divjlc09.apps.googleusercontent.com", */
            client_id: "986334400391-2f8f0bbi4atlhpf8k58e9cikc7mph0ed.apps.googleusercontent.com",
            callback: registerfromgoogle
        })

        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            { theme: "outline", size: 'large' }
        )


    }, [])







    //register function
    const register1 = async () => {
        if (email === '' || password === '' || first_name === '' || last_name === '') {
            setOpen(true)
            setColor('red')
            setMsg('Please fill in all the fields.')
            return

        }

        if (password.length < 5) {
            setOpen(true)
            setColor('red')
            setMsg('Password should be greater than 5 characters.')

            return
        }






        setLoader(true)





        const user = await axios.post(process.env.REACT_APP_BACKEND_URL + 'register1', { email, password, first_name, last_name, newsletter_subscription, email_isverified: false })
            .then((res) => {
                if (res.data.status === 'success') {
                    setOpen(true)
                    setColor('green')
                    setMsg(`We've got your info! Redirecting to prefrences..`)
                    setUser(res.data.newUser)
                    setEmailState(res.data.newUser.email)
                    localStorage.setItem('user', res.data.newUser.email)
                    setTimeout(() => {
                        setStep(step + 1)
                        track(null,'user_created',email)
                    }, 2000);
                    return
                } else {
                    setOpen(true)
                    setColor('#bb2124')
                    setMsg(res.data.msg + ' :: Please try again or reach us at hello@bnbbuyer.com')
                    setLoader(false)
                    return
                }
            })
            .catch((err) => {
                setOpen(true)
                setColor('#bb2124')
                setMsg(`Error4 ${err} occured ::  Please try again or reach us at hello@bnbbuyer.com `)
                setLoader(false)
            })




        /* setStep(step + 1) */
    }


    useMemo(()=>{
        const email = localStorage.getItem('user') || null
         track(null,'sign_up', email)
     },[])
    return (
        <>
            <Snack open={open} setOpen={setOpen} msg={msg} color={color} />
            {/*
           
           <Div className='font-extrabold text-xl text-center hidden lg:block ' >Sign Up</Div>
           <Div className='flex justify-center ' >
                <img
                    alt='steps'
                    src='https://github.com/JusticeFrancis/airbnb_images/blob/main/s1.png?raw=true'
                    variant='square'
                    style={{ width:'90%' }}
                />
            </Div> */}
            <Div className='font-extrabold text-xl   lg:hidden ' >Registration Info</Div>

            <Div className='space-y-3'>
                <Div className='space-y-2' >
                    <Label className='font-semibold' >Email</Label>
                    <InputBase className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1'
                        sx={{ fontSize: '15px' }}
                        placeholder='xyz@example.com'
                        value={email}
                        type='email'
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                </Div>
                <Div className='space-y-2' >
                    <Label className='font-semibold' >Full Name</Label>
                    <Div className='flex items-center space-x-3' >
                        <InputBase className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-1/2 py-1'
                            sx={{ fontSize: '15px' }}
                            placeholder='First Name'
                            value={first_name}
                            onChange={(e) => {
                                setFirstName(e.target.value)
                            }}
                        />
                        <InputBase className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-1/2 py-1'
                            sx={{ fontSize: '15px' }}
                            placeholder='Last Name'
                            value={last_name}
                            onChange={(e) => {
                                setLastName(e.target.value)
                            }}
                        />
                    </Div>
                </Div>
                <Div className='space-y-2' >
                    <Label className='font-semibold' >Password</Label>
                    <InputBase id='password' className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1'
                        sx={{ fontSize: '15px' }}
                        placeholder='Enter Password' type={seePassword ? 'text' : 'password'}
                        endAdornment={
                            seePassword ? (
                                <VisibilityOff sx={{ color: 'gray', opacity: '0.7', position: 'absolute', right: 2 }} onClick={() => {
                                    if (seePassword) { setSeePassword(false) }
                                    else { setSeePassword(true) }

                                }}

                                />
                            ) : (
                                <Visibility sx={{ color: 'gray', opacity: '0.7', position: 'absolute', right: 2 }} onClick={() => {
                                    if (seePassword) { setSeePassword(false) }
                                    else { setSeePassword(true) }

                                }}

                                />
                            )
                        }


                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                    />
                </Div>
            </Div>

            <Div>
                <Div className='flex items-center justify-between' >
                    <Divider className='w-[45%] ' sx={{ borderBottom: '1px solid black' }} />
                    <Div className='w-[4%] text-center text-xl'>or</Div>
                    <Divider className='w-[45%]' sx={{ borderBottom: '1px solid black' }} />
                </Div>
            </Div>

            <Div className='flex items-center  justify-center' >
                {/* <Div  >
                    <Button
                    id='signInDiv'
                        className='shadow-md'
                        sx={{ textTransform: 'none', color: 'gray', textSize: '0.8rem', width: '15rem' }}
                        startIcon={
                            <Google />
                        }
                    >
                        Sign in with Google
                    </Button>
                </Div> */}

                <Div>
                    <Div id='signInDiv'></Div>
                </Div>
            </Div>

            <Div>
                <Div className='flex items-center text-center font-[600] text-sm' >
                    <Checkbox checked={newsletter_subscription}
                        onClick={() => setNewsLetterSubscription(!newsletter_subscription)}
                        sx={{ color: '#1ebf99', '& .MuiSvgIcon-root': { fontSize: 20 }, '&.Mui-checked': { color: '#1ebf99' } }} />
                    <Div> Send me established short term rentals for sale in the Bnb Buyer newsletter</Div>
                </Div>
                <Div className='flex items-center text-center font-[600] text-sm' >
                    <Checkbox checked={agree}
                        onClick={() => setAgree(!agree)}
                        sx={{ color: '#1ebf99', '& .MuiSvgIcon-root': { fontSize: 20 }, '&.Mui-checked': { color: '#1ebf99' } }} />
                    <Div> By Signing Up I Agree To The Bnb Buyer
                        <Anchor textColor='#1ebf99' hoverTextColor='#1ebf99' onClick={() => window.location.href = 'https://bnbbuyer.com/terms'} > Terms Of Service </Anchor> And
                        <Anchor textColor='#1ebf99' hoverTextColor='#1ebf99' onClick={() => window.location.href = 'https://bnbbuyer.com/privacy-policy'}> Privacy Policy </Anchor></Div>
                </Div>
            </Div>


            <Div>
            </Div>

            <Div>

                <Button
                    disabled={isloading === true || agree === false ? true : false}
                    className='shadow-md'
                    sx={{ textTransform: 'none', textSize: '0.8rem', width: '15rem', float: 'right', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                    onClick={register1}

                >
                    Next
                </Button>

            </Div>
        </>
    )
}

export default RegisterationStep1