import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, InputBase } from '@mui/material'
import { Div, Label, Modal } from 'atomize'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useRecoilState } from 'recoil'
import { userEmailState, userLocationState } from '../recoilstate'
import { useLocation, useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'



const Login = ({ openLoginModal, setOpenLoginModal, property_id }) => {
    const navigate = useNavigate()
    const [seePassword, setSeePassword] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isloading, setLoader] = useState(false)
    const [msg, setMsg] = useState('')
    const [emailState, setEmailState] = useRecoilState(userEmailState || '')
    
    const search = useLocation().search;
  const redirect = new URLSearchParams(search).get('redirect') || false;

  let location_from_dashboard = localStorage.getItem('user_location_from_dashboard')

  console.log(location_from_dashboard)
  
    const loginfromgoogle = async (response) => {
        let location_from_dashboard = localStorage.getItem('user_location_from_dashboard')

        var user_object = jwt_decode(response.credential)
        const user = await axios.post(process.env.REACT_APP_BACKEND_URL + 'login', { email: user_object.email, password: user_object.aud })
            .then((res) => {
                if (res.data.status === 'success') {
                    localStorage.setItem('user', res.data.user.email)
                    setMsg(`You're in ! Redirecting you now`)
                    setTimeout(() => {
                        setEmailState(res.data.user.email)
                        setLoader(false)
                        setOpenLoginModal(false)
                        if(redirect === 'login' && location_from_dashboard){
                            window.location.href = location_from_dashboard
                        }
                        
                    }, 2000);
                } else {
                    setMsg(res.data.msg)
                    setLoader(false)

                }
            })
            .catch((err) => {
                setMsg(err + ' please try again')
                setLoader(false)
            })
    }

    //google login
    useEffect(() => {


        /* global google */
        google.accounts.id.initialize({
            /* client_id: "177032038340-jul0gcjukdu8turooase19b1divjlc09.apps.googleusercontent.com", */
             client_id: "986334400391-2f8f0bbi4atlhpf8k58e9cikc7mph0ed.apps.googleusercontent.com",
            callback: loginfromgoogle
        })

        google.accounts.id.renderButton(
            document.getElementById("loginInDiv"),
            { theme: "outline", size: 'large' }
        )


    }, [openLoginModal === true])


    const login = async () => {
        setLoader(true)
        localStorage.removeItem('user')
        let location_from_dashboard = localStorage.getItem('user_location_from_dashboard')

        const log = await axios.post(process.env.REACT_APP_BACKEND_URL + 'login', { email, password })
            .then((res) => {
                if (res.data.status === 'success') {
                    localStorage.setItem('user', res.data.user.email)
                    setMsg(`You're in ! Redirecting you now`)
                    setTimeout(() => {
                        setEmailState(res.data.user.email)
                        setLoader(false)
                        setOpenLoginModal(false)
                        
            if(redirect === 'login' && location_from_dashboard){
                window.location.href = location_from_dashboard
            }
            
                    }, 2000);
                } else {
                    setMsg(res.data.msg)
                    setLoader(false)

                }
            })
            .catch((err) => {
                setMsg(err + ' please try again')
                setLoader(false)
            })

    }


    //tracker starts here

    let propertypage = false

    if (window.location.pathname.substring(1, 13) === 'house-detail') {
        propertypage = true
    }
    const urlParams = new URLSearchParams(window.location.search)
    const subscriber_id = urlParams.get('subscriberId') || null

    const tracking = 'properties'

    let ip_address = null

    const getIp = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        ip_address = res.data.IPv4


    }


    useEffect(() => {
        if (openLoginModal && propertypage) {
            getIp()
        }


    }, [openLoginModal === true])


    const pathname = window.location.pathname
    let pid = pathname.substring(14)

    //tracker ends here
    return (
        <Modal isOpen={openLoginModal} onClose={() => setOpenLoginModal(false)} className='relative' bg='#fafafa' align={{ xs: 'center', lg: 'center' }}
            maxW={{ lg: '24rem', xs: '21rem' }}

        >
            <Close sx={{ fontSize: '1.5rem', cursor: 'pointer', position: 'absolute', top: 4, right: 2 }} onClick={() => setOpenLoginModal(false)} />

            <Div >

                <Div className={msg === `You're in ! Redirecting you now` ? ' text-sm text-green-800 text-center ' : ' text-sm text-red-500 text-center '} >{msg}</Div>

                <Div className='space-y-3'>
                    <Div className='space-y-2' >
                        <Label className='font-semibold' >Email</Label>
                        <InputBase className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1'
                            sx={{ fontSize: '15px' }}
                            placeholder='xyz@example.com'
                            value={email}
                            type='email'
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </Div>

                    <Div className='space-y-2' >
                        <Label className='font-semibold' >Password</Label>
                        <InputBase id='password' className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1'
                            sx={{ fontSize: '15px' }}
                            placeholder='Enter Password' type={seePassword ? 'text' : 'password'}
                            endAdornment={
                                seePassword ? (
                                    <VisibilityOff sx={{ color: 'gray', opacity: '0.7', position: 'absolute', right: 2, cursor: 'pointer' }} onClick={() => {
                                        if (seePassword) { setSeePassword(false) }
                                        else { setSeePassword(true) }

                                    }}

                                    />
                                ) : (
                                    <Visibility sx={{ color: 'gray', opacity: '0.7', position: 'absolute', right: 2, cursor: 'pointer' }} onClick={() => {
                                        if (seePassword) { setSeePassword(false) }
                                        else { setSeePassword(true) }

                                    }}

                                    />
                                )
                            }


                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                        />
                    </Div>
                    <Div className='text-center' >
                        <Div id='loginInDiv'></Div>
                    </Div>
                    <Div onClick={() => {
                        navigate('/forgot-password')
                        setOpenLoginModal(false)
                    }} textColor='#1ebf99' className='text-sm cursor-pointer' >forgot password?</Div>
                    <Div className='flex justify-center' >

                        <Button
                            disabled={isloading}
                            className='shadow-md'
                            sx={{ textTransform: 'none', textSize: '0.8rem', width: '15rem', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                            onClick={login}
                        >
                            Login
                        </Button>

                    </Div>

                    <Div onClick={() => {
                        navigate('/register')
                        setOpenLoginModal(false)
                    }} textColor='#1ebf99' className='text-sm cursor-pointer text-center relative top-4 underline ' > Not a member yet? Sign Up Here!</Div>
                </Div>


            </Div>
        </Modal>
    )
}

export default Login