import { Div, Row, Tag } from "atomize";
import React, { useState, useEffect, useMemo } from "react";
import { ArrowBack, ArrowDropDown, Pending, Sell } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress, Divider } from "@mui/material";
import Currency from "react-currency-formatter";
import { Home, KingBed, AspectRatio } from "@mui/icons-material";
import House from "../components/House";
import ProFormaIncomeStatement from "../reusables/ProFromaIncomeStatement";
import Maps from "../reusables/Maps";
import HousesCarousel from "../components/HousesCarousel";
import JoinPremiumAccessModal from "../modals/JoinPremiumAccessModal";
import axios from "axios";
import { useRecoilState } from "recoil";
import {
  propertiesState,
  userEmailState,
  userLocationState,
} from "../recoilstate";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SimilarHouse from "../components/SimilarHouse";
import { track } from "../tracking";
import { Helmet } from "react-helmet";
import HelmetMolecule from "../molecules/HelmetMolecule";
import moment from "moment";
import ReactCurrencyFormatter from "react-currency-formatter";
import Graph from "../reusables/Graph";
import Share from "../molecules/Share";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const HouseDetailScreen = () => {
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [link, setLink] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useRecoilState(userEmailState);
  const [properties, setProperties] = useState([]);
  const [openJoinPremiumAccessModal, setOpenJoinPremiumAccessModal] =
    useState();
  const { id } = useParams();
  const [loader, setLoader] = useState(true);
  const [property, setProperty] = useState(null);
  const [similarProperties, setSimilarProperties] = useState([]);

  async function getProperty() {
    const properties = await axios
      .get(process.env.REACT_APP_BACKEND_URL + `property/${id}`)
      .then((res) => {
        if (res.data.status === "success") {
          setProperty(res.data.property);
          if (res.data.property === null) {
            navigate("/property-not-found");
          }
          console.log(res.data.property.price_history)
          setLink(res.data.property.link.substr(12, 1));

          getAllProperties();
          getSimilarProperties(res.data.property.similar_properties);
        }
      });
    async function getSimilarProperties(similar) {
      const similarProperties = await axios
        .post(process.env.REACT_APP_BACKEND_URL + `similar-properties`, {
          property_ids: similar,
        })
        .then((res) => {
          if (res.data.status === "success") {
            setSimilarProperties(res.data.properties);
          }
        });
    }

    async function getAllProperties() {
      const properties = await axios
        .get(process.env.REACT_APP_BACKEND_URL + "properties")
        .then((res) => {
          if (res.data.status === "success") {
            setProperties(res.data.properties);
            setLoader(false);
          }
        });
    }
  }

  //middleware
  const [hideCalc, setHideCalc] = useState(true);
  const [hideCalcReason, setHideCalcReason] = useState("login");

  const getUser = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `stripe/retrieve-customer`, {
        email,
      })
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.subscription?.plan.active) {
            setHideCalc(false);
            setHideCalcReason("");
          } else {
            if (email && email !== "") {
              setHideCalcReason("plan");
            } else {
              setHideCalcReason("login");
            }
          }
        } else {
          if (res.data.msg === "failed to get customer billing details") {
            setHideCalcReason("plan");
          }
        }
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getProperty();
    if (!email || email === "") {
      setHideCalcReason("login");
    } else {
      getUser();
    }
  }, [email]);

  //capture user current location

  const [userlocation, setUserLocation] = useRecoilState(userLocationState);

  useEffect(() => {
    setUserLocation(String(window.location.pathname).slice(1));
    localStorage.setItem(
      "user_location",
      String(window.location.pathname).slice(1)
    );
  });

  useMemo(() => {
    if (typeof property?.property_id === "number") {
      const email = localStorage.getItem("user") || null;
      track(property?.property_id, "property_page", email);
    }
  }, [property]);

  return (
    <>
    
      {!loader ? (
        <>
        <HelmetMolecule property={property}/>
          {Object.values(property).length > 0 ? (
            <Div className="lg:w-[60vw] lg:space-y-5  lg:mt-8  mt-5 ">
              <Div
                className="flex items-center text-black font-semibold cursor-pointer mb-5 lg:mb-0"
                onClick={() => {
                  if (document.referrer === "https://app.bnbbuyer.com/") {
                    navigate(-1);
                  } else {
                    navigate("/search-houses");
                  }
                }}
              >
                <Div>
                  <ArrowBack sx={{ color: "black" }} />
                </Div>
                <Div>back to search</Div>


                {property.price_history && property.status !== 'sold' && (
                    <div class="bg-gray-200 ml-3 rounded-sm text-xs px-1 font-semibold ">
                    {" "}
                   <ArrowDropDown sx={{ fontSize: "34px", color: "#363636" }} /> 
                   <span className="relative right-2" >
                   ${property.price_history[0].price_drop  > 1000 ? (property.price_history[0].price_drop / 1000)+'k' : (property.price_history[0].price_drop) }   
                    <span> </span> Price Drop
                     { property.price_history[0].updateDate && " "+moment(property.price_history[0].updateDate).format("MM/DD") } </span> 
                  </div>
                ) }

                {property.status == 'sold' && (
                    <div class="bg-gray-200 ml-3 rounded-sm text-xs px-1 py-1 font-semibold ">
                    {" "}
                    <Sell sx={{ fontSize: "", color: "gray" }} /> Sold { property.sold_date && "-"+moment(property.sold_date).format("MM/DD") }  
                  </div>
                )}

              {property.status == 'pending' && (
                  <div class="bg-orange-100 ml-3 rounded-sm text-xs px-1 py-1 font-semibold  ">
                  {" "}
                  <Pending
                    sx={{
                      fontSize: "",
                      color: "gray",
                      mr: "0.6px",
                      position: "relative",
                      bottom: 1,
                    }}
                  />{" "}
                  Pending
                </div>
              )}
              </Div>

              <Div className="lg:flex lg:space-x-10">
                <Div className="lg:w-3/5">
                  <HousesCarousel houses={property.photos} />
                </Div>
                <Div className="lg:w-2/5 w-full mt-5 lg:mt-0">
                  <Div className="flex items-center justify-between">
                    <Div>
                      <Div className=" text-[#1ebf99] text-2xl font-semibold">
                        Asking Price
                      </Div>
                      <Div className="text-md font-semibold">
                        $
                        <Currency
                          quantity={property.price}
                          decimal={0}
                          pattern={"##,###!"}
                          symbol=""
                        />
                      </Div>
                    </Div>
                    <Div>
                      <Div className=" text-[#1ebf99] text-2xl font-semibold">
                        Revenue
                      </Div>
                      <Div className="text-md font-semibold">
                        $
                        <Currency
                          quantity={property.annual_revenue}
                          decimal={0}
                          pattern={"##,###!"}
                          symbol=""
                        />
                      </Div>
                    </Div>
                  </Div>

                  <Div className="space-y-5 lg:mt-14 mt-7 mb-7 ">
                    <Div className="flex justify-center">
                      <Button
                        className="shadow-md"
                        sx={{
                          textTransform: "none",
                          textSize: "0.8rem",
                          width: "80%",
                          bgcolor: "#1ebf99",
                          color: "white",
                          ":hover": { color: "black" },
                          mx: "auto",
                        }}
                        onClick={() => {
                          if (hideCalcReason == "login") {
                            setOpenLoginModal(true);
                            track(
                              property?.property_id,
                              "contact_seller",
                              email
                            );
                          }

                          if (hideCalcReason === "plan") {
                            setOpenJoinPremiumAccessModal(true);
                            track(
                              property?.property_id,
                              "contact_seller",
                              email
                            );
                          }

                          if (!hideCalc) {
                            track(
                              property?.property_id,
                              "contact_seller",
                              email
                            );
                            const a = document.createElement("a");
                            a.href = "mailto:" + property.email;
                            a.click();
                          }
                        }}
                      >
                        {hideCalcReason === "login"
                          ? "Contact Seller"
                          : " Contact Seller"}
                      </Button>
                      <Elements stripe={stripe}>
                        <JoinPremiumAccessModal
                          openJoinPremiumAccessModal={
                            openJoinPremiumAccessModal
                          }
                          setOpenJoinPremiumAccessModal={
                            setOpenJoinPremiumAccessModal
                          }
                          property_id={property.property_id}
                          defEmail={email}
                        />
                      </Elements>
                    </Div>

                    {property.link && (
                      <Div className="flex justify-center">
                        <Button
                          className="shadow-md"
                          sx={{
                            textTransform: "none",
                            textSize: "0.8rem",
                            width: "80%",
                            bgcolor: "#1ebf99",
                            color: "white",
                            ":hover": { color: "black" },
                          }}
                          onClick={() => {
                            if (hideCalcReason == "login") {
                              setOpenLoginModal(true);
                              track(property?.property_id, "link1", email);
                            }

                            if (hideCalcReason === "plan") {
                              setOpenJoinPremiumAccessModal(true);
                              track(property?.property_id, "link1", email);
                            }

                            if (!hideCalc) {
                              window.open(property.link, "_blank");
                              track(property?.property_id, "link1", email);
                            }
                          }}
                        >
                          {property.link1Type
                            ? "View on " + property.link1Type
                            : "View Listing"}
                        </Button>
                      </Div>
                    )}

                    {property.link2 && (
                      <Div className="flex justify-center">
                        <Button
                          className="shadow-md"
                          sx={{
                            textTransform: "none",
                            textSize: "0.8rem",
                            width: "80%",
                            bgcolor: "#1ebf99",
                            color: "white",
                            ":hover": { color: "black" },
                          }}
                          onClick={() => {
                            if (hideCalcReason == "login") {
                              setOpenLoginModal(true);
                              track(property?.property_id, "link2", email);
                            }

                            if (hideCalcReason === "plan") {
                              setOpenJoinPremiumAccessModal(true);
                              track(property?.property_id, "link2", email);
                            }

                            if (!hideCalc) {
                              window.open(property.link2, "_blank");
                              track(property?.property_id, "link2", email);
                            }
                          }}
                        >
                          {property.link2Type
                            ? "View on " + property.link2Type
                            : "View Listing"}
                        </Button>
                      </Div>
                    )}

<div className="flex justify-center text-xs italic items-center">
<Share property={property} />
</div>
                    {/* <Div className='flex justify-center'>

                                                <Button
                                                    className='shadow-md'
                                                    sx={{ textTransform: 'none', textSize: '0.8rem', width: '80%', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                                                    onClick={() => {
                                                        if(hideCalcReason==='plan'){
                                                            setOpenJoinPremiumAccessModal(true)
                                                        }
    
                                                        if(!hideCalc){
                                                            window.open(property.link, '_blank')
                                                        }
                                                    }}
                                                >
                                                    View on AirBnb
                                                </Button>

                                            </Div> */}
                  </Div>
                </Div>
              </Div>
              <Div>
                <Div className="text-2xl text-black font-bold">
                  {property.title} 
                </Div>
                <Div className="text-sm text-gray-400 text-left my-4">
                  {property.description}
                </Div>
                <Div className="text-[0.8rem]  text-gray-400 flex items-center mt-2 space-x-4">
                  <Div>
                    <Home
                      sx={{
                        color: "#dde0e5",
                        fontSize: "1.2rem",
                        position: "relative",
                        bottom: "2px",
                      }}
                    />{" "}
                    {property.prop_type} house
                  </Div>
                  <Div>
                    <KingBed
                      sx={{
                        color: "#dde0e5",
                        fontSize: "1.2rem",
                        position: "relative",
                        bottom: "2px",
                      }}
                    />{" "}
                    {property.beds} bed rooms
                  </Div>
                  <Div>
                    <AspectRatio
                      sx={{
                        color: "#dde0e5",
                        fontSize: "1.2rem",
                        position: "relative",
                        bottom: "2px",
                      }}
                    />{" "}
                    {property.sqft} sqft
                  </Div>
                </Div>
              </Div>

              {property.revenue_history && (
                <Div className="my-4">
                <Graph
                 hide={hideCalc}
                 reason={hideCalcReason}
                 setOpenJoinPremiumAccessModal={setOpenJoinPremiumAccessModal}
                 setOpenLoginModal={setOpenLoginModal}
                 openLoginModal={openLoginModal}
                 property_id={property.property_id}
                 revenue_history = {property.revenue_history.map((item,index)=> ({
                    date: Object.keys(item)[0],
                    amt: Object.values(item)[0],
                    name : index
                 }))}
                />
                </Div>
  
              )}
              <Div className="my-4">
                <Div className="text-lg mb-2 text-black font-bold">
                  {" "}
                  View On Map
                </Div>
                <Div className=" ">
                  <Maps lat={property.lat} lng={property.long} />
                </Div>
              </Div>

              {/* proforma statement */}

              <ProFormaIncomeStatement
                hide={hideCalc}
                reason={hideCalcReason}
                setOpenJoinPremiumAccessModal={setOpenJoinPremiumAccessModal}
                setOpenLoginModal={setOpenLoginModal}
                openLoginModal={openLoginModal}
                pp={property.price || 0}
                hoai={property.hoa === NaN || !property.hoa ? 0 : property.hoa}
                gar={property.annual_revenue || 0}
                tax={property.tax_rates || 0}
                sqft={
                  !property.sqft || property.sqft === NaN ? 0 : property.sqft
                }
                property_id={property.property_id}
                defaultCleaner={property.cleaner || 0}
                defaultInsurance={property.insurance_amount || 0}
                defaultManagementFee={property.management_fee || 0}
                defaultStr={property.str_platform_fee || 0}
                defaultUtilities={property.utilities || 0}
                id={property.property_id}
              />

              {/* other properties you may like */}

              <Div className="mt-9">
                <Div className="text-sm text-black font-semibold">
                  {" "}
                  Other properties you may like
                </Div>
                <Divider
                  sx={{
                    borderBottom: "2px solid gray",
                    opacity: "0.5",
                    mt: 1,
                    mb: 5,
                  }}
                />

                <Div>
                  <Row>
                    {similarProperties.map((item, index) => {
                      return (
                        <SimilarHouse
                          orientation={"grid"}
                          key={index}
                          property={item}
                        />
                      );
                    })}
                  </Row>
                </Div>
              </Div>
            </Div>
          ) : (
            "nothing to show"
          )}
        </>
      ) : (
        <Div className=" h-[100vh]  ">
          <CircularProgress
            size={"4rem"}
            sx={
              loader
                ? {
                    color: "#1ebf99",
                    display: "block",
                    position: "relative",
                    top: "30%",
                  }
                : { color: "#1ebf99", display: "none" }
            }
          />
        </Div>
      )}
    </>
  );
};

export default HouseDetailScreen;
