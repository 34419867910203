import { InputBase, Button } from '@mui/material'
import { Div, Label } from 'atomize'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Snack from '../reusables/Snack'
import { userEmailState } from '../recoilstate'
import { useRecoilState } from 'recoil'

const ChangePasswordScreen = () => {
    const { token } = useParams()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isloading, setLoader] = useState(true)

    const [msg, setMsg] = useState('')
    const [status, setStatus] = useState('ok')


    //for snack
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState('')

    const [redirectBackUrl, setRedirectBackUrl] = useState('')


    const [userEmail, setUserEmail] = useRecoilState(userEmailState)



    const verifyToken = async () => {
        setLoader(true)



        const verify = await axios.post(process.env.REACT_APP_BACKEND_URL + 'verify-forgot-password-token', { email, token })
            .then((res) => {
                if (res.data.status === 'success') {
                    setLoader(false)
                    setEmail(res.data.authData.email)
                    setRedirectBackUrl(res.data.authData.url)

                } else {
                    setMsg(res.data.msg)
                    setStatus('invalid')

                }
            })
            .catch((err) => {
                setMsg(err + ' please try again')
                setStatus('invalid')
            })

    }


    const changePassword = async () => {

        if (password !== confirmPassword || password === '') {
            setMsg("Passwords must match!")
            return
        }
        setLoader(true)



        const verify = await axios.post(process.env.REACT_APP_BACKEND_URL + 'change-password', { email, password, redirectBackUrl })
            .then((res) => {
                if (res.data.status === 'success') {
                    setLoader(false)
                    setUserEmail(email)

                    setMsg('success, redirecting back')
                    setOpen(true)
                    setColor('green')

                    localStorage.setItem('user',email)

                    setTimeout(() => {
                        window.location.href = redirectBackUrl
                    }, 3000);

                } else {
                    setMsg(res.data.msg)

                }
            })
            .catch((err) => {
                setMsg(err + ' please try again')
            })

    }

    useEffect(() => {
        verifyToken()
    }, [])
    return (
        <Div className='h-[100vh]' >
            <Snack open={open} setOpen={setOpen} msg={msg} color={color} />

            <Div className='w-full lg:w-[30vw] bg-white shadow-md p-4 space-y-4  ' >
                <Div className={' text-sm text-red-500 text-center '} >{msg}</Div>
                <Div className='space-y-2' >
                    <Label className='font-semibold' >Email</Label>
                    <InputBase disabled={true} className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1'
                        sx={{ fontSize: '15px' }}
                        placeholder='xyz@example.com'
                        value={email}
                        type='email'
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                </Div>


                <Div className='space-y-2' >
                    <Label className='font-semibold' >New Password</Label>
                    <InputBase className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1'
                        sx={{ fontSize: '15px' }}
                        type='password'
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                    />
                </Div>



                <Div className='space-y-2' >
                    <Label className='font-semibold' >Confirm Password</Label>
                    <InputBase className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1'
                        sx={{ fontSize: '15px' }}
                        type='password'
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value)
                        }}
                    />
                </Div>


                <Div>

                    <Button
                        disabled={isloading}
                        className='shadow-md'
                        sx={{ textTransform: 'none', textSize: '0.8rem', width: '100%', float: '', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                        onClick={changePassword}

                    >
                        Change
                    </Button>

                </Div>
                {status === 'invalid' && (
                    <Div className='text-[#00ccbb] text-sm cursor-pointer underline' >
                        change email ?
                    </Div>
                )}
            </Div>

        </Div>
    )
}

export default ChangePasswordScreen