import { Div, Tag } from 'atomize'
import React, { useState, useEffect, createRef, useCallback, useRef } from 'react'
import { Button, Divider, InputBase, Tooltip } from '@mui/material'
import Currency from 'react-currency-formatter'
import { IosShare, AttachMoney, Percent, HelpOutline, Lock } from '@mui/icons-material'
import Login from '../modals/LoginModal'
import axios from 'axios'
import '../mystyles.css'
import { track } from '../tracking'
import Graph from './Graph'


const ProFormaIncomeStatement = ({ hide, reason, setOpenJoinPremiumAccessModal, pp, hoai, gar, tax, sqft, property_id,openLoginModal,setOpenLoginModal,
    defaultCleaner, defaultInsurance, defaultManagementFee,defaultStr,defaultUtilities,id
    
 }) => {
   

    //data 1
    const [down_payment, setDownPayment] = useState(20)
    const [closing_cost, setClosingCost] = useState(0.01 * pp)
    const [furnishes_supplies, setFurnishesSupplies] = useState(10000)
    const [total_upfront_investment, setTotalUpfrontInvestment] = useState(0)
    const [purchase_price, setPurchasePrice] = useState(pp)
    const [gross_annual_revenue, setGrossAnnualRevenue] = useState(gar)

    const TotalUpfrontInvestMentCalc = () => {
        const result = (Number(purchase_price) * (Number(down_payment) / 100)) + Number(closing_cost) + Number(furnishes_supplies)
        setTotalUpfrontInvestment(result)
    }




    //data 2
    const [mortgage_rate, setMortgageRate] = useState(5)
    const [property_tax, setPropertyTax] = useState(tax * pp)
    const [insurance, setInsurance] = useState(defaultInsurance)
    const [utilities, setUtilities] = useState(defaultUtilities)
    const [cleaners, setCleaners] = useState(defaultCleaner)
    const [mortgage_payment, setMortgagePayment] = useState(0)
    const [monthly_expenses, setMonthlyExpenses] = useState(0)
    const [hoa, setHoa] = useState(hoai)




    const MortgagePaymentCalc = () => {
        const x = (Number(purchase_price) * (1 - (Number(down_payment) / 100)) * ((Number(mortgage_rate) / 100) / 12))
        const y = (1 + (Number(mortgage_rate) / 100) / 12) ** 360 / ((1 + (Number(mortgage_rate) / 100) / 12) ** 360 - 1)
        const result = x * y
        setMortgagePayment(result)
    }




    const MonthlyExpensesCalc = () => {
        const result = (Number(property_tax) / 12) + (Number(hoa) / 12) + (Number(insurance) / 12) + Number(utilities) + Number(cleaners)
        setMonthlyExpenses(result)
    }


    //data 3

    const [management_fee, setManagementFee] = useState(defaultManagementFee * 100)
    const [str_platform_fee, setStrPlatformFee] = useState(defaultStr * 100)
    /* const [tax_reserve, setTaxReserve] = useState(14) */
    const [net_revenue, setNetRevenue] = useState(0)
    const [operating_expenses, setOperatingExpenses] = useState(0)
    const [net_cash_flow, setNetCahsFlow] = useState(0)
    const [mortgage_payments, setMortgagePayments] = useState(0)


    const MortgagePaymentsCalc = () => {

        const result = Number(mortgage_payment) * 12
        setMortgagePayments(result)
    }

    const NetRevenueCalc = () => {
       /*  const result = Number(gross_annual_revenue) * (1 - (Number(management_fee) / 100)) * (1 - (Number(str_platform_fee) / 100)) * (1 - (Number(tax_reserve) / 100)) */

        const result = Number(gross_annual_revenue) * (1 - (Number(management_fee) / 100)) * (1 - (Number(str_platform_fee) / 100))
        setNetRevenue(result)
    }

    const OperatingExpensesCalc = () => {
        const result = 12 * Number(monthly_expenses)
        setOperatingExpenses(result)
    }


    const NetCashFlowCalc = () => {
        const result = Number(net_revenue) - Number(mortgage_payments) - Number(operating_expenses)
        setNetCahsFlow(result)
    }





    useEffect(() => {

        TotalUpfrontInvestMentCalc()

    }, [down_payment, closing_cost, furnishes_supplies, purchase_price]) 

    useEffect(() => {

        MortgagePaymentCalc()

    }, [down_payment, mortgage_rate, purchase_price])

    useEffect(() => {

        MonthlyExpensesCalc()

    }, [insurance, utilities, cleaners, hoa, property_tax])

    useEffect(() => {

        NetRevenueCalc()

    }, [management_fee, str_platform_fee])

    useEffect(() => {

        MortgagePaymentsCalc()

    }, [mortgage_payment, down_payment])

    useEffect(() => {

        OperatingExpensesCalc()

    }, [monthly_expenses])

    useEffect(() => {

        NetCashFlowCalc()

    }, [net_revenue, mortgage_payments, operating_expenses])


    


    const generatePdf = () => {
        const apikey = '5c8ad59d97msh0b09e5051f33a36p14ab70jsn106e85513be8'
        const options = {
            method: 'POST',
            url: 'https://yakpdf.p.rapidapi.com/pdf',
            headers: {
                'content-type': 'application/json',
                'X-RapidAPI-Key': '90e9ba5495msh137d5b6da832a65p1bce3ejsna6f5dbdd6263',
                'X-RapidAPI-Host': 'yakpdf.p.rapidapi.com'
            },
            data: '{"pdf":{"format":"A4","printBackground":true,"scale":1},"source":{"html":"<!DOCTYPE html><html lang=\"en\"><head><meta charset=\"UTF-8\"><meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\"></head><body><h1>Hello World!</h1></body></html>"},"wait":{"for":"navigation","timeout":250,"waitUntil":"load"}}'
        };

        axios.post(options).then(function (response) {
        }).catch(function (error) {
        });

    }

    return (
        <>

       

            <Login openLoginModal={openLoginModal} setOpenLoginModal={setOpenLoginModal} property_id={property_id} />
            {/* proforma statement */}
            <Div className='bg-white px-3 pt-3 relative' >
                <Div className='flex items-center justify-between '   >
                    <Div>Pro Forma Income Statement </Div>
                   {/*  <Div className='flex justify-center '>

                        <Button
                            disabled={hide}
                            className='shadow-md'
                            sx={{ textTransform: 'none', textSize: '0.8rem', width: '10rem', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                            endIcon={
                                <IosShare sx={{ color: 'white', position: 'relative', bottom: 3, right: 5 }} />
                            }

                            onClick={generatePdf}

                        >
                            Export
                        </Button>

                    </Div> */}
                </Div>

                <Divider sx={{ borderBottom: '2px solid gray', opacity: '0.5', my: 3 }} />


                {reason === 'login' && (
                    <Div className='absolute lg:top-[50%] top-[36%] bg-opacity-5   lg:w-[60vw] w-[90vw] z-40 '  >
                        <Div className='text-center  bg-[#f7f7f7] bg-opacity-80 lg:p-5 p-2 lg:w-[40vw] w-[90vw] mx-auto space-y-4 shadow-lg rounded-xs'  >
                            <Div className='lg:text-xl text-md font-bold ' >Income Statements are available for premium members only.</Div>
                            <Div>

                                <Button
                                    startIcon={
                                        <Lock sx={{ color: 'white', position: 'absolute', left: 3, bottom: { xs: 10, lg: 8 } }} />
                                    }
                                    className='shadow-md'
                                    sx={{ textTransform: 'none', textSize: '0.8rem', width: '15rem', bgcolor: '#1fbf99', color: 'white', ':hover': { color: 'black' }, position: 'relative' }}
                                    onClick={() => {
                                        setOpenLoginModal(true)
                                        const email = localStorage.getItem('user') || null
                                        track(id,'calculator_login', email)
                                    }}
                                >
                                    Login
                                </Button>

                            </Div>

                            <Div className='font-semibold text-sm' >
                                You must login into a paid account to view property calculator
                            </Div>
                        </Div>
                    </Div>
                )}


                {reason === 'plan' && (
                    <Div className='absolute lg:top-[50%] top-[36%] bg-opacity-5   lg:w-[60vw] w-[90vw] z-40 '  >
                        <Div className='text-center  bg-[#f7f7f7] bg-opacity-80 lg:p-5 p-2 lg:w-[40vw] w-[90vw] mx-auto space-y-4 shadow-lg rounded-xs'  >
                            <Div className='lg:text-xl text-md font-bold ' >Income Statements are available for premium members only.</Div>
                            <Div>

                                <Button
                                    startIcon={
                                        <Lock sx={{ color: 'white', position: 'absolute', left: 3, bottom: { xs: 10, lg: 8 } }} />
                                    }
                                    className='shadow-md'
                                    sx={{
                                        textTransform: 'none', textSize: '0.8rem', width: '15rem', bgcolor: '#1fbf99', color: 'white', ':hover': { color: 'black' },
                                        position: 'relative'
                                    }}
                                    onClick={() => {
                                        setOpenJoinPremiumAccessModal(true)
                                        const email = localStorage.getItem('user') || null
                                        track(id,'calculator_upgrade', email)
                                    }}
                                >
                                    Join Premium to View
                                </Button>

                            </Div>

                            <Div className='font-semibold text-sm' >
                                Joining Premium makes it super easy to calculate your COC return, Cap Rate and gives you the ability to contact the seller
                            </Div>
                        </Div>
                    </Div>
                )}

                <Div className={hide ? 'lg:flex lg:space-x-7 blur-sm relative ' : 'lg:flex lg:space-x-7 relative '} >

                    <Div className=' w-full lg:w-1/2'>
                        <Div className='lg:text-xl text-[0.6rem] text-black font-semibold' > Purchase</Div>
                        <Divider sx={{ borderBottom: '1px solid black', opacity: '1', my: 3 }} />

                        <Div className=' lg:w-4/5  space-y-3' >
                            <Div className='flex items-center ' >
                                <Div className='text-sm  flex-1' >Purchase Price</Div>
                                <Div>
                                    <Tag className='border-[2px] border-gray-300 w-[8rem] relative' >
                                        <AttachMoney sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />
                                        <Div>
                                            <InputBase sx={{ height: '1.5rem', pl: '4px', }}
                                                value={purchase_price.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                onChange={(e) => {

                                                    let val = e.target.value //get value from input
                                                    let m = val.replace(/,/g, '') //removing any comma present in the string
                                                    let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                                    e.target.value = result //set input value to the seperate format
                                                    setPurchasePrice(m)
                                                }}
                                            />
                                        </Div>
                                    </Tag>
                                </Div>
                            </Div>
                            <Div className='flex items-center' >
                                <Div className='text-sm flex-1' >Down Payment</Div>
                                <Div>
                                    <Tag className='border-[2px] border-gray-300 w-[8rem] relative flex' >
                                        <Percent sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />
                                        <Div>
                                            <InputBase type='number' sx={{ height: '1.5rem', pl: '4px' }}
                                                value={down_payment}
                                                onChange={(e) => {
                                                    setDownPayment(e.target.value)

                                                }}
                                            />
                                        </Div>
                                    </Tag>
                                </Div>
                            </Div>
                            <Div className='flex items-center' >
                                <Div className='text-sm flex-1' >Closing Cost</Div>
                                <Div>
                                    <Tag className='border-[2px] border-gray-300 w-[8rem] relative flex' >
                                        <AttachMoney sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />
                                        <Div>
                                            <InputBase sx={{ height: '1.5rem', pl: '4px' }}
                                                value={closing_cost.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                onChange={(e) => {

                                                    let val = e.target.value //get value from input
                                                    let m = val.replace(/,/g, '') //removing any comma present in the string
                                                    let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                                    e.target.value = result //set input value to the seperate format
                                                    setClosingCost(m)
                                                }}
                                            />
                                        </Div>
                                    </Tag>
                                </Div>
                            </Div>
                            <Div className='flex items-center' >
                                <Div className='text-sm flex-1' >New Furnishing / Supplies</Div>
                                <Div>
                                    <Tag className='border-[2px] border-gray-300 w-[8rem] relative flex' >
                                        <AttachMoney sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />
                                        <Div>
                                            <InputBase sx={{ height: '1.5rem', pl: '4px' }}
                                                value={furnishes_supplies.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                onChange={(e) => {

                                                    let val = e.target.value //get value from input
                                                    let m = val.replace(/,/g, '') //removing any comma present in the string
                                                    let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                                    e.target.value = result //set input value to the seperate format
                                                    setFurnishesSupplies(m)
                                                }}
                                            />
                                        </Div>
                                    </Tag>
                                </Div>
                            </Div>
                        </Div>

                        <Div>

                            <Divider sx={{ borderBottom: '1px solid black', opacity: '1', my: 3 }} />

                            <Div>
                                <Div className='font-semibold text-md' >
                                    Total Upfront Investment = $<Currency symbol='' pattern='##,###!' quantity={total_upfront_investment} />
                                </Div>
                            </Div>

                            <Divider sx={{ borderBottom: '1px solid black', opacity: '1', my: 3 }} />
                        </Div>

                        <Div className='lg:w-4/5 w-full  space-y-3' >
                            <Div className='flex items-center ' >
                                <Div className='text-sm  flex-1' >Mortgage Rate</Div>
                                <Div>
                                    <Tag className='border-[2px] border-gray-300 w-[8rem] relative flex' >
                                        <Percent sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />
                                        <Div>
                                            <InputBase type='number' sx={{ height: '1.5rem', pl: '4px' }}
                                                value={mortgage_rate}
                                                onChange={(e) => {
                                                    setMortgageRate(e.target.value)
                                                }}
                                            />
                                        </Div>
                                    </Tag>
                                </Div>
                            </Div>
                            <Div className='flex items-center' >
                                <Div className='text-sm flex-1' >Property Tax</Div>
                                <Div>
                                    <Tag className='border-[2px] border-gray-300 w-[8rem] relative flex' >
                                        <AttachMoney sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />
                                        <Div className='flex space-x-2 items-center  text-xs' >
                                            <InputBase sx={{ height: '1.5rem', pl: '4px', mr: 0.4, width: '4rem' }}
                                                value={property_tax.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                onChange={(e) => {

                                                    let val = e.target.value //get value from input
                                                    let m = val.replace(/,/g, '') //removing any comma present in the string
                                                    let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                                    e.target.value = result //set input value to the seperate format
                                                    setPropertyTax(m)
                                                }}
                                            />
                                            /Yr</Div>
                                    </Tag>
                                </Div>
                            </Div>
                            <Div className='flex items-center' >
                                <Div className='text-sm flex-1' >HOA</Div>
                                <Div>
                                    <Tag className='border-[2px] border-gray-300 w-[8rem] relative flex justify-between' >
                                        <AttachMoney sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />

                                        <Div className='flex space-x-2 items-center  text-xs' >
                                            <InputBase sx={{ height: '1.5rem', pl: '4px', mr: 0.4, width: '4rem' }}
                                                value={hoa.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                onChange={(e) => {

                                                    let val = e.target.value //get value from input
                                                    let m = val.replace(/,/g, '') //removing any comma present in the string
                                                    let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                                    e.target.value = result //set input value to the seperate format
                                                    setHoa(m)
                                                }}
                                            />
                                            /Yr</Div>
                                    </Tag>
                                </Div>
                            </Div>
                            <Div className='flex items-center' >
                                <Div className='text-sm flex-1' >Insurance</Div>
                                <Div>
                                    <Tag className='border-[2px] border-gray-300 w-[8rem] relative flex' >
                                        <AttachMoney sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />

                                        <Div className='flex space-x-2 items-center  text-xs' >
                                            <InputBase sx={{ height: '1.5rem', pl: '4px', mr: 0.4, width: '4rem' }}
                                                value={insurance.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                onChange={(e) => {

                                                    let val = e.target.value //get value from input
                                                    let m = val.replace(/,/g, '') //removing any comma present in the string
                                                    let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                                    e.target.value = result //set input value to the seperate format
                                                    setInsurance(m)
                                                }}
                                            />
                                            /Yr</Div>
                                    </Tag>
                                </Div>
                            </Div>
                            <Div className='flex items-center' >
                                <Div className='text-sm flex-1' >Utilities</Div>
                                <Div>
                                    <Tag className='border-[2px] border-gray-300 w-[8rem] relative flex' >
                                        <AttachMoney sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />
                                        <Div className='flex space-x-2 items-center  text-xs' >
                                            <InputBase sx={{ height: '1.5rem', pl: '4px', mr: 0.4, width: '4rem' }}
                                                value={utilities.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                onChange={(e) => {

                                                    let val = e.target.value //get value from input
                                                    let m = val.replace(/,/g, '') //removing any comma present in the string
                                                    let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                                    e.target.value = result //set input value to the seperate format
                                                    setUtilities(m)
                                                }}
                                            />
                                            /Mo</Div>
                                    </Tag>
                                </Div>
                            </Div>
                            <Div className='flex items-center' >
                                <Div className='text-sm flex-1' >Cleaners</Div>
                                <Div>
                                    <Tag className='border-[2px] border-gray-300 w-[8rem] relative flex' >
                                        <AttachMoney sx={{ color: '#1ebf99', position: 'relative', right: 1, top: 0 }} />
                                        <Div className='flex space-x-2 items-center text-xs' >
                                            <InputBase sx={{ height: '1.5rem', pl: '4px', mr: 0.4, width: '4rem' }}
                                                value={cleaners.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                onChange={(e) => {

                                                    let val = e.target.value //get value from input
                                                    let m = val.replace(/,/g, '') //removing any comma present in the string
                                                    let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                                    e.target.value = result //set input value to the seperate format
                                                    setCleaners(m)
                                                }}
                                            />
                                            /Mo</Div>
                                    </Tag>
                                </Div>
                            </Div>
                        </Div>

                        <Div>

                            <Divider sx={{ borderBottom: '1px solid black', opacity: '1', my: 3 }} />

                            <Div className='space-y-3' >
                                <Div className='font-semibold text-md' >
                                    Total Monthly Expenses = $<Currency symbol='' pattern='##,###!' quantity={monthly_expenses} />
                                </Div>
                                <Div className='font-semibold text-md' >
                                    Mortgage Payment (P&I) = $<Currency symbol='' pattern='##,###!' quantity={mortgage_payment} />
                                </Div>
                            </Div>

                            <Divider sx={{ borderBottom: '1px solid black', opacity: '1', my: 3 }} />
                        </Div>

                    </Div>
                    <Div className='w-full lg:w-1/2'>
                        <Div className=' mt-16' >
                            <Div className='lg:flex justify-between'>
                                <Div className=' hidden lg:block lg:w-1/5'></Div>
                                <Div className=' w-full lg:w-4/5  space-y-3 mr-auto' >
                                    <Div className='flex items-center ' >
                                        <Div className='text-sm  flex-1' >Gross Annual Revenue</Div>
                                        <Div>
                                            <Tag className='border-[2px] border-gray-300 w-[7rem] relative' >
                                                <AttachMoney sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />
                                                <Div>
                                                    <InputBase sx={{ height: '1.5rem', pl: '4px' }}
                                                        value={gross_annual_revenue.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        onChange={(e) => {

                                                            let val = e.target.value //get value from input
                                                            let m = val.replace(/,/g, '') //removing any comma present in the string
                                                            let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                                            e.target.value = result //set input value to the seperate format
                                                            setGrossAnnualRevenue(m)
                                                        }}
                                                    />
                                                </Div>
                                            </Tag>
                                        </Div>
                                    </Div>
                                    <Div className='flex items-center' >
                                        <Div className='text-sm flex-1' >Management Fee</Div>
                                        <Div>
                                            <Tag className='border-[2px] border-gray-300 w-[7rem] relative flex' >
                                                <Percent sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />
                                                <Div>
                                                    <InputBase type='number' sx={{ height: '1.5rem', pl: '4px' }}
                                                        value={management_fee}
                                                        onChange={(e) => {
                                                            setManagementFee(e.target.value)
                                                        }}
                                                    />
                                                </Div>
                                            </Tag>
                                        </Div>
                                    </Div>
                                    <Div className='flex items-center' >
                                        <Div className='text-sm flex-1' >STR Platform Fee</Div>
                                        <Div>
                                            <Tag className='border-[2px] border-gray-300 w-[7rem] relative' >
                                                <Percent sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />
                                                <Div>
                                                    <InputBase type='number' sx={{ height: '1.5rem', pl: '4px' }}
                                                        value={str_platform_fee}
                                                        onChange={(e) => {
                                                            setStrPlatformFee(e.target.value)
                                                        }}
                                                    />
                                                </Div>
                                            </Tag>
                                        </Div>
                                    </Div>
                                    {/* <Div className='flex items-center' >
                                        <Div className='text-sm flex-1' >Tax Reserve</Div>
                                        <Div>
                                            <Tag className='border-[2px] border-gray-300 w-[7rem] relative' >
                                                <Percent sx={{ color: '#1ebf99', position: 'relative', right: 5, top: 0 }} />
                                                <Div>
                                                    <InputBase type='number' sx={{ height: '1.5rem', pl: '4px' }}
                                                        value={tax_reserve}
                                                        onChange={(e) => {
                                                            setTaxReserve(e.target.value)
                                                        }}
                                                    />
                                                </Div>
                                            </Tag>
                                        </Div>
                                    </Div> */}
                                    <Div>

                                        <Divider sx={{ borderBottom: '1px solid black', opacity: '1', my: 3 }} />

                                        <Div className='space-y-4' >
                                            <Div className='font-semibold text-md flex items-center justify-between' >
                                                <Div>
                                                    Net Revenue
                                                </Div>
                                                <Div>$<Currency symbol='' pattern='##,###!' quantity={net_revenue} /></Div>
                                            </Div>
                                            <Div className='font-semibold text-md flex items-center justify-between' >
                                                <Div>
                                                    Mortgage Payments
                                                </Div>
                                                <Div>($<Currency symbol='' pattern='##,###!' quantity={mortgage_payments} />)</Div>
                                            </Div>
                                            <Div className='font-semibold text-md flex items-center justify-between' >
                                                <Div>
                                                    Operating Expenses
                                                </Div>
                                                <Div>($<Currency symbol='' pattern='##,###!' quantity={operating_expenses} />)</Div>
                                            </Div>
                                            <Div className='font-semibold text-md flex items-center justify-between' >
                                                <Div>
                                                    Net Cashflow
                                                </Div>
                                                <Div>
                                                    {net_cash_flow < 0 ? (<>
                                                        ($<Currency symbol='' pattern='##,###!' quantity={net_cash_flow} />)
                                                    </>) : (<>
                                                        $<Currency symbol='' pattern='##,###!' quantity={net_cash_flow} />
                                                    </>)}
                                                </Div>
                                            </Div>
                                        </Div>

                                    </Div>


                                </Div>
                            </Div>


                            <Div className='flex items-center justify-between lg:space-x-4 space-x-1 mt-16' >
                                <Div className='w-1/3' >
                                    <Div className='lg:text-sm text-[0.8rem] text-center font-bold flex justify-around' >
                                        <Div className='lg:relative lg:left-6 md:relative md:left-6' >
                                            Gross Yield

                                        </Div>
                                        <Tooltip title='The percentage of Gross Revenue to Purchase Price' placement='top-end' >
                                            <HelpOutline sx={{ color: '#1ebf99', ml: { xs: '0rem', lg: '1rem' }, fontSize: { lg: '1.6rem', xs: '1.2rem' } }} />
                                        </Tooltip>
                                    </Div>
                                    <Div className='bg-[#d9d9d9] rounded-md text-dark font-semibold  text-center py-2 overflow-x-auto ' >
                                        {((gross_annual_revenue / purchase_price) * 100).toFixed(2)}%
                                    </Div>
                                </Div>
                                <Div className='w-1/3' >
                                    <Div className='lg:text-sm text-[0.8rem]  text-center font-bold flex justify-around' >
                                        <Div className='lg:relative lg:left-6 md:relative md:left-6' >CoC Return</Div>
                                        <Tooltip title='Cash on Cash Return. The percentage of Net Cashflow to Total Upfront Investment' placement='top-end' >

                                            <HelpOutline sx={{ color: '#1ebf99', ml: { xs: '0rem', lg: '1rem' }, fontSize: { lg: '1.6rem', xs: '1.2rem' } }} />
                                        </Tooltip>
                                    </Div>
                                    <Div className='bg-[#d9d9d9] rounded-md text-dark font-semibold  text-center py-2  overflow-x-auto' >
                                        {((net_cash_flow / total_upfront_investment) * 100).toFixed(1)}%
                                    </Div>
                                </Div>
                                <Div className='w-1/3 ' >
                                    <Div className='lg:text-sm text-[0.8rem]  text-center font-bold flex justify-around' >
                                        <Div className='lg:relative lg:left-6 md:relative md:left-6' >
                                            Cap Rate

                                        </Div>
                                        <Tooltip title='The percentage of Net Cashflow to Purchase Price' placement='top-end' >
                                            <HelpOutline sx={{ color: '#1ebf99', ml: { xs: '0rem', lg: '1rem' }, fontSize: { lg: '1.6rem', xs: '1.2rem' } }} />

                                        </Tooltip>
                                    </Div>
                                    <Div className='bg-[#d9d9d9] rounded-md text-dark font-semibold  text-center py-2 overflow-x-auto ' >
                                        {((net_cash_flow / purchase_price) * 100).toFixed(1)}%
                                    </Div>
                                </Div>
                            </Div>
 
                        </Div>
                    </Div>
                </Div>

            </Div>


            <Div className='text-sm italic' >
                BnB buyer provides this content for informational purposes only. Buyers should perform their own due diligence. BnB Buyer has not validated any of the information on 
                this page.
            </Div>





        </>
    )
}

export default ProFormaIncomeStatement