
const NavLinks = [
    {
        name: 'View Listings',
        route: '/search-houses'
    }, {
        name: 'List My STR',
        route: '/sell-my-property'
    },
]




export default NavLinks