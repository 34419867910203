import { CheckCircleOutline } from '@mui/icons-material'
import { Button } from '@mui/material'
import { Div } from 'atomize'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userLocationState } from '../recoilstate'
import Snack from '../reusables/Snack'



const RegistrationStep4 = ({ step, setStep }) => {
    const navigate = useNavigate('')
    const { msg } = useParams()
    //for snack
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState('')
    const [msgForSnack, setMsg] = useState('')
    const [userLocation,  setUserLocation] = useRecoilState(userLocationState)
    
    useEffect(() => {
        if (msg === 'success') {
            setOpen(true)
            setColor('green')
            setMsg('Subscription Successful, redirecting back to previous screen')
        } 
        window.scrollTo({top:0, behavior:'smooth'})

        setTimeout(() => {
            let location = localStorage.getItem('user_location')
            if(location){
                window.location.href = 'https://app.bnbbuyer.com/'+location
            }else{
                window.location.href = 'https://app.bnbbuyer.com/'
            }
            
           console.log(localStorage.getItem('user_location'))
        }, 3000);
    }, [])
    return (
        <Div className='h-[80vh] space-y-6 ' >
            <Snack open={open} setOpen={setOpen} msg={msgForSnack} color={color} />

           {/*  <Div className='font-extrabold text-xl text-center hidden lg:block ' >Sign Up</Div>
            <Div>
                <img
                    alt='steps'
                    src='https://github.com/JusticeFrancis/airbnb_images/blob/main/s4.png?raw=true'
                    variant='square'
                />
            </Div> */}
            <Div className='font-extrabold text-xl   lg:hidden ' >Signup</Div>

            <Div className='text-center text-2xl font-bold flex justify-center items-center ' >
                Congratulations
                <img
                    alt='emoji'
                    src='https://github.com/JusticeFrancis/airbnb_images/blob/main/Emoji.png?raw=true'
                    variant='square'
                    className='w-[2rem] h-[2rem] ml-3'
                />
            </Div>
            <Div className='text-center' >
                <CheckCircleOutline
                    sx={{ fontSize: '9rem', color: '#1ebf99' }}
                />
            </Div>

            <Div className='text-sm text-gray-500 text-center' >
                You Just Finished All The Steps To Sign Up To Our App!!
            </Div>



            <Div className='flex justify-center relative top-10 lg:top-20'>


                <Button
                    className='shadow-md'
                    sx={{ textTransform: 'none', textSize: '0.8rem', width: { lg: '15rem', xs: '11rem' }, float: 'center', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                    onClick={() =>{ 
                    let location = localStorage.getItem('user_location')
                    if(location){
                        window.location.href= 'https://app.bnbbuyer.com/'+location
                    }
                    else{
                         window.location.href= 'https://app.bnbbuyer.com/'
                    }}
                    }
                >
                    Back To Listing
                </Button>

            </Div>
        </Div>
    )
}

export default RegistrationStep4