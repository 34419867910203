import { Drawer } from '@mui/material'
import { Div } from 'atomize'
import React, { useState } from 'react'
import Filter from '../components/Filter'

const MobileFilterSideDrawer = ({ openMobileFilterSideDrawer, setOpenMobileFilterSideDrawer,price,setPrice,revenue,setRevenue,roi, setRoi, filterPrice, setFilterPrice, maxPrice, minPrice ,properties, setProperties
    , minRevenue, maxRevenue, setFilterRevenue, minRoi, maxRoi,areas,setAreas,allAreas, setLoader,setAllAreas , setMyFilter ,loader,applyFilter ,setApplyFilter, setFilterMy, filterMy ,defaultSelectedAreas, setDefaultSelectedAreas,setItemOffset} ) => {
    
    return (
        <Drawer open={openMobileFilterSideDrawer} onClose={() => setOpenMobileFilterSideDrawer(false)} dir='ltr'  >
            <Div className=' relative h-full px-4 pt-2 w-[20rem] '  >
                <Filter revenue={revenue} setRevenue={setRevenue} roi={roi} setRoi={setRoi} price={price} setPrice={setPrice} filterPrice={filterPrice} setFilterPrice={setFilterPrice} maxPrice={maxPrice} minPrice={minPrice} 
                minRevenue={minRevenue} maxRevenue={maxRevenue}
                properties={properties} setProperties={setProperties}
                setFilterRevenue={setFilterRevenue}
                minRoi={minRoi} maxRoi={maxRoi}
                areas={areas} setAreas={setAreas}
                allAreas={allAreas} setLoader={setLoader}
                setAllAreas={setAllAreas} setMyFilter={setMyFilter} loader={loader}
                applyFilter={applyFilter} setApplyFilter={setApplyFilter} setFilterMy={setFilterMy} filterMy={filterMy}
                openMobileFilterSideDrawer={openMobileFilterSideDrawer} setOpenMobileFilterSideDrawer={setOpenMobileFilterSideDrawer}
                defaultSelectedAreas={defaultSelectedAreas} setDefaultSelectedAreas={setDefaultSelectedAreas}
                setItemOffset={setItemOffset}
                />
            </Div>
        </Drawer>
    )
}

export default MobileFilterSideDrawer