import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const RegisterBtn = () => {
  const navigate = useNavigate()
  return (
    <Button
            sx={{ border: '2px solid #1ebf99', borderRadius: '0.4rem', 
            textTransform: 'none', color: 'white', px: '1.3rem', py: '0.1rem', bgcolor: '#1ebf99', ':hover': { color: 'black' }, width:{xs:'80%',lg:'100%'} }}
            onClick={()=>navigate('/register')}
    >Sign Up</Button>
  )
}

export default RegisterBtn