import { Button } from '@mui/material'
import { Div } from 'atomize'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFoundScreen = () => {
    const navigate = useNavigate()
    return (
        <>
           <Div className='lg:w-[30vw] lg:space-y-5  relative top-36 lg:top-20 h-[90vh]' >
            <Div className='space-y-5' >

                <Div className='text-5xl text-center font-extrabold text-[#1ebf99]' >404</Div>

                <Div className='font-semibold text-center' >
                    Hmm... We Can't Seem To Find The Page You Were Looking For
                </Div>

                <Div className='flex justify-center' >
                    <Button
                        className='shadow-md'
                        sx={{ textTransform: 'none', textSize: '0.8rem', width: '15rem', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                        onClick={()=> navigate('/search-houses')}
                    >
                        View Listings
                    </Button>
                </Div>

            </Div>


           </Div>
        </>
    )
}

export default NotFoundScreen