import { Box, Button, Slider, InputBase } from '@mui/material'
import { Div, Label } from 'atomize'
import React, { useEffect, useState } from 'react'
import Currency from 'react-currency-formatter'
import { areasState, propertiesState } from '../../recoilstate'
import AutoCompleteSearchForR2 from '../../reusables/AutoCompleteSearchForR2'
import { useRecoilState } from 'recoil'
import axios from 'axios'
import Snack from '../../reusables/Snack'
import AreaSelect from '../../select-inputs/PreferencesAreas'

const PrefrencesScreen = () => {
    // eslint-disable-next-line
    const [properties, setProperties] = useRecoilState(propertiesState)
    // eslint-disable-next-line
    const [value, setValue] = useState([20, 37])
    const [price, setPrice] = useState([])
    const [revenue, setRevenue] = useState(0)
    const [roi, setRoi] = useState(0)

    const [allAreas, setAllAreas] = useRecoilState(areasState)
    const [areas, setAreas] = useState([])
    const [selectedZips, setSelectedZips] = useState([])


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })

    }, [])




    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(0)
    const [maxRevenue, setMaxRevenue] = useState(0)
    const [minRevenue, setMinRevenue] = useState(0)
    const [maxRoi, setMaxRoi] = useState(0)
    const [minRoi, setMinRoi] = useState(0)


    const handleChangePrice = (event, newValue) => {
        setPrice(newValue)
    }
    const handleChangeRevenue = (event, newValue) => {
        setRevenue(newValue)
    }

    const handleChangeRoi = (event, newValue) => {
        setRoi(newValue)
    }

    //for snack
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState('')
    const [msg, setMsg] = useState('')
    const [isloading, setLoader] = useState(false)




   /*  useEffect(() => {
        //set price states
        setMaxPrice(Math.max(...properties.map(property => property.price)))
        setMinPrice(Math.min(...properties.map(property => property.price)))
        setPrice([Math.min(...properties.map(property => property.price)), Math.max(...properties.map(property => property.price))])

        //set revenue states
        setMaxRevenue(Math.max(...properties.map(property => property.annual_revenue)))
        setMinRevenue(Math.min(...properties.map(property => property.annual_revenue)))
        setRevenue(Math.min(...properties.map(property => property.annual_revenue)))


        //set roi states
        setMaxRoi(Math.max(...properties.map(property => property.roi)))
        setMinRoi(Math.min(...properties.map(property => property.roi)))
        setRoi(Math.min(...properties.map(property => property.roi)))




    }, [properties]) */


    useEffect(() => {
        setAreas(allAreas)
    }, [allAreas])

    const [selectedAreas, setSelectedAreas] = useState([])
    const savePreferences = async () => {
        console.log(areas)
       

        let email = localStorage.getItem('user')
        console.log(email)
        let nroi = roi /100

        const prefrences = await axios.post(process.env.REACT_APP_BACKEND_URL + 'register2', {
            email, roi: nroi, revenue, price, zips:selectedZips, areas : selectedAreas
        }).then((res) => {
            console.log(res.data)
            if (res.data.status === 'success') {

                setOpen(true)
                setColor('green')
                setMsg(`You've got great taste! We'll get to work on finding properties you'll love..`)
                return
            } else {
                setOpen(true)
                setColor('red')
                setMsg(res.data.msg)
                setLoader(false)
                return
            }
        })
            .catch((err) => {
                setOpen(true)
                setColor('red')
                setMsg(`Error4 ${err} occured `)
                setLoader(false)
            })


    }



    const setMyFilter = async () => {
        setLoader(true)
        const email = localStorage.getItem('user')
        console.log(email)
        await axios.post(process.env.REACT_APP_BACKEND_URL + `stripe/retrieve-customer`, { email })
            .then((res) => {
                if (res.data.status === 'success') {
                    console.log(res.data.user)
                    setPrice(res.data.user.price)
                    setRevenue(res.data.user.revenue)
                    setRoi(res.data.user.roi * 100)
                    setLoader(false)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {

        setMyFilter()

    }, [])
    return (
        <>
            <Snack open={open} setOpen={setOpen} msg={msg} color={color} />
            <Div className='lg:w-4/5' >

                <Div className='space-y-2 px-2'>
                <Div className='space-y-2 ' >
                    <Label style={{ fontSize: '1.4rem' }} className=' font-semibold' >Price</Label>

                    <Box sx={{ width: '100%' }} >
                        <Slider valueLabelDisplay='auto' min={0} max={6999000} step={100000} onChange={handleChangePrice} value={price} sx={{ color: '#1ebf99' }} />
                        <Div className='flex items-center justify-between ' >
                            <Div className='text-gray-500 lg:flex items-center lg:flex-1 '> Min: <Div className='text-black font-semibold' > $

                                <InputBase type='text'
                                 sx={{ border: '1px solid #1ebf99', borderRadius: '4px', width: { xs: 'unset', lg: 'unset' }, pl: '0.3rem', ml: '0.3rem' }} 
                                 value={Number(price[0]).toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                                 onChange={(e) => {


                                    let val = e.target.value //get value from input
                                    let m = val.replace(/,/g, '') //removing any comma present in the string
                                    let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                    e.target.value = result //set input value to the seperate format
                                    handleChangePrice(e, [Number(m), Number(price[1])])
                                }

                                } />
                            </Div> </Div>
                            <Div className='text-gray-500 lg:flex items-center  '> Max: <Div className='text-black font-semibold' > $
                                <InputBase type='text' 
                                sx={{ border: '1px solid #1ebf99', borderRadius: '4px', width: { xs: 'unset', lg: 'unset' }, pl: '0.3rem', ml: '0.3rem' }} 
                                value={Number(price[1]).toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    onChange={(e) => {
                                        let val = e.target.value //get value from input
                                        let m = val.replace(/,/g, '') //removing any comma present in the string
                                        let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                        e.target.value = result //set input value to the seperate format
                                        handleChangePrice(e, [Number(price[0]), Number(m)])
                                    }} />
                            </Div> </Div>

                        </Div>
                    </Box>

                </Div>
                <Div className='space-y-2' >
                    <Label style={{ fontSize: '1.4rem' }} className=' font-semibold' >Revenue</Label>

                    <Box sx={{ width: '100%' }} >
                        <Slider valueLabelDisplay='auto' min={6667} max={500000} step={100} value={revenue} onChange={handleChangeRevenue} sx={{ color: '#1ebf99' }} />
                        <Div className='flex items-center justify-between' >
                            <Div className='text-gray-500 '></Div>
                            <Div className='text-gray-500 lg:flex items-center '>Minimum Revenue: <Div className='text-black font-semibold' > $
                                <InputBase type='text'
                                 sx={{ border: '1px solid #1ebf99', borderRadius: '4px', width: { xs: 'unset', lg: 'unset' }, pl: '0.3rem', ml: '0.3rem' }} 
                                 value={Number(revenue).toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                                 onChange={(e) => {

                                    let val = e.target.value //get value from input
                                    let m = val.replace(/,/g, '') //removing any comma present in the string
                                    let result = m.toLocaleString("en-us").toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")//add comma as a thousandth seperator
                                    e.target.value = result //set input value to the seperate format
                                    handleChangeRevenue(e, Number(m))
                                }} />
                            </Div> </Div>

                        </Div>
                    </Box>

                </Div>
                <Div className='space-y-2 mb-4' >
                    <Label style={{ fontSize: '1.4rem' }} className=' font-semibold' >ROI</Label>

                    <Box sx={{ width: '100%' }} >
                        <Slider valueLabelDisplay='auto' min={0} max={34} value={roi} step={2} onChange={handleChangeRoi} sx={{ color: '#1ebf99' }} />
                        <Div className='flex items-center justify-between' >
                            <Div className='text-gray-500 '>  </Div>
                            <Div className='flex items-center justify-between' >
                            <Div className='text-gray-500 '>  </Div>
                            <Div className='text-gray-500 lg:flex items-center '>Minimum ROI: <Div className='text-black font-semibold ml-2' >
                                <InputBase 
                                
                                sx={{ border: '1px solid #1ebf99', borderRadius: '4px', width: { xs: 'unset', lg: '6rem' }, pl: '0.3rem', mr: '0.3rem' }} 
                                type='number'  value={Number(roi).toFixed(0)}
                                inputProps={{ sx:{ color:'black' ,'&::placeholder':{textOverflow:'ellipsis !important', color:'black',opacity:1}} }}
                                onChange={(e) => handleChangeRoi(e, Number(e.target.value))} />
                                % </Div> </Div>
                        </Div>
                        </Div>
                    </Box>

                </Div>


                </Div>
                <Div className='mt-4' >
                    <Div className='space-y-2 '>
                        {/* <AutoCompleteSearchForR2 areas={allAreas} setAreas={setAreas} /> */}
                      {/*   <AreaSelect selectedZips={selectedZips} setSelectedZips={setSelectedZips} /> */}
                      <AreaSelect selectedZips={selectedZips} setSelectedZips={setSelectedZips} selectedAreas={selectedAreas} setSelectedAreas={setSelectedAreas} />

                    </Div>

                </Div>
                <Div className='mt-4'  >

                    <Button
                        disabled={areas.length < 1 || isloading ? true : false}
                        className='shadow-md'
                        sx={{ textTransform: 'none', textSize: '0.8rem', width: { lg: '15rem', xs: '8rem' }, float: 'right', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                        onClick={savePreferences}
                    >
                        Apply changes
                    </Button>
                </Div>
            </Div>
        </>
    )
}

export default PrefrencesScreen