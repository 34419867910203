import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Layout from './Layout';
import { Helmet } from 'react-helmet';
function App() {
  
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Layout />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
