import { atom, selector } from "recoil";





export const userState = atom({
    key: "user",
    default: {}
})


export const userEmailState = atom({
    key: "userEmail",
    default: localStorage.getItem('user')
})


export const propertiesState = atom({
    key: "properties",
    default: []
})



export const areasState = atom({
    key: "areas",
    default: []
})


export const userLocationState = atom({
    key: "userLocation",
    default: null
})


export const defaultSelectedAreasState = atom({
    key:'defaultSelectedAreas',
    default : []
})

export const selectedZipsState = atom({
    key:'selectedZips',
    default: []
})