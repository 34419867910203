import {
  Button,
  Checkbox,
  Divider,
  InputBase,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Div, Label, Anchor } from "atomize";
import { AttachMoney } from "@mui/icons-material";
import Snack from "../reusables/Snack";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const SellPropertyScreen = () => {
  const [revenueNumber, setRevenueNumber] = useState("actuals");
  const [price, setPrice] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [revenue_description, setRevenueDescription] = useState("");
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(0);
  const [hoa_type, setHoaType] = useState("per year");
  const [hoa, setHoa] = useState(0);
  const [bedrooms, setBedrooms] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [sqft, setSqft] = useState(0);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [color, setColor] = useState("");
  const [step, setStep] = useState(0);
  const [agree, setAgree] = useState(true);

  const navigate = useNavigate();

  const hoatypes = [
    {
      name: "/month",
      value: "per month",
    },
    {
      name: "/qtr",
      value: "per quater",
    },
    {
      name: "/yr",
      value: "per year",
    },
  ];

  const handleChangeHoaType = (event) => {
    setHoaType(event.target.value);
  };

  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  const sellProperty = async () => {
    if (
      price === 0 ||
      revenue === 0 ||
      revenue_description === "" ||
      email === "" ||
      hoa <= 0
    ) {
      setOpen(true);
      setColor("#bb2124");
      setMsg("Fill In All Required fields (*)");
      return;
    }

    if (link1.length > 0 && !validURL(link1)) {
      setOpen(true);
      setColor("#bb2124");
      setMsg("link to Airbnb/ VBRO / Direct Booking  is not valid");
      return;
    }
    if (link2.length > 0 && !validURL(link2)) {
      setOpen(true);
      setColor("#bb2124");
      setMsg("link to Zillow/ Realtor / MLS  is not valid");
      return;
    }
    if (link1.length < 1 && link2.length < 1) {
      setOpen(true);
      setColor("#bb2124");
      setMsg("both links cannot be empty");
      return;
    }

    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "sell-my-property", {
        price,
        revenue,
        link1,
        link2,
        email,
        phone,
        revenueNumber,
        revenue_description,
        hoa,
        hoa_type,
        bedrooms,
        bathrooms,
        sqft,
      })
      .then((res) => {
        if (res.data.status === "success") {
          setOpen(true);
          setColor("green");
          setMsg("successfull ..");
          setTimeout(() => {
            setStep(1);
          }, 2000);
          return;
        } else {
          setOpen(true);
          setColor("red");
          setMsg(res.data.msg);
          console.log(res.data);
          return;
        }
      })

      .catch((err) => {
        setOpen(true);
        setColor("red");
        setMsg(err + "something went wrong");
        return;
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [step]);

  useEffect(() => {
    localStorage.setItem(
      "user_location",
      String(window.location.pathname).slice(1)
    );
  });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sell My Property</title>
        <meta name="title" content="Sell My Property" />
        <meta
          name="description"
          content="Sell My Property"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.bnbbuyer.com/sell-my-property" />
        <meta
          property="og:title"
          content="Sell My Property"
        />
        <meta
          property="og:description"
          content="Sell My Property"
        />
        <meta
          property="og:image"
          content="https://bnbbuyer.com/wp-content/uploads/2022/12/Twitter_social.png"
        />
      </Helmet>

      {step === 1 && (
        <Div className="h-[100vh] lg:w-[30vw] ">
          <Div className="bg-white shadow-lg p-4 rounded-md mt-[6rem] h-[19rem] lg:h-[13rem]  ">
            <Div className="text-2xl font-[500] text-center ">
              Property Submitted Successfully
            </Div>
            <Div className="text-sm text-center mt-4  ">
              We're reviewing your info and will be in touch if we have any
              questions. If you need us in the meantime, please reach out to
              hello@bnbbuyer.com
            </Div>

            <Div className=" mt-20 lg:mt-[2.3rem]">
              <Button
                className="shadow-md"
                sx={{
                  textTransform: "none",
                  textSize: "0.8rem",
                  float: "left",
                  bgcolor: "#ffffff",
                  color: "black",
                  ":hover": { color: "black" },
                  border: "1px solid #1ebf99",
                }}
                onClick={() => navigate("/")}
              >
                Return to Properties
              </Button>

              <Button
                className="shadow-md"
                sx={{
                  textTransform: "none",
                  textSize: "0.8rem",
                  float: "right",
                  bgcolor: "#1ebf99",
                  color: "white",
                  ":hover": { color: "black" },
                }}
                onClick={() => {
                  window.location.href =
                    "https://app.bnbbuyer.com/sell-my-property";
                }}
              >
                List Another
              </Button>
            </Div>
          </Div>
        </Div>
      )}

      {step === 0 && (
        <>
          <Snack color={color} msg={msg} open={open} setOpen={setOpen} />

          <Div className="lg:w-[40vw] space-y-5  ">
            <Div className="text-xl text-black font-bold">Sell My Property</Div>
            <Divider sx={{ borderBottom: "2px solid gray" }} />
            <Div className="space-y-3">
              <Div className="space-y-2">
                <Label className="font-semibold">List Price*</Label>
                <InputBase
                  startAdornment={
                    <AttachMoney
                      sx={{
                        color: "#1ebf99",
                        mr: { lg: "25rem", md: "18rem", xs: "55vw" },
                      }}
                    />
                  }
                  className="border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1"
                  sx={{ fontSize: "15px" }}
                  placeholder="e.g: 1,000,000"
                  onChange={(e) => {
                    let val = e.target.value; //get value from input
                    let m = val.replace(/,/g, ""); //removing any comma present in the string
                    let result = m
                      .toLocaleString("en-us")
                      .toString()
                      .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ","); //add comma as a thousandth seperator
                    e.target.value = result; //set input value to the seperate format
                    setPrice(m);
                  }}
                />
              </Div>
              <Div className="space-y-2">
                <Label className="font-semibold">Gross Revenue*</Label>
                <InputBase
                  startAdornment={
                    <AttachMoney
                      sx={{
                        color: "#1ebf99",
                        mr: { lg: "25rem", md: "18rem", xs: "55vw" },
                      }}
                    />
                  }
                  className="border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1"
                  sx={{ fontSize: "15px" }}
                  placeholder="e.g: 123,000"
                  onChange={(e) => {
                    let val = e.target.value; //get value from input
                    let m = val.replace(/,/g, ""); //removing any comma present in the string
                    let result = m
                      .toLocaleString("en-us")
                      .toString()
                      .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ","); //add comma as a thousandth seperator
                    e.target.value = result; //set input value to the seperate format
                    setRevenue(m);
                  }}
                />
              </Div>
              <Div className="space-y-2">
                <Label className="font-semibold">Revenue Description*</Label>
                <InputBase
                  className="border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1"
                  sx={{ fontSize: "15px" }}
                  placeholder="e.g: 2021 Full year, 2022 YTD, Monthly Avg"
                  onChange={(e) => {
                    setRevenueDescription(e.target.value);
                  }}
                />
              </Div>

              <Div className="space-y-2">
                <Label className="font-semibold">Hoa Amount*</Label>
                <Div className="flex space-x-4">
                  <Div className="w-4/5">
                    <InputBase
                      startAdornment={
                        <AttachMoney
                          sx={{
                            color: "#1ebf99",
                            mr: { lg: "10rem", md: "18rem", xs: "25vw" },
                          }}
                        />
                      }
                      className="border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200  py-1"
                      sx={{ fontSize: "15px", width: "100%" }}
                      placeholder="e.g: 7,600"
                      onChange={(e) => {
                        let val = e.target.value; //get value from input
                        let m = val.replace(/,/g, ""); //removing any comma present in the string
                        let result = m
                          .toLocaleString("en-us")
                          .toString()
                          .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ","); //add comma as a thousandth seperator
                        e.target.value = result; //set input value to the seperate format
                        setHoa(m);
                      }}
                    />
                  </Div>

                  <Div className="w-1/5">
                    <Select
                      sx={{ fontSize: "0.8rem", width: { xs: "100%" } }}
                      input={
                        <InputBase className="border-[1px] rounded-sm pl-2 bg-white border-gray-200  py-1" />
                      }
                      value={hoa_type}
                      onChange={handleChangeHoaType}
                    >
                      {hoatypes.map((item, index) => (
                        <MenuItem value={item.value} key={index}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Div>
                </Div>
              </Div>

              <Div className="space-y-2">
                <Div className="flex space-x-4">
                  <Div className="w-1/3">
                    <Label className="font-semibold">Bedrooms</Label>
                    <InputBase
                      className="border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1"
                      sx={{ fontSize: "15px" }}
                      placeholder="e.g: 3"
                      type="number"
                      onChange={(e) => {
                        setBedrooms(e.target.value);
                      }}
                    />
                  </Div>
                  <Div className="w-1/3">
                    <Label className="font-semibold">Bathrooms</Label>
                    <InputBase
                      className="border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1"
                      sx={{ fontSize: "15px" }}
                      placeholder="e.g: 3"
                      type="number"
                      onChange={(e) => {
                        setBathrooms(e.target.value);
                      }}
                    />
                  </Div>
                  <Div className="w-1/3">
                    <Label className="font-semibold">SQFT</Label>
                    <InputBase
                      className="border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1"
                      sx={{ fontSize: "15px" }}
                      placeholder="e.g: 1800"
                      type="number"
                      onChange={(e) => {
                        setSqft(e.target.value);
                      }}
                    />
                  </Div>
                </Div>
              </Div>

              <Div className="space-y-2">
                <Label className="font-semibold">
                  Is Your Revenue Number A Projection or Historic Actuals*
                </Label>
                <Div className="flex items-center w-full space-x-3 ">
                  <Div
                    onClick={() => {
                      setRevenueNumber("actuals");
                    }}
                    className={
                      revenueNumber === "actuals"
                        ? "px-2 flex justify-between text-[#1ebf99] border-2 border-[#1ebf99] w-1/2 text-lg rounded-sm"
                        : "px-2 flex justify-between text-black border-[1px] border-gray-600 w-1/2 text-lg cursor-pointer rounded-sm"
                    }
                  >
                    <Div>
                      <Checkbox
                        checked={revenueNumber === "actuals" && true}
                        sx={{
                          color: "#1ebf99",
                          "& .MuiSvgIcon-root": { fontSize: 20 },
                          "&.Mui-checked": { color: "#1ebf99" },
                        }}
                      />
                    </Div>
                    <Div className="mt-1 mr-2"> Actuals</Div>
                    <Div></Div>
                  </Div>

                  <Div
                    onClick={() => {
                      setRevenueNumber("projection");
                    }}
                    className={
                      revenueNumber === "projection"
                        ? "px-2  flex justify-between text-[#1ebf99] border-2 border-[#1ebf99] w-1/2 text-lg rounded-sm"
                        : "px-2  flex justify-between text-black border-[1px] border-gray-600 w-1/2 text-lg cursor-pointer rounded-sm"
                    }
                  >
                    <Div>
                      <Checkbox
                        checked={revenueNumber === "projection" && true}
                        sx={{
                          color: "#1ebf99",
                          "& .MuiSvgIcon-root": { fontSize: 20 },
                          "&.Mui-checked": { color: "#1ebf99" },
                        }}
                      />
                    </Div>
                    <Div className="mt-1 mr-2"> Projection</Div>
                    <Div></Div>
                  </Div>
                </Div>
              </Div>

              <Div className="space-y-2">
                <Label className="font-semibold">
                  Link to AirBnb / VRBO /Direct Booking
                </Label>
                <InputBase
                  className="border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1"
                  sx={{ fontSize: "15px" }}
                  placeholder="e.g: AirBnb link, Direct Booking"
                  onChange={(e) => {
                    setLink1(e.target.value);
                  }}
                />
              </Div>
              <Div className="space-y-2">
                <Label className="font-semibold">
                  Link to Zillow / Realtor /MLS
                </Label>
                <InputBase
                  className="border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1"
                  sx={{ fontSize: "15px" }}
                  placeholder="e.g: Zillow Link, Realtor Link, ect"
                  onChange={(e) => {
                    setLink2(e.target.value);
                  }}
                />
              </Div>
              <Div className="space-y-2">
                <Label className="font-semibold">
                  {" "}
                  Email (Shared With Buyers)*
                </Label>
                <InputBase
                  className="border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1"
                  sx={{ fontSize: "15px" }}
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Div>
              <Div className="space-y-2">
                <Label className="font-semibold"> Phone (Not Shared)</Label>
                <InputBase
                  className="border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1"
                  sx={{ fontSize: "15px" }}
                  placeholder="Phone"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </Div>

              <Div>
                <Div className="flex items-center text-center font-[600] text-sm">
                  <Checkbox
                    checked={agree}
                    onClick={() => setAgree(!agree)}
                    sx={{
                      color: "#1ebf99",
                      "& .MuiSvgIcon-root": { fontSize: 20 },
                      "&.Mui-checked": { color: "#1ebf99" },
                    }}
                  />
                  <Div>
                    {" "}
                    By Listing My Property I Agree To The Bnb Buyer
                    <Anchor
                      textColor="#1ebf99"
                      hoverTextColor="#1ebf99"
                      onClick={() =>
                        (window.location.href = "https://bnbbuyer.com/terms")
                      }
                    >
                      {" "}
                      Terms Of Service{" "}
                    </Anchor>{" "}
                    And
                    <Anchor
                      textColor="#1ebf99"
                      hoverTextColor="#1ebf99"
                      onClick={() =>
                        (window.location.href =
                          "https://bnbbuyer.com/privacy-policy")
                      }
                    >
                      {" "}
                      Privacy Policy{" "}
                    </Anchor>
                  </Div>
                </Div>
              </Div>

              <Div className="relative top-6">
                <Button
                  disabled={!agree}
                  className="shadow-md"
                  sx={{
                    textTransform: "none",
                    textSize: "0.8rem",
                    width: "15rem",
                    float: "right",
                    bgcolor: "#1ebf99",
                    color: "white",
                    ":hover": { color: "black" },
                  }}
                  onClick={sellProperty}
                >
                  Sell Now
                </Button>
              </Div>
            </Div>
          </Div>
        </>
      )}
    </>
  );
};

export default SellPropertyScreen;
