import './App.css';
import Main from './Main';
import Header from './components/Header';
import Footer from './components/Footer';
import { Div } from 'atomize';
import { useEffect,useMemo } from 'react';
import axios from 'axios'
import {useRecoilState} from 'recoil'
import { areasState, propertiesState } from './recoilstate';
function Layout() {
    // eslint-disable-next-line
    const [properties, setProperties] = useRecoilState(propertiesState)
    // eslint-disable-next-line
    const [areas, setAreas] = useRecoilState(areasState) 

    //get all properties and setRecoilState
    async function getAllProperties(){
     const properties = await axios.get(process.env.REACT_APP_BACKEND_URL+'properties')
     .then((res)=>{
         if(res.data.status === 'success'){
             setProperties(res.data.properties)
         }
     })
    }

    //get priority one areas and setRecoilState
    async function getAllAreas(){
        const areas = await axios.get(process.env.REACT_APP_BACKEND_URL+'areas-by-priority')
        .then((res)=>{
            if(res.data.status === 'success'){
             setAreas(res.data.areas)
         }
     })
    }
    useMemo(()=>{
       getAllAreas()
       getAllProperties()
    },[])
    return (
        <>
                <Header />
                <Div className='relative lg:top-[13vh] top-[10vh] px-4 lg:px-0 mb-56 lg:h-fit md:h-fit xl:h-unset 2xl:h-unset lg:portrait:h-[100vh] ' >
                    <Div className='flex items-center justify-center ' >
                        <Main />
                    </Div>
                </Div>
                <Footer />
        </>
    );
}

export default Layout;
