import { Div } from 'atomize'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const   UserNav = ({setOpenMobileUserSideDrawer}) => {
    const navigate = useNavigate()
    const location = window.location
    return (
        <>
            <Div  className= 'space-y-4 lg:space-y-1 mt-5 lg:mt-0 ' >
            <Div
                className={location.pathname === '/user/profile' ?
                    'text-[#1ebf99] text-sm py-2 pl-8 border-l-2 border-l-[#1ebf99] cursor-pointer' :
                    'text-black text-sm font-semibold py-2 pl-8 cursor-pointer'
                }
                onClick={() => {
                    navigate('/user/profile')
                    setOpenMobileUserSideDrawer(false)

                }}
            >
                My Profile
            </Div>
            <Div
                className={location.pathname === '/user/preferences' ?
                    'text-[#1ebf99] text-sm py-2 pl-8 border-l-2 border-l-[#1ebf99]' :
                    'text-black text-sm font-semibold py-2 pl-8 cursor-pointer'}
                onClick={() => {
                    navigate('/user/preferences')
                    setOpenMobileUserSideDrawer(false)
                }}
            >
                My Preferences
            </Div>
            <Div
                className={location.pathname === '/user/billing&subscription' ?
                    'text-[#1ebf99] text-sm py-2 pl-8 border-l-2 border-l-[#1ebf99] cursor-pointer' :
                    'text-black text-sm font-semibold py-2 pl-8 cursor-pointer '}
                onClick={() => {
                    navigate('/user/billing&subscription')
                    setOpenMobileUserSideDrawer(false)
                }}
            >
                Billing & Subscription
            </Div>

            <Div
                className={location.pathname === '/user/email-preference' ?
                    'text-[#1ebf99] text-sm py-2 pl-8 border-l-2 border-l-[#1ebf99] cursor-pointer' :
                    'text-black text-sm font-semibold py-2 pl-8 cursor-pointer '}
                onClick={() => {
                    navigate('/user/email-preference')
                    setOpenMobileUserSideDrawer(false)
                }}
            >
                Email Preferences
            </Div>
            </Div>
        </>
    )
}

export default UserNav