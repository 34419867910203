import { Button, InputBase } from '@mui/material'
import { loadStripe } from '@stripe/stripe-js'
import { useStripe } from '@stripe/react-stripe-js'
import { Div, Label } from 'atomize'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Currency from 'react-currency-formatter'
import Snack from '../reusables/Snack'

const sections = [
    {
        name: 'Links to Properties',
        description: 'Unlock access to view the properties on other websites with more details such as Airbnb, VRBO, Realtor and Zillow.',
        img: 'https://github.com/JusticeFrancis/airbnb_images/blob/main/link.png?raw=true',
        emoji: '🔗'
    },
    {
        name: 'Listing Notification',
        description: 'Get notified whenever a property matching your prefrences is listed.',
        img: 'https://github.com/JusticeFrancis/airbnb_images/blob/main/bell.png?raw=true',
        emoji: '🛎️'
    },
    {
        name: 'Sellers Contact Info',
        description: 'Access direct contact information of the seller and/or listing agent.',
        img: 'https://github.com/JusticeFrancis/airbnb_images/blob/main/mail.png?raw=true',
        emoji: '✉️'
    },
    {
        name: 'Income Calculator',
        description: 'Automatically generate customizable pro-forma income statements based on actual revenue numbers and estimated expenses.',
        img: 'https://github.com/JusticeFrancis/airbnb_images/blob/main/moneywings.png?raw=true',
        emoji: '💸'
    }
]

const RegistrationStep3 = ({ step, setStep }) => {
    const stripe = useStripe()
    const [selectedPlan, setSelectedPlan] = useState('monthly')
    const [email, setEmail] = useState(localStorage.getItem('user'))
    const [isLoading, setIsLoading] = useState(false)

    //for snack
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState('')
    const [msg, setMsg] = useState('')


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })

    }, [])

    const subscribe = async (plan) => {
        setIsLoading(true)
        const subscribenow = await axios.post(process.env.REACT_APP_BACKEND_URL + 'stripe/subscribe', { email, plan })
            .then((res) => {
                if (res.data.status === 'success') {
                    stripe.redirectToCheckout({ sessionId: res.data.session_id })
                    setOpen(true)
                    setColor('green')
                    setMsg('Sending you to Stripe to securely process your payment!')
                } else {
                    setIsLoading(false)
                    setOpen(true)
                    setColor('red')
                    setMsg(res.data.msg + ' Please try again or reach us at hello@bnbbuyer.com')
                }
            })
            .catch((err) => {
                setIsLoading(false)
                setOpen(true)
                setColor('red')
                setMsg('some error occured' + ' Please try again or reach us at hello@bnbbuyer.com')
            })
    }


    //stripe

    return (
        <>
            <Snack open={open} setOpen={setOpen} msg={msg} color={color} />
            
           {/*  
           
           <Div className='font-extrabold text-xl text-center hidden lg:block ' >Sign Up</Div>
           <Div>
                <img
                    alt='steps'
                    src='https://github.com/JusticeFrancis/airbnb_images/blob/main/s3.png?raw=true'
                    variant='square'
                />
            </Div> */}
            <Div className='font-extrabold text-xl   lg:hidden ' >Select Plan</Div>

            <Div className='space-y-20' >
                <Div className='space-y-3'>
                    <Div className='font-bold text-2xl' >Select Your Plan</Div>

                    <Div >
                        <Button
                            disabled={isLoading}
                            className='shadow-md'
                            sx={selectedPlan === 'monthly' ? {
                                textTransform: 'none', fontSize: { xs: '1.2rem', lg: '1.5' }, width: { lg: '15rem', xs: '9rem' }, float: 'left', border: '1px solid #1ebf99', color: 'white',
                                ':hover': { color: 'black' }, fontWeight: 'bolder', bgcolor: '#1ebf99',
                            } : {
                                textTransform: 'none', fontSize: { xs: '1.2rem', lg: '1.5' }, width: { lg: '15rem', xs: '9rem' }, float: 'left', border: '1px solid #1ebf99', color: 'black',
                                ':hover': { color: 'black' }, fontWeight: 'bolder',
                            }}

                            onClick={() => {
                                setSelectedPlan('monthly')
                                subscribe('monthly')
                            }}

                        >
                            <Currency quantity={24} />
                            <span className='text-gray-300 text-sm relative top-1' >/Month</span>
                        </Button>

                        <Button
                            disabled={isLoading}
                            className='shadow-md'
                            sx={selectedPlan === 'yearly' ? {
                                textTransform: 'none', fontSize: { xs: '1.2rem', lg: '1.5' }, width: { lg: '15rem', xs: '9rem' }, float: 'right', border: '1px solid #1ebf99', color: 'white',
                                ':hover': { color: 'black' }, fontWeight: 'bolder', bgcolor: '#1ebf99',
                            } : {
                                textTransform: 'none', fontSize: { xs: '1.2rem', lg: '1.5' }, width: { lg: '15rem', xs: '9rem' }, float: 'right', border: '1px solid #1ebf99', color: 'black',
                                ':hover': { color: 'black' }, fontWeight: 'bolder',
                            }}
                            onClick={() => {
                                setSelectedPlan('yearly')
                                subscribe('yearly')
                            }}

                        >
                            <Currency quantity={159} />
                            <span className='text-gray-300 text-sm relative top-1' >/Year</span>
                        </Button>



                    </Div>
                </Div>


                <Div className='hidden lg:block' >
                    <Div className='text-md font-semibold text-center mt-12 mb-10 ' >What Can You Get With Bnb Buyer Premium ?</Div>
                    <Div className='lg:flex items-center lg:space-x-3 space-y-3 lg:space-y-0' >
                        {sections.map((item, index) => (
                            <Div className='lg:w-1/4 border-2 border-gray-300 p-2 rounded-md space-y-3 h-[14rem] ' key={index} >
                                <Div className='flex justify-center text-2xl' >{item.emoji}</Div>
                                <Div className='font-bold text-center text-sm' >{item.name}</Div>
                                <Div textSize={'caption'} textAlign='center' className=' text-black  text-sm' >
                                    {item.description}
                                </Div>
                            </Div>
                        ))}
                    </Div>
                </Div>

                <Div className=' lg:hidden' >
                    <Div className='text-md font-semibold text-center mt-12 mb-5 ' >What Can You Get With Bnb Buyer Premium ?</Div>
                    <Div className='lg:flex items-center lg:space-x-3 space-y-3 lg:space-y-0' >
                        {sections.map((item, index) => (
                            <Div className='lg:w-1/4  p-2 rounded-md space-y-3 ' key={index} >
                                <Div className='flex items-center justify-center' >
                                    <Div className='flex justify-center text-2xl' >{item.emoji}</Div>
                                    <Div className='font-bold text-center text-sm' >{item.name}</Div>
                                </Div>

                            </Div>
                        ))}
                    </Div>
                </Div>


                <Div className='space-y-3'>

                    <Div className='space-y-5 relative top-7'>
                        <Div className='text-sm text-[#1ebf99] underline cursor-pointer' 
                        
                        onClick={() => {
                            setStep(step + 1)
                        }}
                        >
                            No Thanks, I'll Stay On The Free Plan For Now
                            {/* <Checkbox  sx={{ color: '#1ebf99', '& .MuiSvgIcon-root': { fontSize: 20 }, '&.Mui-checked': { color: '#1ebf99' } }} /> */}
                        </Div>
                       {/*  <Div >
                            <Button
                                disable={isLoading}
                                className='shadow-md'
                                sx={{
                                    textTransform: 'none', textSize: '0.8rem', width: { lg: '15rem', xs: '8rem' }, float: 'left', border: '1px solid #1ebf99', color: 'gray',
                                    ':hover': { color: 'black' }
                                }}
                                onClick={() => {
                                    setStep(step - 1)
                                }}

                            >
                                Previous
                            </Button>

                            <Button
                                disable={isLoading}
                                className='shadow-md'
                                sx={{ textTransform: 'none', textSize: '0.8rem', width: { lg: '15rem', xs: '11rem' }, float: 'right', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                                onClick={() => {
                                    setStep(step + 1)
                                }}
                            >
                                Start 7 Day Free Trial
                            </Button>

                        </Div> */}
                    </Div>
                </Div>




            </Div>
        </>
    )
}

export default RegistrationStep3