import { Check } from "@mui/icons-material";
import { Checkbox, Divider } from "@mui/material";
import { Div } from "atomize";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const EmailPrefrencesScreen = ({user}) => {
  const [emailPreferences, setEmailPreferences] = useState(false);
  const [emailPriceDrop, setEmailPriceDrop] = useState( false);
  const [emailGeneral, setEmailGeneral] = useState( false);


  


  const updateEmailPreferences = async(emailPreferences, emailPriceDrop, emailGeneral) => {
    await axios.post(process.env.REACT_APP_BACKEND_URL+"email-settings/update", {
       email : user.email,
       email_preferences  : emailPreferences,
       email_pricedrop : emailPriceDrop,
       email_general : emailGeneral
    })

    .then((res)=> {
        console.log(res)

    })
    .catch((err)=> {
        console.log(err)
    })
  }

  useEffect(() => {
    setEmailPreferences(user.email_preferences || false)
    setEmailPriceDrop(user.email_pricedrop || false)
    setEmailGeneral(user.email_general || false)
  }, [user])
  

  const navigate = useNavigate();
  return (
    <Div className="lg:w-4/5 pl-6  py-5">
      <div className="flex items-start space-x-2">
        <Checkbox
          checked={emailPreferences}
          onClick={() => {
            if(emailPreferences){
                setEmailPreferences(false)
                updateEmailPreferences(false,emailPriceDrop, emailGeneral)
            }else{
               
                    setEmailPreferences(true)
                    updateEmailPreferences(true,emailPriceDrop, emailGeneral)
                
            }
            
        }}
          sx={{
            color: "rgb(178,190,181)",
            "& .MuiSvgIcon-root": { fontSize: 20 },
            "&.Mui-checked": { color: "gray" },
          }}
          icon={<div className="border-[1px] border-gray-400  w-5 h-5" />}
          checkedIcon={
            <Check sx={{ color: "#1ebf99", border: "1px solid gray" }} />
          }
        />

        <div>
          <div className="font-semibold text-sm ">
            Properties Matching My Preferences
          </div>
          <div className="font-normal text-xs ">
            <div>
              Get notifications whenever a new property matching your preferences
              gets added to the site.
            </div>
            <div>
              You can update your property preferences in the{" "}
              <span
                className="text-[#1ebf99] underline "
                onClick={() => navigate("/user/preferences")}
              >
                my preferences page
              </span>
            </div>
          </div>
        </div>
      </div>
      <Divider sx={{ my: 2 }} />

      <div className="flex items-start space-x-2">
        <Checkbox
          checked={emailPriceDrop}
          onClick={() => {
            if(emailPriceDrop){
                setEmailPriceDrop(false)
                updateEmailPreferences(emailPreferences,false, emailGeneral)
            }else{
               
                    setEmailPriceDrop(true)
                    updateEmailPreferences(emailPreferences,true, emailGeneral)
                
            }
            
           
        }}
          sx={{
            color: "rgb(178,190,181)",
            "& .MuiSvgIcon-root": { fontSize: 20 },
            "&.Mui-checked": { color: "gray" },
          }}
          icon={<div className="border-[1px] border-gray-400  w-5 h-5" />}
          checkedIcon={
            <Check sx={{ color: "#1ebf99", border: "1px solid gray" }} />
          }
        />

        <div>
          <div className="font-semibold text-sm ">
           Price Drop Notifications
          </div>
          <div className="font-normal text-xs ">
            <div>
              Get notifications when a property that you have shown interest in has a price drop.
            </div>
           
          </div>
        </div>
      </div>
      <Divider sx={{ my: 2 }} />


      <div className="flex items-start space-x-2">
        <Checkbox
          checked={emailGeneral}
          onClick={() => {
            if(emailGeneral){
                setEmailGeneral(false)
                updateEmailPreferences(emailPreferences,emailPriceDrop, false)
            }else{
               
                    setEmailGeneral(true)
                    updateEmailPreferences(emailPreferences,emailPriceDrop, true)
                
            }
        }}
          sx={{
            color: "rgb(178,190,181)",
            "& .MuiSvgIcon-root": { fontSize: 20 },
            "&.Mui-checked": { color: "gray" },
          }}
          icon={<div className="border-[1px] border-gray-400  w-5 h-5" />}
          checkedIcon={
            <Check sx={{ color: "#1ebf99", border: "1px solid gray" }} />
          }
        />  

        <div>
          <div className="font-semibold text-sm ">
            General Newsletter
          </div>
          <div className="font-normal text-xs ">
            <div>
             Recieve our biweekly newsletter that features properties all across the country. This newsletter doesn't
             take individual preferences into account and is a nice way to get an idea on STR markets you may not have 
             been aware of.
            </div>
            
          </div>
        </div>
      </div>
    </Div>
  );
};

export default EmailPrefrencesScreen;
