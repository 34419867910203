import React from "react";
import { Helmet } from "react-helmet";

const HelmetMolecule = ({ property }) => {
  console.log(property.photos[0]);
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{property.title}</title>
      <meta
        name="description"
        key="description"
        content={property.description}
      />
      <meta name="title" key="title" content={property.title} />
      <link
        rel="canonical"
        href={"http://app.bnbbuyer.com/house-detail/" + property.property_id}
      />
      <meta
        property="og:image"
        content={property.photos[0]}
      />
      <meta
        property="og:title"
        content={property.title}
      />

      <meta
        name="twitter:card"
        content={property.description}
      />
      <meta
        name="twitter:title"
        content={property.title}
      />
      <meta
        name="twitter:description"
        content={property.description}
      />
      <meta
        name="twitter:image"
        content={property.photos[0]}
      />
    </Helmet>
  );
};

export default HelmetMolecule;
