import { Avatar, Divider } from '@mui/material'
import { Col, Div } from 'atomize'
import { Home, KingBed, AspectRatio } from '@mui/icons-material'
import Currency from 'react-currency-formatter'
import React from 'react'
import {useNavigate} from 'react-router-dom'

const SimilarHouse = ({ property,orientation }) => {
    const navigate = useNavigate()
    return (
        <>

            {orientation === 'grid' && (
                <Col size={{xs:'12', lg:'4',md:'6'}} style={{ marginBottom: '1rem' }}>
                    <Div className='bg-white shadow-lg rounded-lg  hover:opacity-80 cursor-pointer' onClick={()=>{window.location.pathname = '/house-detail/'+property.property_id}} >

                        <Avatar
                            src={property.photos[0]}
                            sx={{ width: '100%', height: '12rem', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }} variant='square' elevation='5'>BR</Avatar>
                        <Div className='p-2 space-y-2'>
                            <Div className='text-lg text-black font-bold' > {property.title} </Div>
                            <Div className='flex items-center justify-between text-sm font-semibold' >
                                <Div>Price : <span className='text-blue-600' > $<Currency quantity={property.price} decimal={0}  pattern='##,###!' symbol='' /></span></Div>
                                <Div>Income : <span className='text-blue-600' > $<Currency quantity={property.annual_revenue} decimal={0} pattern='##,###!' symbol=''/> </span></Div>
                            </Div>
                            <Divider />
                            <Div className='text-[0.8rem]  text-gray-400 flex items-center mt-2 justify-between'>
                                <Div ><Home sx={{ color: '#dde0e5', fontSize: '1.2rem', position: 'relative', bottom: '2px' }} /> {property.prop_type}</Div>
                                <Div><KingBed sx={{ color: '#dde0e5', fontSize: '1.2rem', position: 'relative', bottom: '2px' }} /> {property.beds} bed rooms</Div>
                                <Div><AspectRatio sx={{ color: '#dde0e5', fontSize: '1.2rem', position: 'relative', bottom: '2px' }} /> {property.sqft} sqft</Div>
                            </Div>

                            <Div className='flex items-center justify-between mt-4 text-blue-700 font-semibold mb-2 ' >
                                <Div></Div>
                                <Div className='cursor-pointer' >
                                    View Listing
                                </Div>
                            </Div>
                        </Div>





                    </Div>
                </Col>
            )}
        </>
    )
}

export default SimilarHouse 