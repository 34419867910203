import { Button } from '@mui/material'
import React,{useState} from 'react'
import Login from '../modals/LoginModal' 

const LoginBtn = ({setLoggedIn, setOpenMobileSideDrawer, redirect}) => {
  const [openLoginModal, setOpenLoginModal] = useState(redirect === 'login' ? true : false) 
  return (
    <>
    
    <Login openLoginModal={openLoginModal} setOpenLoginModal={setOpenLoginModal} />
    <Button
            sx={{ border: '2px solid #1ebf99', borderRadius: '0.4rem', 
            textTransform: 'none', color: 'black', px: '1.3rem', py: '0.1rem', ':hover': { bgcolor: '#1ebf99' }, width:{xs:'80%',lg:''} }}
            onClick={()=>{
              setOpenLoginModal(true)
            }}
          >Login</Button>
    
    </>
  )
}

export default LoginBtn