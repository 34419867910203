import { Home, Menu } from '@mui/icons-material'
import { Avatar } from '@mui/material'
import { Div } from 'atomize'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MobileSideDrawer from '../molecules/MobileSideDrawer'
import MobileUserSideDrawer from '../molecules/MobileUserSideDrawer'
import LoginBtn from '../reusables/LoginBtn'
import NavLinks from '../reusables/NavLinks'
import RegisterBtn from '../reusables/RegisterBtn'
import { useRecoilState } from 'recoil'
import { userEmailState } from '../recoilstate'
import LogoutBtn from '../reusables/LogoutBtn'
import logo from '../assets/favicon2.png'

const Header = () => {
  const naviagate = useNavigate()
  const [loggedIn, setLoggedIn] = useState(false)
  const [emailState, setEmailState] = useRecoilState(userEmailState || '')

  const location = window.location

  const search = useLocation().search;
  const redirect = new URLSearchParams(search).get('redirect') || false;
  console.log(redirect)

  //mobile side drawer
  const [openMobileSideDrawer, setOpenMobileSideDrawer] = useState(redirect === 'login' ? true : false)
  //mobile user side drawer 
  const [openMobileUserSideDrawer, setOpenMobileUserSideDrawer] = useState(false)

 

  return (
    <>
      <Div className='bg-white border-b-2 border-b-gray-200 lg:flex hidden items-center py-4 fixed w-full shadow-sm mx-auto pl-4 z-50'  >


        <Div className=' px-auto flex items-center  ' >
          <Div>
            <Avatar src={logo} variant='circular' sx={{ p:"0.1px", border:'1px solid #1ebf99', bgcolor:'#1ebf99' }} />
            {/* <Home sx={{ color: "white", bgcolor: '#1ebf99', borderRadius: '2rem', fontSize: '2.5rem', p: '0.1rem', mr: '0.4rem' }} /> */}
          </Div>
          <Div className='text-[#1ebf99] font-bold'>
            <span className='relative top-1'  >Bnb </span> <br /> <span  > Buyer</span>
          </Div>
        </Div>

        <Div className='flex items-center space-x-7 text-sm ml-10 ' >
          {NavLinks.map((item, index) => (
            <Div key={index} hoverTextColor='#1ebf99' className={location.pathname === item.route ? 'cursor-pointer text-[#1ebf99] ' : 'cursor-pointer'} onClick={() => naviagate(item.route)} >{item.name}</Div>
          ))}
        </Div>

        <Div className='flex items-center space-x-4 absolute right-3' >

          {emailState && emailState != '' ? (
            <>
                <Div className='space-y-2 lg:space-y-1 mt-5 lg:mt-0 cursor-pointer   ' >
                  <Div
                    className={location.pathname === '/user/profile' ?
                      'text-[#1ebf99] text-sm ' :
                      'text-gray-500 text-sm hover:text-[#1ebf99]'
                    }
                    onClick={() => {
                      naviagate('/user/profile')

                    }}
                  >
                    My Profile
                  </Div>
                </Div>
              <LogoutBtn />
              </>

          ) :
            (

              <>
                <LoginBtn setLoggedIn={setLoggedIn} redirect={redirect} />

                <RegisterBtn />
              </>
            )}
        </Div>



      </Div>

      <Div className='fixed top-0 w-full flex lg:hidden items-center justify-center shadow-sm border-b-2 border-b-gray-200 z-50 bg-white '  >

        <Div className='absolute top-2 left-4' >
          <Menu sx={{ color: "black" }} onClick={() => setOpenMobileSideDrawer(true)} />
          <MobileSideDrawer openMobileSideDrawer={openMobileSideDrawer} setOpenMobileSideDrawer={setOpenMobileSideDrawer} setLoggedIn={setLoggedIn} redirect={redirect} />
        </Div>
        <Div className=' px-auto flex items-center  ' >
          <Div>
          <Avatar src={logo} variant='circular' sx={{ p:"0.1px", border:'1px solid #1ebf99', bgcolor:'#1ebf99' }} />
            {/* <Home sx={{ color: "white", bgcolor: '#1ebf99', borderRadius: '2rem', fontSize: '2.5rem', p: '0.1rem', mr: '0.4rem' }} /> */}
          </Div>
          <Div className='text-[#1ebf99] font-bold'>
            <span className='relative top-1'  >Bnb </span> <br /> <span  > Buyer</span>
          </Div>
        </Div>
        <Div>
          {loggedIn && (<Avatar sx={{ bgcolor: '#1ebf99' }} onClick={() => setOpenMobileUserSideDrawer(true)} style={{ position: 'absolute', top: 4, right: 3 }} >A</Avatar>)}
          <MobileUserSideDrawer openMobileUserSideDrawer={openMobileUserSideDrawer} setOpenMobileUserSideDrawer={setOpenMobileUserSideDrawer} />
        </Div>
      </Div>
    </>
  )
}

export default Header