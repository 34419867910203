import React from 'react'
import GoogleMapReact from 'google-map-react'
import {LocationOn} from '@mui/icons-material'
import { Div } from 'atomize'

const Component = ({text}) => <div className='text-white font-semibold text-center' > <div> <LocationOn sx={{ color:'#1ebf99',fontSize:'3rem' }} /></div>  </div>

const Maps = ({lat ,lng}) => {
    const defaultProps = {
        center: {
            lat,
            lng
        },
        zoom: -100
    }
    return (
        <div style={{width:'100%' }}>

             {/* <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDURVgLoX9jdZeHNGuoAo64srcXunImlew', version: '3.31' }}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                
                 
                
            >
                <Component
                lat = {lat}
                lng = {lng} 
                text = "property"
                />
 
            </GoogleMapReact> */}

           <Div className='lg:hidden'  >
           <img 
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=14&size=400x330&markers=color:0x1ebf99%7C${lat},${lng}&key=AIzaSyDURVgLoX9jdZeHNGuoAo64srcXunImlew`} />
           </Div>

           

           <Div className='lg:block hidden'  >
           <img 
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=14&size=900x215&markers=color:0x1ebf99%7C${lat},${lng}&scale=2&key=AIzaSyDURVgLoX9jdZeHNGuoAo64srcXunImlew`} />
           </Div>

        </div>
    )
}

export default Maps