import { Close } from '@mui/icons-material'
import { Button, InputBase } from '@mui/material'
import { Div, Label, Modal } from 'atomize'
import React, { useEffect, useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
import Currency from 'react-currency-formatter'
import Snack from '../reusables/Snack'
import { track } from '../tracking'

const sections = [
    {
        name: 'Links to Properties',
        description: 'Unlock access to view the properties on other websites with more details such as Airbnb, VRBO, Realtor and Zillow.',
        img: 'https://github.com/JusticeFrancis/airbnb_images/blob/main/link.png?raw=true',
        emoji: '🔗'
    },
    {
        name: 'Listing Notification',
        description: 'Get notified whenever a property matching your prefrences is listed.',
        img: 'https://github.com/JusticeFrancis/airbnb_images/blob/main/bell.png?raw=true',
        emoji: '🛎️'
    },
    {
        name: 'Sellers Contact Info',
        description: 'Access direct contact information of the seller and/or listing agent.',
        img: 'https://github.com/JusticeFrancis/airbnb_images/blob/main/mail.png?raw=true',
        emoji: '✉️'
    },
    {
        name: 'Income Calculator',
        description: 'Automatically generate customizable pro-forma income statements based on actual revenue numbers and estimated expenses.',
        img: 'https://github.com/JusticeFrancis/airbnb_images/blob/main/moneywings.png?raw=true',
        emoji: '💸'
    }
]

const JoinPremiumAccessModal = ({ openJoinPremiumAccessModal , setOpenJoinPremiumAccessModal, property_id,defEmail }) => {
    const [selectedPlan, setSelectedPlan] = useState('monthly')
    const [email, setEmail] = useState(localStorage.getItem('user') || defEmail)
    const [isLoading, setIsLoading] = useState(false)
    const stripe = useStripe()

    //for snack
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState('')
    const [msg, setMsg] = useState('')


    const subscribe = async (plan) => {
        setIsLoading(true)
        const subscribenow = await axios.post(process.env.REACT_APP_BACKEND_URL + 'stripe/subscribe-from-propery-screen', { email, plan, property_id })
            .then((res) => {
                if (res.data.status === 'success') {
                    stripe.redirectToCheckout({ sessionId: res.data.session_id })
                    setOpen(true)
                    setColor('green')
                    setMsg('Sending you to Stripe to securely process your payment!')
                } else {
                    setIsLoading(false)
                    setOpen(true)
                    setColor('red')
                    setMsg(res.data.msg + ' Please try again or reach us at hello@bnbbuyer.com')
                }
            })
            .catch((err) => {
                setIsLoading(false)
                setOpen(true)
                setColor('red')
                setMsg('some error occured' + ' Please try again or reach us at hello@bnbbuyer.com')
            })
    }



       //tracker starts here

       let propertypage = false

       if(window.location.pathname.substring(1,13) === 'house-detail'){
           propertypage = true
       }
       const urlParams = new URLSearchParams(window.location.search)
       const subscriber_id = urlParams.get('subscriberId') || null
   
       const tracking = 'properties'
   
       let ip_address = null
   
       const getIp = async () => {
           const res = await axios.get('https://geolocation-db.com/json/')
           ip_address = res.data.IPv4
   
       }
   
       
       useEffect(() => {
         if( openJoinPremiumAccessModal && propertypage){
           getIp()
         }
   
   
       }, [openJoinPremiumAccessModal === true])
   
   
       const pathname = window.location.pathname
       let pid = pathname.substring(14)

   
       
     
   
       //tracker ends here
    return (
        <>
            <Snack open={open} setOpen={setOpen} msg={msg} color={color} />
            <Modal isOpen={openJoinPremiumAccessModal} onClose={() => setOpenJoinPremiumAccessModal(false)} className='relative' bg='#fafafa' align={{ xs: '', lg: 'center' }} maxW={{ lg: '56rem',md:'52rem', xs: '21rem' }}
                h={{ xs: '170vh', lg: '30rem' }}
            >
                <Close sx={{ fontSize: '1.5rem', cursor: 'pointer', position: 'absolute', top: 4, right: 2 }} onClick={() => setOpenJoinPremiumAccessModal(false)} />

                <Div >
                    <Div className='text-xl font-bold text-center mb-3' >Join <span className='text-[#1ebf99]'>Premium</span> To Access</Div>

                    <Div className='space-y-2  lg:w-[30rem] mx-auto  ' >
                        {/*  <Label className='font-semibold' >Email</Label>
                    <Div className='lg:flex justify-center lg:space-x-5 space-y-3 lg:space-y-0'>
                        <Div className='lg:w-3/5'>
                            <InputBase className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1'
                                sx={{ fontSize: '15px' }}
                                placeholder='xyz@example.com' />
                        </Div>
                        <Div className='lg:w-2/5' >
                            <Button
                                className='shadow-md'
                                sx={{ textTransform: 'none', textSize: '0.8rem', width: '100%', float: 'right', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}


                            >
                                Get Started
                            </Button>
                        </Div>
                    </Div> */}


                        <Div className='flex space-x-2' >
                            <Button
                                disabled={isLoading}
                                className='shadow-md'
                                sx={selectedPlan === 'monthly' ? {
                                    textTransform: 'none', fontSize: { xs: '1.2rem', lg: '1.5' }, width: { lg: '15rem', xs: '9rem' }, float: 'left', border: '1px solid #1ebf99', color: 'white',
                                    ':hover': { color: 'black' }, fontWeight: 'bolder', bgcolor: '#1ebf99',
                                } : {
                                    textTransform: 'none', fontSize: { xs: '1.2rem', lg: '1.5' }, width: { lg: '15rem', xs: '9rem' }, float: 'left', border: '1px solid #1ebf99', color: 'black',
                                    ':hover': { color: 'black' }, fontWeight: 'bolder',
                                }}

                                onClick={() => {
                                    setSelectedPlan('monthly')
                                    subscribe('monthly')
                                    const email = localStorage.getItem('user') || null
                                    track(pid,'upgrade_monthly', email)
                                }}

                            >
                                <Currency quantity={24} />
                                <span className='text-gray-300 text-sm relative top-1' >/Month</span>
                            </Button>

                            <Button
                                disabled={isLoading}
                                className='shadow-md'
                                sx={selectedPlan === 'yearly' ? {
                                    textTransform: 'none', fontSize: { xs: '1.2rem', lg: '1.5' }, width: { lg: '15rem', xs: '9rem' }, float: 'right', border: '1px solid #1ebf99', color: 'white',
                                    ':hover': { color: 'black' }, fontWeight: 'bolder', bgcolor: '#1ebf99',
                                } : {
                                    textTransform: 'none', fontSize: { xs: '1.2rem', lg: '1.5' }, width: { lg: '15rem', xs: '9rem' }, float: 'right', border: '1px solid #1ebf99', color: 'black',
                                    ':hover': { color: 'black' }, fontWeight: 'bolder',
                                }}
                                onClick={() => {
                                    setSelectedPlan('yearly')
                                    subscribe('yearly')
                                    const email = localStorage.getItem('user') || null
                                    track(pid,'upgrade_annual', email)
                                }}

                            >
                                <Currency quantity={159} />
                                <span className='text-gray-300 text-sm relative top-1' >/Year</span>
                            </Button>



                        </Div>

                    </Div>

                    <Div className='text-md font-semibold text-center mt-12 mb-10 ' >What Can You Get With Bnb Buyer Premium ?</Div>
                    <Div className='lg:flex items-center lg:space-x-3 space-y-3 lg:space-y-0' >
                        {sections.map((item, index) => (
                            <Div className='lg:w-1/4 border-2 border-gray-300 p-2 rounded-md space-y-3 h-[12rem] ' key={index} >
                                <Div className='flex justify-center text-2xl' >{item.emoji}</Div>
                                <Div className='font-bold text-center text-sm' >{item.name}</Div>
                                <Div textSize={'caption'} textAlign='center' className=' text-black  text-sm' >
                                    {item.description}
                                </Div>
                            </Div>
                        ))}
                    </Div>

                </Div>
            </Modal>
        </>
    )
}

export default JoinPremiumAccessModal