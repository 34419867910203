import {
  Divider,
  Select,
  MenuItem,
  InputBase,
  Button,
  CircularProgress,
} from "@mui/material";
import { Div, Row } from "atomize";
import {
  ViewList,
  AutoAwesomeMosaic,
  FilterAlt,
  ChevronRight,
  ChevronLeft,
} from "@mui/icons-material";
import React, { useState, useEffect, useMemo } from "react";
import Filter from "../components/Filter";
import House from "../components/House";
import MobileFilterSideDrawer from "../molecules/MobileFilterSideDrawer";
import ReactPaginate from "react-paginate";
import { useRecoilState } from "recoil";
import {
  areasState,
  defaultSelectedAreasState,
  propertiesState,
  userLocationState,
} from "../recoilstate";
import axios from "axios";
import { track } from "../tracking";
import { Helmet } from "react-helmet";

const houses = [
  {
    img: "https://github.com/JusticeFrancis/airbnb_images/blob/main/h1.png?raw=true",
  },
  {
    img: "https://github.com/JusticeFrancis/airbnb_images/blob/main/h2.png?raw=true",
  },
  {
    img: "https://github.com/JusticeFrancis/airbnb_images/blob/main/h3.png?raw=true",
  },
  {
    img: "https://github.com/JusticeFrancis/airbnb_images/blob/main/h4.png?raw=true",
  },
  {
    img: "https://github.com/JusticeFrancis/airbnb_images/blob/main/h5.png?raw=true",
  },
  {
    img: "https://github.com/JusticeFrancis/airbnb_images/blob/main/h6.png?raw=true",
  },
];

const HousesScreen = () => {
  const [properties, setProperties] = useRecoilState(propertiesState);
  const [openMobileFilterSideDrawer, setOpenMobileFilterSideDrawer] =
    useState(false);
  const [orientation, setOrientation] = useState("grid");
  const [filterListingType, setFilterListingType] = useState("dateDsc");
  const [filterMy, setFilterMy] = useState(true);

  //pagination scripts
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  async function getAllProperties() {
    const properties = await axios
      .get(process.env.REACT_APP_BACKEND_URL + "properties")
      .then((res) => {
        if (res.data.status === "success") {
          setProperties(res.data.properties);
          setItemOffset(0);
        }
      });
  }

  useEffect(() => {
    const endOffset = itemOffset + 9;
    setCurrentItems(properties.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(properties.length / 9));
  }, [itemOffset, 9, properties]);

  const handleClick = (event) => {
    const newOffset = (event.selected * 9) % properties.length;
    setItemOffset(newOffset);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //state
  const [allAreas, setAllAreas] = useRecoilState(areasState);
  const [areas, setAreas] = useState([]);

  const [price, setPrice] = useState([]);
  const [revenue, setRevenue] = useState(0);
  const [roi, setRoi] = useState(0);
  const [filterPrice, setFilterPrice] = useState(false);
  const [filterRevenue, setFilterRevenue] = useState(false);

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [maxRevenue, setMaxRevenue] = useState(0);
  const [minRevenue, setMinRevenue] = useState(0);
  const [maxRoi, setMaxRoi] = useState(0);
  const [minRoi, setMinRoi] = useState(0);

  const [loader, setLoader] = useState(true);

  const [applyFilter, setApplyFilter] = useState(false);

  useEffect(() => {
    if (!applyFilter) {
      //set price states

      setMaxPrice(Math.max(...properties.map((property) => property.price)));
      setMinPrice(Math.min(...properties.map((property) => property.price)));
      /*  setMaxPrice(7000000)
             setMinPrice(0) */
      setPrice([
        Math.min(...properties.map((property) => property.price)),
        Math.max(...properties.map((property) => property.price)),
      ]);

      setMaxRevenue(
        Math.max(...properties.map((property) => property.annual_revenue))
      );
      setMinRevenue(
        Math.min(...properties.map((property) => property.annual_revenue))
      );
      /* setMinRevenue(0)
            setMaxRevenue(200000) */
      setRevenue(
        Math.min(...properties.map((property) => property.annual_revenue))
      );

      //set roi states
      setMaxRoi(Math.max(...properties.map((property) => property.roi)));
      setMinRoi(Math.min(...properties.map((property) => property.roi)));
      /* setMinRoi(0)
            setMaxRoi(3) */
      setRoi(Math.min(...properties.map((property) => property.roi)));
    } else {
      setMaxPrice(7000000);
      setMinPrice(0);

      //setMaxRevenue(Math.max(...properties.map(property => property.annual_revenue)))
      //setMinRevenue(Math.min(...properties.map(property => property.annual_revenue)))
      setMinRevenue(0);
      setMaxRevenue(200000);

      //set roi states
      //setMaxRoi(Math.max(...properties.map(property => property.roi)))
      //setMinRoi(Math.min(...properties.map(property => property.roi)))
      setMinRoi(0);
      setMaxRoi(3);
    }
  }, [properties]);

  useEffect(() => {
    setAreas(allAreas);
  }, [allAreas]);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, [properties.length > 0]);

  const setMyFilter = async () => {
    setLoader(true);
    setApplyFilter(true);
    const email = localStorage.getItem("user");
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `filter/use-my-filters`, {
        email,
      })
      .then((res) => {
        if (res.data.status === "success") {
          setProperties(res.data.properties);
          setPrice(res.data.user.price);
          setRevenue(res.data.user.revenue);
          setRoi(res.data.user.roi * 100);
          setTimeout(() => {
            setOpenMobileFilterSideDrawer(false);
          }, [2000]);
          setItemOffset(0);
          setLoader(false);
        }
      })
      .catch((err) => {});
  };

  //captures user current location

  const [userlocation, setUserLocation] = useRecoilState(userLocationState);

  useEffect(() => {
    setUserLocation(window.location.href);
  });

  //sorting api

  const listingTypes = [
    {
      name: "Price (Low to High)",
      value: "priceAsc",
    },
    {
      name: "Price (High to Low)",
      value: "priceDsc",
    },
    {
      name: "Revenue (Low to High)",
      value: "revenueAsc",
    },
    {
      name: "Revenue (High to Low)",
      value: "revenueDsc",
    },
    {
      name: "ROI (Low to High)",
      value: "roiAsc",
    },
    {
      name: "ROI (High to Low)",
      value: "roiDsc",
    },
    {
      name: "Listing Date (Oldest to Newest)",
      value: "dateAsc",
    },
    {
      name: "Listing Date (Newest to Oldest)",
      value: "dateDsc",
    },
  ];

  const handleChangeListingTypeFilter = (event) => {
    setFilterListingType(event.target.value);
    if (event.target.value === "priceAsc") sortPrice(1);
    if (event.target.value === "priceDsc") sortPrice(-1);
    if (event.target.value === "revenueAsc") sortRevenue(1);
    if (event.target.value === "revenueDsc") sortRevenue(-1);
    if (event.target.value === "roiAsc") sortRoi(1);
    if (event.target.value === "roiDsc") sortRoi(-1);
    if (event.target.value === "dateAsc") sortDate(1);
    if (event.target.value === "dateDsc") sortDate(-1);
  };

  //price
  const sortPrice = async (sort) => {
    let ids = [];
    for (let i = 0; i < properties.length; i++) {
      ids.push(properties[i]._id);
    }
    setLoader(true);
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `sort/price`, { ids, sort })
      .then((res) => {
        if (res.data.status === "success") {
          setProperties(res.data.properties);
          setLoader(false);
        }
      })
      .catch((err) => {});
  };

  //revenue
  const sortRevenue = async (sort) => {
    let ids = [];
    for (let i = 0; i < properties.length; i++) {
      ids.push(properties[i]._id);
    }
    setLoader(true);
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `sort/revenue`, { ids, sort })
      .then((res) => {
        if (res.data.status === "success") {
          setProperties(res.data.properties);
          setLoader(false);
        }
      })
      .catch((err) => {});
  };

  //roi
  const sortRoi = async (sort) => {
    let ids = [];
    for (let i = 0; i < properties.length; i++) {
      ids.push(properties[i]._id);
    }
    setLoader(true);
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `sort/roi`, { ids, sort })
      .then((res) => {
        if (res.data.status === "success") {
          setProperties(res.data.properties);
          setLoader(false);
        }
      })
      .catch((err) => {});
  };

  //date
  const sortDate = async (sort) => {
    let ids = [];
    for (let i = 0; i < properties.length; i++) {
      ids.push(properties[i]._id);
    }
    setLoader(true);
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `sort/date`, { ids, sort })
      .then((res) => {
        if (res.data.status === "success") {
          setProperties(res.data.properties);
          setLoader(false);
        }
      })
      .catch((err) => {});
  };

  useMemo(() => {
    const email = localStorage.getItem("user") || null;
    track(null, "listing_page", email);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "user_location",
      String(window.location.pathname).slice(1)
    );
  });

  const [defaultSelectedAreas, setDefaultSelectedAreas] = useRecoilState(
    defaultSelectedAreasState
  );
  return (
    <>
      <Helmet>
      <title>Bnb Buyer</title>
        <meta
          name="description"
          content="Find STR properties for sale with a short-term rental history"
        />
        <meta
          property="og:image"
          content="https://bnbbuyer.com/wp-content/uploads/2022/12/fb_social.png"
          class="yoast-seo-meta-tag"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          class="yoast-seo-meta-tag"
        />
        <meta
          name="twitter:title"
          content="Bnb Buyer - Find STRs for Sale"
          class="yoast-seo-meta-tag"
        />
        <meta
          name="twitter:description"
          content="Find STR properties for sale with a short-term rental history"
          class="yoast-seo-meta-tag"
        />
        <meta
          name="twitter:image"
          content="https://bnbbuyer.com/wp-content/uploads/2022/12/Twitter_social.png"
          class="yoast-seo-meta-tag"
        />
      </Helmet>
      <Div className="lg:w-[80vw] lg:space-y-5  lg:mt-8  mt-5">
        <Div className="flex lg:space-x-6  ">
          <Div className="w-1/5 space-y-2 mt-[8.6px] hidden lg:block ">
            <Filter
              revenue={revenue}
              setRevenue={setRevenue}
              roi={roi}
              setRoi={setRoi}
              price={price}
              setPrice={setPrice}
              filterPrice={filterPrice}
              setFilterPrice={setFilterPrice}
              setFilterRevenue={setFilterRevenue}
              minRoi={minRoi}
              maxRoi={maxRoi}
              areas={areas}
              setAreas={setAreas}
              maxPrice={maxPrice}
              minPrice={minPrice}
              minRevenue={minRevenue}
              maxRevenue={maxRevenue}
              properties={properties}
              setProperties={setProperties}
              allAreas={allAreas}
              setLoader={setLoader}
              setAllAreas={setAllAreas}
              setMyFilter={setMyFilter}
              loader={loader}
              applyFilter={applyFilter}
              setApplyFilter={setApplyFilter}
              setFilterMy={setFilterMy}
              filterMy={filterMy}
              defaultSelectedAreas={defaultSelectedAreas}
              setDefaultSelectedAreas={setDefaultSelectedAreas}
              setItemOffset={setItemOffset}
            />
          </Div>

          <Div className="w-full lg:w-4/5 space-y-4 ">
            <Div className=" items-center justify-between hidden lg:flex">
              <Div className="text-gray-400">
                {" "}
                <span className="text-black">Results:</span> {properties.length}{" "}
                results found
              </Div>
              <Div className="text-gray-400 space-x-3 flex items-center ">
                <ViewList
                  onClick={() => setOrientation("grid")}
                  className={
                    orientation === "list"
                      ? "bg-gray-200 rounded-md p-[1px] cursor-pointer"
                      : "bg-[#1ebf99] rounded-md p-[1px] cursor-pointer"
                  }
                  sx={{ color: "white" }}
                />
                <AutoAwesomeMosaic
                  onClick={() => setOrientation("list")}
                  className={
                    orientation === "grid"
                      ? "bg-gray-200 rounded-md p-[1px] cursor-pointer"
                      : "bg-[#1ebf99] rounded-md p-[1px] cursor-pointer"
                  }
                  sx={{ color: "white" }}
                />
                <Div className="relative bottom-1">
                  <Select
                    sx={{ width: "100%", fontSize: "0.8rem" }}
                    input={
                      <InputBase className="border-[1px] rounded-sm pl-2 bg-white border-gray-200 w-full py-1" />
                    }
                    value={filterListingType}
                    onChange={handleChangeListingTypeFilter}
                  >
                    {/* <MenuItem value='default' >Sort By</MenuItem> */}
                    {listingTypes.map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Div>
              </Div>
            </Div>
            <Divider
              sx={{
                position: "relative",
                bottom: "0.5rem",
                display: { lg: "block", xs: "none" },
              }}
            />
            <Div className="lg:hidden">
              <Div className="text-gray-400">
                {" "}
                <span className="text-black">Results:</span> {properties.length}{" "}
                results found
              </Div>
              <Divider sx={{ my: "1rem" }} />
              <Div className="flex items-center justify-between ">
                <Div className="text-gray-400">
                  <Button
                    startIcon={<FilterAlt sx={{ color: "white" }} />}
                    className="shadow-md"
                    sx={{
                      textTransform: "none",
                      textSize: "0.8rem",
                      width: "7rem",
                      float: "right",
                      bgcolor: "#1ebf99",
                      color: "white",
                      ":hover": { color: "black" },
                    }}
                    onClick={() => {
                      setOpenMobileFilterSideDrawer(true);
                    }}
                  >
                    Filter
                  </Button>
                  <MobileFilterSideDrawer
                    openMobileFilterSideDrawer={openMobileFilterSideDrawer}
                    setOpenMobileFilterSideDrawer={
                      setOpenMobileFilterSideDrawer
                    }
                    revenue={revenue}
                    setRevenue={setRevenue}
                    roi={roi}
                    setRoi={setRoi}
                    price={price}
                    setPrice={setPrice}
                    filterPrice={filterPrice}
                    setFilterPrice={setFilterPrice}
                    maxPrice={maxPrice}
                    minPrice={minPrice}
                    properties={properties}
                    setProperties={setProperties}
                    setFilterRevenue={setFilterRevenue}
                    maxRevenue={maxRevenue}
                    minRevenue={minRevenue}
                    minRoi={minRoi}
                    maxRoi={maxRoi}
                    setAllAreas={setAllAreas}
                    areas={areas}
                    setAreas={setAreas}
                    allAreas={allAreas}
                    setLoader={setLoader}
                    setMyFilter={setMyFilter}
                    loader={loader}
                    applyFilter={applyFilter}
                    setApplyFilter={setApplyFilter}
                    setFilterMy={setFilterMy}
                    filterMy={filterMy}
                    defaultSelectedAreas={defaultSelectedAreas}
                    setDefaultSelectedAreas={setDefaultSelectedAreas}
                    setItemOffset={setItemOffset}
                  />
                </Div>
                <Div className="text-gray-400 space-x-3 flex items-center ">
                  <ViewList
                    onClick={() => setOrientation("grid")}
                    className={
                      orientation === "list"
                        ? "bg-gray-200 rounded-md p-[1px] cursor-pointer"
                        : "bg-[#1ebf99] rounded-md p-[1px] cursor-pointer"
                    }
                    sx={{ color: "white" }}
                  />
                  <AutoAwesomeMosaic
                    onClick={() => setOrientation("list")}
                    className={
                      orientation === "grid"
                        ? "bg-gray-200 rounded-md p-[1px] cursor-pointer"
                        : "bg-[#1ebf99] rounded-md p-[1px] cursor-pointer"
                    }
                    sx={{ color: "white" }}
                  />
                  <Div className="relative bottom-1">
                    <Select
                      sx={{
                        width: { lg: "100%", xs: "9rem" },
                        fontSize: "0.8rem",
                      }}
                      input={
                        <InputBase className="border-[1px] rounded-sm pl-2 bg-white border-gray-200 w-full py-1" />
                      }
                      value={filterListingType}
                      onChange={handleChangeListingTypeFilter}
                    >
                      <MenuItem value="default" disabled>
                        Sort By
                      </MenuItem>
                      {listingTypes.map((item, index) => (
                        <MenuItem value={item.value} key={index}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Div>
                </Div>
              </Div>
            </Div>

            <Div className="mt-8">
              <Row className={loader ? "blur-lg cursor-wait" : "blur-none"}>
                {currentItems &&
                  currentItems.map((item, index) => (
                    <House
                      orientation={orientation}
                      key={index}
                      property={item}
                    />
                  ))}
              </Row>
              <Row className={loader ? "blur-3xl cursor-wait" : "blur-none"}>
                {currentItems && currentItems.length < 1 ? (
                  <Div className="lg:w-[30vw] lg:space-y-5  relative top-36 lg:top-20 h-[90vh] mx-auto">
                    <Div className="space-y-5">
                      <Div className="text-5xl text-center font-extrabold text-[#1ebf99]">
                        oops
                      </Div>

                      <Div className="font-semibold text-center">
                        Hmm... We Can't Seem To Find the Property You Were
                        Looking For
                      </Div>

                      <Div className="flex justify-center">
                        <Button
                          className="shadow-md"
                          sx={{
                            textTransform: "none",
                            textSize: "0.8rem",
                            width: "15rem",
                            bgcolor: "#1ebf99",
                            color: "white",
                            ":hover": { color: "black" },
                          }}
                          onClick={() => {
                            setApplyFilter(false);
                            setFilterMy(true);
                            getAllProperties();
                          }}
                        >
                          Reset Filter
                        </Button>
                      </Div>
                    </Div>
                  </Div>
                ) : null}
              </Row>

              <Row
                className={
                  loader
                    ? "flex absolute top-[9%] left-[45%]  lg:top-[10%] lg:left-[58%] z-40 cursor-wait"
                    : "hidden"
                }
              >
                <CircularProgress
                  size={"4rem"}
                  sx={
                    loader
                      ? { color: "#1ebf99", display: "block" }
                      : { color: "#1ebf99", display: "none" }
                  }
                />
              </Row>

              <ReactPaginate
                nextLabel={
                  <ChevronRight
                    sx={{
                      border: "1px solid #1ebf99",
                      borderRadius: "2rem",
                      p: "0.5",
                      fontSize: "2rem",
                      color: "#1ebf99",
                    }}
                  />
                }
                className="flex flex-wrap justify-center items-center  space-x-8 text-xl border-2500 mt-16"
                activeClassName="text-2xl text-[#1ebf99] font-bold border-[1px] border-gray-200 px-5 py-1 "
                onPageChange={handleClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={
                  <ChevronLeft
                    sx={{
                      border: "1px solid #1ebf99",
                      borderRadius: "2rem",
                      p: "0.5",
                      fontSize: "2rem",
                      color: "#1ebf99",
                    }}
                  />
                }
                renderOnZeroPageCount={null}
              />
            </Div>
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default HousesScreen;
