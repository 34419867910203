import { Avatar, Divider } from '@mui/material'
import { Col, Div } from 'atomize'
import { Home, KingBed, AspectRatio } from '@mui/icons-material'
import Currency from 'react-currency-formatter'
import React from 'react'
import {useNavigate} from 'react-router-dom'

const House = ({ property,orientation }) => {
    const navigate = useNavigate()
    return (
        <>

            {orientation === 'grid' && (
                <Col size={{xs:'12', lg:'4',md:'6'}} style={{ marginBottom: '1rem' }}>
                    <Div className='bg-white shadow-lg rounded-lg  hover:opacity-80 cursor-pointer' onClick={()=>navigate('/house-detail/'+property.property_id)} >

                        <Avatar
                            src={property.photos[0]}
                            sx={{ width: '100%', height: '12rem', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }} variant='square' elevation='5'>BR</Avatar>
                        <Div className='p-2 space-y-2'>
                            <Div className='text-lg text-black font-bold' > {property.title}</Div>
                            <Div className='flex items-center justify-between text-sm font-semibold' >
                                <Div>Price : <span className='text-blue-600' > $<Currency quantity={property.price} decimal={0}  pattern='##,###!' symbol='' /></span></Div>
                                <Div>Income : <span className='text-blue-600' > $<Currency quantity={property.annual_revenue} decimal={0} pattern='##,###!' symbol=''/> </span></Div>
                            </Div>
                            <Divider />
                            <Div className='text-[0.8rem]  text-gray-400 flex items-center mt-2 justify-between'>
                                <Div ><Home sx={{ color: '#dde0e5', fontSize: '1.2rem', position: 'relative', bottom: '2px' }} /> {property.prop_type}</Div>
                                <Div><KingBed sx={{ color: '#dde0e5', fontSize: '1.2rem', position: 'relative', bottom: '2px' }} /> {property.beds} bed rooms</Div>
                                <Div><AspectRatio sx={{ color: '#dde0e5', fontSize: '1.2rem', position: 'relative', bottom: '2px' }} /> {property.sqft} sqft</Div>
                            </Div>

                            <Div className='flex items-center justify-between mt-4 text-blue-700 font-semibold mb-2 ' >
                                <Div></Div>
                                <Div className='cursor-pointer' >
                                    View Listing
                                </Div>
                            </Div>
                        </Div>





                    </Div>
                </Col>
            )}

            {orientation === 'list' && (
                <Col size='12' style={{ marginBottom: '1rem' }}>
                    <Div className='bg-white shadow-md rounded-lg flex space-x-4  lg:h-[14rem] hover:opacity-80 cursor-pointer ' onClick={()=>navigate('/house-detail/'+property.property_id)} >

                     <Div className='w-[30%] relative  '  >
                     <Avatar
                            src={property.photos[0]}
                            sx={{ width: '100%', height: {lg:'100%',xs:'100%',md:'100%'}, borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }} variant='square' elevation='5' />
                     </Div>
                        <Div className='w-[70%] px-2 relative '>
                            <Div className='flex  justify-between space-x-2 lg:space-x-0 ' >
                                <Div className=' space-y-1' >
                                    <Div className='lg:text-2xl text-md text-black font-bold' > {property.title} </Div>
                                    <Div className='lg:text-[0.8rem] text-[0.5rem]  text-gray-400 flex items-center mt-2 justify-between space-x-4'>
                                        <Div ><Home sx={{ color: '#dde0e5', fontSize: '1.2rem', position: 'relative', bottom: '2px' }} /> {property.prop_type}  house</Div>
                                        <Div><KingBed sx={{ color: '#dde0e5', fontSize: '1.2rem', position: 'relative', bottom: '2px' }} /> {property.beds} bed rooms</Div>
                                        <Div><AspectRatio sx={{ color: '#dde0e5', fontSize: '1.2rem', position: 'relative', bottom: '2px' }} /> {property.sqft} sqft</Div>
                                    </Div>
                                </Div>

                                <Div className='lg:text-lg text-sm font-semibold space-y-1' >
                                    <Div>Price : <span className='text-blue-600' > $<Currency quantity={property.price} pattern='##,###!' symbol=''/></span></Div>
                                    <Div>Income : <span className='text-blue-600' > $<Currency quantity={property.annual_revenue} pattern='##,###!' symbol='' /> </span></Div>
                                </Div>

                            </Div>
                            <Div className='text-md font-semibold mt-8 hidden lg:block'>
                                <Div>Description :</Div>
                                <Div className='text-gray-400 text-sm' >
                                   {property.description ? property.description.substr(0,350)+'...' : ''}
                                </Div>
                            </Div>


                            <Div className='flex items-center justify-between mt-4 text-blue-700 font-semibold mb-2 lg:absolute right-4 bottom-2' >
                                <Div></Div>
                                <Div className='cursor-pointer' >
                                    View Listing
                                </Div>
                            </Div>
                        </Div>





                    </Div>
                </Col>
            )}
        </>
    )
}

export default House 