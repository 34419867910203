import { Div } from 'atomize'
import React, { useState } from 'react'
import RegisterationStep1 from '../registration._steps/RegistrationStep1'
import RegistrationStep2 from '../registration._steps/RegistrationStep2'
import RegistrationStep3 from '../registration._steps/RegistrationStep3'
import RegistrationStep4 from '../registration._steps/RegistrationStep4'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Stepper } from 'react-form-stepper'
import { useLocation, useNavigate } from 'react-router-dom'


const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
const RegisterScreen = ({ defaultStep }) => {
    const [step, setStep] = useState(defaultStep)
    const navigate = useNavigate()
    const search = useLocation().search;
    const easyRegister = new URLSearchParams(search).get('easyRegister') || false;

    // const user = localStorage.getItem('user')
    // if(user){
    //     navigate('/')
    // }

    
    
    return (
        <>



            <Div className='lg:w-[48vw] space-y-5 h-[100vh] relative' >

                <Div className='font-extrabold text-xl text-center hidden lg:block ' >Sign Up</Div>

                <Div className='lg:w-[48vw] hidden lg:flex justify-center' >
                    <Stepper style={{ width: '50vw', display: 'flex', justifySelf: 'center' }} steps={[{ label: 'Registration Info' }, { label: 'Prefrences' }, { label: 'Selected Plan' }, { label: 'Sign Up' }]} className='w-[48vw]'
                        activeStep={step-1} styleConfig={{ activeBgColor: '#1ebf99', completedBgColor: '#1ebf99', labelFontSize: '1.2rem', size: '3.5rem', fontWeight: '300' }} 
                        connectorStyleConfig={{ activeColor: 'red',completedColor:'green', size: '0.2rem' }} />
                </Div>

                <Div className=' flex lg:hidden justify-start' >
                    <Stepper style={{width:'95vw',padding:0,  display: 'flex', justifySelf: 'center' }} steps={[{ label: 'Registration Info' }, { label: 'Prefrences' }, { label: 'Selected Plan' }, { label: 'Sign Up' }]} className='w-[48vw]'
                        activeStep={step-1} styleConfig={{ activeBgColor: '#1ebf99', completedBgColor: '#1ebf99', labelFontSize: '.6rem', size: '1.6rem', fontWeight: '300' }}
                         connectorStyleConfig={{ activeColor: '#1ebf99', size: '0.2rem', completedColor:'#1ebf99'}} />
                </Div>





                {step === 1 && <RegisterationStep1 step={step} setStep={setStep} />}

                {step === 2 && <RegistrationStep2 step={step} setStep={setStep} easyRegister={easyRegister} />}

                {step === 3 && (
                    <Elements stripe={stripe} >
                        <RegistrationStep3 step={step} setStep={setStep} />
                    </Elements>
                )}

                {step === 4 && (
                    <RegistrationStep4 step={step} setStep={setStep} />
                )}
            </Div>

        </>
    )
}

export default RegisterScreen