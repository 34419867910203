import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import { useState, useEffect, useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { areasState } from '../recoilstate'



const AreasSelect = ({selectedZips, setSelectedZips,setSelectedAreas, selectedAreas}) =>{

    /* const getUser = async () => {
        const user = await axios.post(process.env.REACT_APP_BACKEND_URL + `stripe/retrieve-customer`, { email: localStorage.getItem('user') })
    }

    useEffect(()=>{
        getUser()
    },[]) */

    const [allAreas, setAllAreas] = useRecoilState(areasState)

    const [defaultAreas, setDefaultAreas] = useState([])


    useMemo(()=>{ 


        allAreas.map((item)=>{
            defaultAreas.push({
                label: item.area,
                zips: item.zips,
                value: item.area_id
            })

        })


    },[allAreas])
    
    let areasShown = []
     const loadOptions = async(inputValue, callback) =>{
        areasShown=[]
       
         const areas =  await axios.post(process.env.REACT_APP_BACKEND_URL+'areas-by-priority-limit',{area_name: inputValue })
         const requestResults = areas.data.areas
          requestResults.map((item)=>{
            areasShown.push({
                label: item.area,
                zips: item.zips,
                value: item.area_id
            })
          })
          callback(areasShown)
     }

     const selectedAreasFunc = (option) =>{
       try {
        let zipper = []
        let ss = []
           option.map((item)=>{
            ss.push(item)
               item.zips.map((zip)=>{
                   zipper.push(zip)
                })
            })
            setSelectedZips(zipper)
            setSelectedAreas(ss)
        
       } catch (error) {
        setSelectedZips([])
       }
        }

        const style = {
            control: base =>({
                ...base,
                border:'1px solid gray',
                boxShadow: "none",
                "&:hover": {
                    border:'1px solid #1ebf99'
                }
            })
        }
    return(
        <AsyncSelect
        styles={style}
        placeholder='Search locations...'
        defaultOptions={defaultAreas}
        defaultValue={selectedZips} loadOptions={loadOptions} 
        className={window.location.pathname ==='/register' || window.location.pathname ==='/user/preferences' ? 'flex-wrap w-[93vw] md:w-[52rem] lg:w-[47rem] ': 'flex-wrap w-[21rem] md:w-[21rem] lg:w-[15rem]'}
        noOptionsMessage={(opt)=> {
            if(opt.inputValue === ''){
                return'type a location'
            }else{
                return 'no search results for '+ opt.inputValue
            }
        }}
        onChange={(opt) =>{
            selectedAreasFunc(opt)
        }}
        isMulti
        />
    )
}

export default AreasSelect 