
// const Plans ={
//   monthly: process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID,
//   yearly: process.env.REACT_APP_STRIPE_YEARLY_PLAN_ID
  
// }


const Plans ={
    monthly: process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID,
    yearly: process.env.REACT_APP_STRIPE_YEARLY_PLAN_ID
    
  }


export default Plans


