import { Button, InputBase, CircularProgress } from '@mui/material'
import { Div, Label } from 'atomize'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Snack from '../../reusables/Snack'
import { useNavigate } from 'react-router-dom'
import { Campaign } from '@mui/icons-material'
import { useRecoilState } from 'recoil'
import { userLocationState } from '../../recoilstate'

const ProfileScreen = ({ loader, user, setLoader, email, setEmail, first_name, setFirstName, last_name, setLastName, oldEmail, phone, setPhone, setOldEmail }) => {
    const [password, setPassword] = useState('')


    const [newPassword, setNewPassword] = useState('')

    //for snack
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState('')
    const [msg, setMsg] = useState('')

    const navigate = useNavigate()

    const updateUser = async () => {
        await axios.post(process.env.REACT_APP_BACKEND_URL + 'update', {
            oldEmail: oldEmail, newEmail: email, first_name, last_name
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.status === 'success') {
                    setOpen(true)
                    setMsg(res.data.msg)
                    setColor('green')
                    localStorage.setItem('user', res.data.user.email)
                    setOldEmail(res.data.user.email)

                } else {
                    setOpen(true)
                    setMsg(res.data.msg)
                    setColor('#bb2124')
                }
            })
    }

    const [user_location, setUserLocation] = useRecoilState(userLocationState)

    useEffect(()=>{
       setUserLocation(window.location.href)
    },[])

    return (
        <>
            <Snack open={open} setOpen={setOpen} msg={msg} color={color} />
            <Div className={!loader ? 'lg:w-3/5 blur-none' : 'lg:w-3/5 blur-lg'} >
                {user.email_isverified && (
                    <Div className='text-orange-400 text-sm italic my-3' >
                        <Campaign sx={{ color: 'orange' }} /> This account is using Google login you can't apply any changes or reset password
                    </Div>
                )}
                <Div className='space-y-3'>

                    <Div className='space-y-2' >
                        <Label className='font-semibold' >Full Name</Label>
                        <Div className='flex items-center space-x-3' >
                            <InputBase className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-1/2 py-1'
                                disabled={user.email_isverified}
                                sx={{ fontSize: '15px' }}
                                placeholder='First Name'
                                value={first_name}
                                onChange={(e) => { setFirstName(e.target.value) }}
                            />
                            <InputBase className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-1/2 py-1'
                                disabled={user.email_isverified}
                                sx={{ fontSize: '15px' }}
                                placeholder='Last Name'
                                value={last_name}
                                onChange={(e) => { setLastName(e.target.value) }}
                            />
                        </Div>
                    </Div>

                    <Div className='space-y-2' >
                        <Label className='font-semibold' >Email</Label>
                        <InputBase
                            disabled={user.email_isverified}
                            className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1'
                            sx={{ fontSize: '15px' }}
                            placeholder='xyz@example.com'
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                    </Div>
                </Div>

                <Div className='mt-10' >

                    <Button
                        disabled={user.email_isverified}
                        className='shadow-md'
                        sx={{ textTransform: 'none', textSize: '0.8rem', width: '15rem', height: '2.5rem', float: 'left', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                        onClick={updateUser}
                    >
                        Apply Changes
                    </Button>

                </Div>


                <Div className='mt-32' >

                    <Button
                        disabled={user.email_isverified}
                        className='shadow-md'
                        sx={{ textTransform: 'none', textSize: '0.8rem', width: '15rem', height: '2.5rem', float: 'left', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                        onClick={() => {
                            navigate('/forgot-password')
                        }}
                    >
                        Reset Password
                    </Button>

                </Div>
            </Div>


            <Div className={loader ? 'flex absolute top-[9%] left-[45%]  lg:top-[10%] lg:left-[58%] z-40 cursor-wait' : 'hidden'}>
                <CircularProgress size={'4rem'} sx={loader ? { color: '#1ebf99', display: 'block' } : { color: '#1ebf99', display: 'none' }} />
            </Div>

        </>
    )
}

export default ProfileScreen