import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, InputBase } from '@mui/material'
import { Div, Label, Modal } from 'atomize'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useRecoilState } from 'recoil'
import { userEmailState, userLocationState } from '../recoilstate'
import Snack from '../reusables/Snack'



const ForgotPassword = () => {
    const [userlocation, setUserLocation] = useRecoilState(userLocationState)

    const [email, setEmail] = useState(localStorage.getItem('user')|| '')

    //for snack
    const [msg, setMsg] = useState('')
    const [status, setStatus] = useState(false)
    const [isloading, setLoader] = useState(false)


    const getToken = async () => {
        //setLoader(true)

        if (email.length < 4) {
            setMsg('Email is in wrong format')
            setLoader(false)

            return
        }

        const browserName = navigator.userAgent
        const operatingSystem = navigator.userAgent
        let redirectBackUrl = ''

        if (userlocation === 'http://app.bnbbuyer.com/forgot-password') {
            redirectBackUrl = 'http://app.bnbbuyer.com/'
        } else {
            redirectBackUrl = userlocation
        }


        const token = await axios.post(process.env.REACT_APP_BACKEND_URL + 'get-forgot-password-token', { email, redirectBackUrl, browserName, operatingSystem })
            .then((res) => {
                if (res.data.status === 'success') {
                    setMsg('change password link successfully sent')
                    setStatus(true)
                    setLoader(false)

                } else {
                    setMsg(res.data.msg)
                    setLoader(false)

                }
            })
            .catch((err) => {
                setMsg(err + ' please try again')
                setLoader(false)
            })

    }



    return (
        <Div className='h-[100vh]' >

            <Div className='lg:w-[30vw] w-full bg-white shadow-md p-4 h-[35vh] space-y-4 relative' >

                <Div className={msg === 'change password link successfully sent' ? ' text-sm text-[#00ccbb] text-center ' : ' text-sm text-red-500 text-center '} >{msg}</Div>

                {status ? (

                    <>

                        <Div className='text-right text-sm text-[#00ccbb] underline cursor-pointer' onClick={() => {
                            setStatus(false)
                            setEmail('')
                            setMsg('')
                        }} >
                            change email
                        </Div>

                        <Div className='bg-gray-200 p-5 ' >
                            <Div className='text-center text-green-800 font-bold text-xl ' > Link Sent to  {email} </Div>
                        </Div>
                    </>
                ) : (
                    <Div className='space-y-2' >
                        <Label className='font-semibold' >Email</Label>
                        <InputBase className='border-[1px] rounded-sm pl-2 bg-gray-50 border-gray-200 w-full py-1'
                            sx={{ fontSize: '15px' }}
                            placeholder='xyz@example.com'
                            value={email}
                            type='email'
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </Div>
                )
                }


                <Div>

                    <Button
                        disabled={isloading}
                        className='shadow-md'
                        sx={{ textTransform: 'none', textSize: '0.8rem', width: '100%', float: '', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                        onClick={getToken}
                    >
                        {status ? 'send link again' : 'send me the change password link'}
                    </Button>

                </Div>


            </Div >
        </Div >
    )
}

export default ForgotPassword