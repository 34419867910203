import { Circle, Lock } from "@mui/icons-material";
import { Button, Divider } from "@mui/material";
import { Div } from "atomize";
import moment from "moment";
import React from "react";
import Currency from 'react-currency-formatter'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import Login from "../modals/LoginModal";

const dateFormatter = (data) => {
  // return moment(date).unix();
  console.log(data);
  data.forEach((d) => {
    d.dataora = moment(d.dataora).valueOf(); // date -> epoch
  });
};

const CustomToolTip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-gray-300 border-0  px-4 py-3 text-xs rounded-lg ">
        <p className="label"> Date : {`${label} `}</p>
        <p className="intro"> Revenue : ${`${payload[0].value.toLocaleString()}`}</p>
      </div>
    );
  }

  return null;
};

const formatter = (value) => `$${value.toLocaleString()}`;

const xformatter = (value) => <div className=" text-xs"> {value} </div>;

const Graph = ({
  hide,
  reason,
  setOpenJoinPremiumAccessModal,
  openLoginModal,
  setOpenLoginModal,
  property_id,
  revenue_history,
}) => {
  const data = revenue_history;

  const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;
    if (payload.name === data[0].name) {
      return (
        <circle
          cx={cx - 0}
          cy={cy - 0}
          r={5.5}
          stroke="none"
          strokeWidth={3}
          fill="#1ebf99"
        />
      );
    }

    if (payload.name === data[data.length - 1].name) {
      return (
        <circle
          cx={cx - 0}
          cy={cy - 0}
          r={5.5}
          stroke="none"
          strokeWidth={3}
          fill="#1ebf99"
        />
      );
    }
  };

  //   const data = revenue_history
  return (
    <>
      <div className="text-lg font-bold ">Revenue History</div>
      <Login
        openLoginModal={openLoginModal}
        setOpenLoginModal={setOpenLoginModal}
        property_id={property_id}
      />
      {/* proforma statement */}
      <Div className="bg-white  pt-3 relative">
        {reason === "login" && (
          <Div className="absolute lg:top-[30%] top-[26%] bg-opacity-5   lg:w-[60vw] w-[90vw] z-40 ">
            <Div className="text-center  bg-[#f7f7f7] bg-opacity-80 lg:p-5 p-2 lg:w-[40vw] w-[90vw] mx-auto space-y-4 shadow-lg rounded-xs">
              <Div className="lg:text-xl text-md font-bold ">
                Revenue history charts are available for premium members only.
              </Div>
              <Div>
                <Button
                  startIcon={
                    <Lock
                      sx={{
                        color: "white",
                        position: "absolute",
                        left: 3,
                        bottom: { xs: 10, lg: 8 },
                      }}
                    />
                  }
                  className="shadow-md"
                  sx={{
                    textTransform: "none",
                    textSize: "0.8rem",
                    width: "15rem",
                    bgcolor: "#1fbf99",
                    color: "white",
                    ":hover": { color: "black" },
                    position: "relative",
                  }}
                  onClick={() => {
                    setOpenLoginModal(true);
                    const email = localStorage.getItem("user") || null;
                    // track(id, "calculator_login", email);
                  }}
                >
                  Login
                </Button>
              </Div>

              <Div className="font-semibold text-sm">
                You must login into a paid account to view monthly revenue
                history chart
              </Div>
            </Div>
          </Div>
        )}

        {reason === "plan" && (
          <Div className="absolute lg:top-[30%] top-[26%] bg-opacity-5   lg:w-[60vw] w-[90vw] z-40 ">
            <Div className="text-center  bg-[#f7f7f7] bg-opacity-80 lg:p-5 p-2 lg:w-[40vw] w-[90vw] mx-auto space-y-4 shadow-lg rounded-xs">
              <Div className="lg:text-xl text-md font-bold ">
              Revenue history charts are available for premium members only.
              </Div>
              <Div>
                <Button
                  startIcon={
                    <Lock
                      sx={{
                        color: "white",
                        position: "absolute",
                        left: 3,
                        bottom: { xs: 10, lg: 8 },
                      }}
                    />
                  }
                  className="shadow-md"
                  sx={{
                    textTransform: "none",
                    textSize: "0.8rem",
                    width: "15rem",
                    bgcolor: "#1fbf99",
                    color: "white",
                    ":hover": { color: "black" },
                    position: "relative",
                  }}
                  onClick={() => {
                    setOpenJoinPremiumAccessModal(true);
                    const email = localStorage.getItem("user") || null;
                    // track(id, "calculator_upgrade", email);
                  }}
                >
                  Join Premium to View
                </Button>
              </Div>

              {/* <Div className="font-semibold text-sm">
                Joining Premium makes it super easy to calculate your COC
                return, Cap Rate and gives you the ability to contact the seller
              </Div> */}
            </Div>
          </Div>
        )}

        <Div
          className={
            hide ? " lg:space-x-7 blur-sm relative " : " lg:space-x-7 relative "
          }
        >
          <>
            <div className=" justify-center w-full hidden lg:flex  ">
              <LineChart width={900} height={400} data={data}>
                <CartesianGrid
                  strokeDasharray="0"
                  strokeWidth={0.3}
                  horizontal={true}
                  vertical={false}
                />
                <Tooltip
                  cursor={true}
                  content={<CustomToolTip />}
                  wrapperStyle={{ outline: "none" }}
                />
                <YAxis dataKey={"amt"} tickFormatter={formatter} width={100} />
                <XAxis dataKey={"date"} tick={{ fontSize: 13 }}  />
                <Line
                  type=""
                  dataKey="amt"
                  stroke="#1ebf99"
                  strokeWidth={4}
                  isAnimationActive={false}
                  dot ={{fill: '#1ebf99'}}
                //   dot={CustomizedDot}
                />
              </LineChart>
            </div>

            <div className=" relative right-3 w-full   lg:hidden ">
              <LineChart width={340} height={200} data={data}>
                <CartesianGrid
                  strokeDasharray="0"
                  strokeWidth={0.3}
                  horizontal={true}
                  vertical={false}
                />
                <Tooltip
                  cursor={true}
                  content={<CustomToolTip />}
                  wrapperStyle={{ outline: "none" }}
                />
                <YAxis
                  dataKey={"amt"}
                  tickFormatter={formatter}
                  tick={{ fontSize: 10 }}
                />
                <XAxis dataKey={"date"} tick={{ fontSize: 10 }} />
                <Line
                  type=""
                  dataKey="amt"
                  stroke="#1ebf99"
                  strokeWidth={4}
                  isAnimationActive={false}
                  dot ={{fill: '#1ebf99'}}
                //   dot={CustomizedDot}
                />
              </LineChart>
            </div>
          </>
        </Div>
      </Div>
    </>
  );
};

export default Graph;
