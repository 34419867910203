import React, { useState, useEffect, useMemo } from 'react'
import { Div, Tag } from 'atomize'
import Currency from 'react-currency-formatter'
import { Button, CircularProgress } from '@mui/material'
import PlansModal from '../../modals/PlansModal'
import Snack from '../../reusables/Snack'
import axios from 'axios'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { useNavigate, useParams } from 'react-router-dom'
import Plans from '../../Plans'
import { track } from '../../tracking'

const billing_history = [
    {
        amount: 24,
        date: '06/18/2022'
    },
    {
        amount: 244.5,
        date: '06/12/2022'
    },
    {
        amount: 124.5,
        date: '06/10/2022'
    },
]

const BillingsScreen = ({ user, loader, pm, setLoader, subscription, endDate, invoices, setSubscription, free }) => {

    const { callbackMsg } = useParams()
    const stripe = useStripe()
    const [openPlansModal, setOpenPlansModal] = useState()

    const navigate = useNavigate()

    //for snack
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState('')
    const [msg, setMsg] = useState('')

    const updateCard = async () => {
        setLoader(true)
        await axios.post(process.env.REACT_APP_BACKEND_URL + 'stripe/update-card', {
            email: localStorage.getItem('user')
        })
            .then((res) => {
                if (res.data.status === 'success') {
                    stripe.redirectToCheckout({ sessionId: res.data.session_id })
                    setOpen(true)
                    setColor('green')
                    setMsg('redirecting to stripe')
                } else {
                    setOpen(true)
                    setMsg(res.data.msg)
                    setColor('#bb2124')
                    setLoader(false)
                }
            })

    }





    useEffect(() => {

        if (callbackMsg && callbackMsg === 'success') {
            setOpen(true)
            setColor('green')
            setMsg('payment card successfully updated')
        }

        if (callbackMsg && callbackMsg === 'fail') {
            setOpen(true)
            setColor('#bb2124')
            setMsg('payment card failed to update')
        }

    }, [])


    const email = localStorage.getItem('user') || null
    useMemo(()=>{
         track(null,'billing page', email)
     },[])



    return (
        <>
            <Snack open={open} setOpen={setOpen} msg={msg} color={color} />

            <Elements stripe={stripe} >


                <PlansModal openPlansModal={openPlansModal} setOpenPlansModal={setOpenPlansModal}

                    setLoader={setLoader} setOpen={setOpen} setMsg={setMsg} setColor={setColor} subscription={subscription} setSubscription={setSubscription}
                    free={free}
                />
            </Elements>

            <Div className={!loader ? 'lg:w-3/5 blur-none w-full' : 'lg:w-3/5 blur-lg w-full'} >
                <Div className='space-y-6' >
                    <Div className='space-y-2' >
                        <Div className='text-md text-black font-bold' >My Plan</Div>
                        <Div className='bg-white shadow-md py-2 lg:py-4 px-2 lg:px-10 flex  rounded-lg' >
                            <Div className='flex-1' >
                                <Div className='font-bold text-sm' >
                                    {subscription.plan?.id === Plans.monthly && 'Bnbapp Monthly Membership'}

                                    {subscription.plan?.id === Plans.yearly && ' Bnbapp Yearly Membership '}
                                    <Tag className=' border-[1px] border-[#6ed68d] text-center w-[6rem] ml-2' bg='#daf2dc'>{subscription.plan?.active ? 'Active' : 'Free Plan'}</Tag> </Div>
                                <Div className={subscription.plan?.active ? 'text-black text-sm' : 'hidden'}>
                                    <span className='text-black font-bold mr-2' >$<Currency quantity={(subscription.plan?.amount) / 100} symbol='' pattern={'##,###!'} /></span>
                                    {subscription.cancel_at_period_end ? 'Ends' : 'Renews'} on
                                    {' '+(endDate?.getMonth() + 1) + '/'  + (endDate?.getDate()) +  '/' + (endDate?.getFullYear())}
                                </Div>
                            </Div>

                            <Div className='my-auto' >
                                <Button
                                    disabled={loader}
                                    className='shadow-md'
                                    sx={{ textTransform: 'none', fontSize: '0.8rem', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                                    onClick={() => {
                                        setOpenPlansModal(true)
                                        track(null,'change_plan_button', email)
                                    }}
                                >
                                    {subscription.plan?.active ? 'Change' : 'Join Premium Access'}
                                </Button>
                            </Div>
                        </Div>
                    </Div>

                    <Div className={free ? 'hidden' : 'space-y-2'}>
                        <Div className='text-md text-black font-bold' >Payment Method</Div>
                        <Div className='bg-white shadow-md py-2 lg:py-4 px-2 lg:px-10 flex  rounded-lg' >
                            <Div className='flex-1' >
                                <Div className='font-bold text-sm uppercase' >
                                    {pm?.brand} Ending In {pm?.last4} <Tag className=' border-[1px] border-[#6ed68d] text-center w-[6rem] ml-2' bg='#daf2dc'>Active</Tag> </Div>
                                <Div className='text-black text-sm'>
                                    Expires: {pm.exp_month}/{pm.exp_year}
                                </Div>
                            </Div>

                            <Div className='my-auto' >
                                <Button
                                    disabled={loader}
                                    className='shadow-md'
                                    sx={{ textTransform: 'none', fontSize: '0.8rem', bgcolor: '#1ebf99', color: 'white', ':hover': { color: 'black' } }}
                                    onClick={updateCard}
                                >
                                    Change
                                </Button>
                            </Div>
                        </Div>
                    </Div>


                    <Div className={free ? 'hidden' : 'space-y-2'} >
                        <Div className='text-md text-black font-bold' >Billing History</Div>
                        <Div>
                            {invoices.map((item, index) => (
                                <Div className='flex items-center justify-between py-4 px-8 bg-white border-b-2 border-b-gray' key={index}>
                                    <Div className='text-lg text-[#1ebf99] font-bold' >$<Currency quantity={(item.amount_paid) / 100} pattern='##,###!' symbol='' /></Div>
                                    <Div className='text-lg text-gray-400 font-semibold' >{' ' + ((new Date(Number(String(item.period_end) + '000')))?.getDate()) + '/' + ((new Date(Number(String(item.period_end) + '000')))?.getMonth() + 1) + '/' + ((new Date(Number(String(item.period_end) + '000')))?.getFullYear())}</Div>
                                    <Div className='text-sm text-[#1ebf99] cursor-pointer ' ><a download href={item.invoice_pdf}  >See More</a></Div>
                                </Div>
                            ))}

                        </Div>
                    </Div>

                </Div>
            </Div>


            <Div className={loader ? 'flex absolute top-[9%] left-[45%]  lg:top-[10%] lg:left-[58%] z-40 cursor-wait' : 'hidden'}>
                <CircularProgress size={'4rem'} sx={loader ? { color: '#1ebf99', display: 'block' } : { color: '#1ebf99', display: 'none' }} />
            </Div>
        </>
    )
}

export default BillingsScreen