import { Divider } from "@mui/material";
import { Div } from "atomize";
import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import UserNav from "../../reusables/UserNav";
import NotFoundScreen from "../NotFoundScreen";
import BillingsScreen from "./BillingsScreen";
import PrefrencesScreen from "./PreferencesScreen";
import ProfileScreen from "./ProfileScreen";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Snack from "../../reusables/Snack";
import EmailPrefrencesScreen from "./EmailPrefrencesScreen";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const User = () => {
  const [loader, setLoader] = useState(true);
  const [user, setUser] = useState({});
  const [email, setEmail] = useState(localStorage.getItem("user") || "");
  const [oldEmail, setOldEmail] = useState(localStorage.getItem("user") || "");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  const [payment_method, setPaymentMethod] = useState({});
  const [subscription, setSubscription] = useState({});
  const [endDate, setEndDate] = useState();
  const [invoices, setInvoices] = useState([]);

  const [free, setFree] = useState(false);

  //for snack
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState("");
  const [msg, setMsg] = useState("");

  const navigate = useNavigate();

  const getUser = async () => {
    console.log(email);
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `stripe/retrieve-customer`, {
        email,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          if (res.data.billing_account) {
            setUser(res.data.user);
            setPaymentMethod(res.data.payment_method);
            setSubscription(res.data.subscription);
            setEndDate(
              new Date(
                Number(String(res.data.subscription.current_period_end) + "000")
              )
            );
            console.log(res.data.invoices.data);
            setInvoices(res.data.invoices.data);
            setEmail(res.data.user.email);
            localStorage.setItem("user", res.data.user.email);
            setFirstName(res.data.user.first_name);
            setLastName(res.data.user.last_name);
            setPhone(res.data.user.phone);
            setLoader(false);
          } else {
            setFree(true);
            setEmail(email);
            setUser(res.data.user);
            setFirstName(res.data.user.first_name);
            setLastName(res.data.user.last_name);
            setPhone(res.data.user.phone);
            setLoader(false);
          }
        }
      });
  };

  localStorage.setItem('user_location_from_dashboard', window.location.href)

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      setOpen(true);
      setColor("#bb2124");
      setMsg("please login to access this page");
      setTimeout(() => {
        navigate("/?redirect=login");
        window.location.reload();
      }, 3000);
    }
    getUser();
  }, []);
  return (
    <>
      <Snack open={open} setOpen={setOpen} msg={msg} color={color} />
      <Div
        className={
          loader
            ? " h-[100vh] lg:w-[70vw] lg:space-y-5  lg:mt-2  mt-1"
            : "lg:w-[70vw] lg:space-y-5  lg:mt-2  mt-1 h-[100vh]"
        }
      >
        <Div className="text-3xl text-black font-bold lg:relative lg:top-4">
          Hi {user?.first_name}
        </Div>
        <Div className="text-2xl text-gray-400">
          👋 We are Happy To See You Again
        </Div>

        <Divider />

        <Div className="flex  lg:relative lg:bottom-5">
          <Div className="lg:w-1/5 p-4 space-y-4 hidden lg:block ">
            <UserNav />
          </Div>
          <Div className=" w-full lg:w-4/5 lg:border-l-[1px] lg:border-l-gray-300  py-2 lg:px-8 ">
            <Routes>
              <Route
                path="/profile"
                element={
                  <ProfileScreen
                    loader={loader}
                    user={user}
                    setLoader={setLoader}
                    email={email}
                    setEmail={setEmail}
                    first_name={first_name}
                    last_name={last_name}
                    setLastName={setLastName}
                    setFirstName={setFirstName}
                    oldEmail={oldEmail}
                    setOldEmail={setOldEmail}
                    phone={phone}
                    setPhone={setPhone}
                  />
                }
              />
              <Route path="/preferences" element={<PrefrencesScreen />} />
              <Route
                path="/billing&subscription"
                element={
                  <Elements stripe={stripe}>
                    <BillingsScreen
                      user={user}
                      loader={loader}
                      pm={payment_method}
                      setLoader={setLoader}
                      subscription={subscription}
                      endDate={endDate}
                      invoices={invoices}
                      setSubscription={setSubscription}
                      free={free}
                    />
                  </Elements>
                }
              />

              <Route
                path="/billing&subscription/:callbackMsg"
                element={
                  <Elements stripe={stripe}>
                    <BillingsScreen
                      user={user}
                      loader={loader}
                      pm={payment_method}
                      setLoader={setLoader}
                      subscription={subscription}
                      endDate={endDate}
                      invoices={invoices}
                      setSubscription={setSubscription}
                      free={free}
                    />
                  </Elements>
                }
              />

              <Route path="/email-preference" element={<EmailPrefrencesScreen user={user} />} />

              {<Route path="/*" element={<NotFoundScreen />} />}
            </Routes>
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default User;
