
import axios from 'axios'

export const track = async (property_id, page,email) => {
    let pid = property_id
    let ip_address = null
    const res = await axios.get('https://geolocation-db.com/json/')
    ip_address = res.data.IPv4
    const urlParams = new URLSearchParams(window.location.search)
    const subscriber_id = urlParams.get('subscriberId') || null
    let tracking = page




    
    await axios.post(process.env.REACT_APP_BACKEND_URL + 'track', { email, property_id: pid, subscriber_id, ip_address, tracking })
        .then((res) => {
           
        })
        .catch((err) => {
            
        })
}