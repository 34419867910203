import { Div } from 'atomize'
import React from 'react'
import { Home } from '@mui/icons-material'
import {Avatar} from '@mui/material'
import logo from '../assets/favicon2.png'

const Footer = () => {
    return (
        <>
            <Div className=' w-full bg-gray-100 px-10 lg:flex justify-between py-2 portrait:mt-[40vh] hidden ' d={{ lg: 'flex', xs: 'none' }} >

                <Div className='flex items-center space-x-3' >
                    <Div className=' px-auto flex items-center  ' >
                        <Div>
                        <Avatar src={logo} variant='circular' sx={{ p:"0.1px", border:'1px solid #1ebf99', bgcolor:'#1ebf99' }} />
                           {/*  <Home sx={{ color: "white", bgcolor: '#1ebf99', borderRadius: '2rem', fontSize: '1.5rem', p: '0.1rem', mr: '0.4rem' }} /> */}
                        </Div>
                        <Div className='text-[#1ebf99] text-sm font-bold'>
                            <span className='relative top-1'  >Bnb </span> <br /> <span  > Buyer</span>
                        </Div>
                    </Div>
                    <Div className='text-[#1ebf99] text-sm' >

                        © Bnb Buyer LLC

                    </Div>
                </Div>

                <Div className='flex items-center text-[#148d71] text-[0.8rem] lg:text-sm space-x-6' >
                    <Div className='underline cursor-pointer hover:text-gray-500' onClick={()=> window.location.href = 'https://bnbbuyer.com/blog'}  >Blog</Div>
                    <Div className='underline cursor-pointer hover:text-gray-500' onClick={()=> window.location.href = 'https://bnbbuyer.com/terms'} >Terms of Service</Div>
                    <Div className='underline cursor-pointer hover:text-gray-500' onClick={()=> window.location.href = 'https://bnbbuyer.com/privacy-policy'} >Privacy Policy</Div>
                    <Div className='underline cursor-pointer hover:text-gray-500' onClick={()=> window.location.href = 'https://bnbbuyer.com/contact'}>Contact</Div>
                </Div>

            </Div>

            <Div className=' w-full' >
                <Div className='text-[#1ebf99] bg-gray-100 text-center p-4 block lg:hidden '  >
                    © Bnb Buyer LLC
                </Div>
            </Div>
        </>
    )
}

export default Footer